import { useState } from 'react';
import RegisterAction from './RegisterAction';
import LoginAction from '../login/LoginAction';
import Account from '../Account'

function useRegisterState() {

  const [item, setItem] = useState(null)
  const [completed, setCompleted] = useState(null)
  const [error, setError] = useState(null)


  const itemChangeHandler = (data) => {
    setItem(new Account(data))
  }
  const createHandler = (data) => {
   //Loginする
    LoginAction.login(data,loginHandler,errorHandler )
  }

  const loginHandler = (data) => {
    setCompleted(true)
  }

  const errorHandler = (data) => {
    setError(data)
  }

  const createUser = (data) => {
    RegisterAction.create(data, createHandler, errorHandler)
  }
  const save = (data) => {
    RegisterAction.saveOnCookie(data)
  }

  const loadFromCookie = () => {
    RegisterAction.loadFromCookie( itemChangeHandler)
  }

  const removeFromCookie = () => {
    RegisterAction.removeFromCookie()
  }

  const loadErrors = () => {
    RegisterAction.loadErrors(errorHandler)
  }

  const removeErrors = () => {
    RegisterAction.removeErrors()
  }



  return {account:item, createResult:completed, createUser:createUser, regiterError:error, removeErrors:removeErrors,loadAccountErrors:loadErrors, saveAccountInfo:save, loadAccountInfo:loadFromCookie, removeAccountInfo:removeFromCookie};
}

export default useRegisterState