
import 'whatwg-fetch'
import config from 'react-global-configuration';
import Action from '../action/Action';

const DeliveryAction = {


  getList( token, handler, errorHandler ){
    const path = config.get("API_PATH") + "/api/deliveries"
    Action.fetchAuthorizedJson(path,token, "GET", {}, null,  handler, errorHandler)
  },

  create( token, item, handler, errorHandler ){
    const path = config.get("API_PATH") + "/api/deliveries"
    const header = {'Content-Type': 'application/json'}
    Action.fetchAuthorizedJson(path, token, "POST", header,  JSON.stringify(item),  handler, errorHandler, {token:token})
  },

  update( token, item, handler, errorHandler ){
    const path = config.get("API_PATH") + "/api/deliveries/" + item.id
    const header = {'Content-Type': 'application/json'}
    Action.fetchAuthorizedJson(path, token, "PATCH", header,  JSON.stringify(item),  handler, errorHandler, {token:token})
  },

  delete( token, id, handler, errorHandler ){
    const path = config.get("API_PATH") + "/api/deliveries/" + id
    Action.fetchAuthorizedDelete( path, token,  {}, handler,errorHandler, {token:token})
  },


 
}

export default DeliveryAction
