import React, { useState, useEffect } from 'react'
import useMediaState from '../order/media/useMediaState';

function BackgroundLoader(props) {
  const url = window.URL || window.webkitURL;
  const [back_img_src, setBackImageSrc] = useState(null)
  const {loaded_image, loaded_images_length, getOrderImage} = useMediaState()
  
  useEffect(() => {
    if(props.item){
      if(props.type === 1 && props.item.pdf_front_path) {
        getOrderImage(props.token, props.item.id, props.type)
      }
      if(props.type === 2 && props.item.pdf_back_path) {
        getOrderImage(props.token, props.item.id, props.type)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.item]);

  useEffect(() => {
    if(loaded_image){
      console.log(loaded_images_length)
      const _possible = loaded_image.filter(item => item.type === props.type)

      if(_possible.length > 0 ){
        setBackImageSrc(_possible[0].data) 
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loaded_image, loaded_images_length]);

  return (
    <div>
    {back_img_src &&
      <img src={url.createObjectURL(back_img_src)} alt="" />
    }
    </div>
  )
}

export default BackgroundLoader
