import { useState } from 'react';

import CartAction from './CartAction';


function useCartState() {

  const [cart, setCart] = useState(null)
  const [current_id, setCurrentId] = useState(null)
  const [updated_at, setUpdatedAt] = useState(null)

  const currentCartIdLoadHandler = (data)  => {
    setCurrentId(data)
  }
  
  const cartChangeHandler = (data) => {
    setCart(data)
  }

  const errorHandler = (data) => {
    
  }

  const cartUpdatedHandler = (data) => {
    setUpdatedAt(new Date())
  }
  const getCurrentId =  () => {
    CartAction.loadFromCookie(currentCartIdLoadHandler)
  }

  const duplicateFromOrder = (order) => {
    return CartAction.duplicateFromOrder(order)
  }

  const get = (token, id) => {
    CartAction.get(token, id, cartChangeHandler)
  } 

  const create = async(token, item) => {
    await CartAction.create(token, item, cartChangeHandler, errorHandler)
  } 

  const update = (token, item) => {
    CartAction.updateItem(token, item, cartUpdatedHandler, errorHandler)
  } 


  return { cart:cart,current_cart_id:current_id, cart_updated_time:updated_at, getCart:get, getCurrentCartId:getCurrentId, createCartItem:create, updateCartItem:update, duplicateCartItem:duplicateFromOrder};
}

export default useCartState