import React, {  useEffect } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import useAuthState from '../auth/useAuthState'
import UserMenu from '../menu/UserMenu'
import ProfileContent from './ProfileContent'
import "../css/member.css"
function ProfileApp(props:PropsValue) {
  const {token, auth} = useAuthState()

  useEffect(() => {
    auth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);



  return(

        <div id="page" className="member register"> 
          <Header token={token} />
          <Menu />
          <article id="contents">
            <UserMenu selectMenuItem="register" />
            <section className="s-contentsin2 topcomment">
              <h1 className="stitle">会員登録/会員情報変更</h1>
              <p>ID（メールアドレス）とパスワードと、基本情報の必須項目を入力してください。</p>
            </section>
            <section className="s-contentsin2 delivery-list">
              <ProfileContent token={token} />
            </section>
            </article>
          <Footer />
        </div>
  

  )
}

export default ProfileApp
