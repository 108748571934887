import React, { useState, useEffect } from 'react'
import config from 'react-global-configuration';
import Account from '../Account'
import Message from '../../message/Message'
import useRegisterState from './useRegisterState';


function RegisterForm(props:PropsValue) {

  const [item, setItem] = useState(new Account())
  const [erros, setErrors] = useState([])
  const [message, setMessage] = useState(null)

  const {account, regiterError, saveAccountInfo, loadAccountInfo, loadAccountErrors, removeErrors} = useRegisterState()

  useEffect(() => {
    loadAccountInfo()
    loadAccountErrors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(regiterError && regiterError.status === "0"){
      setMessage(Message.getMessage("email.unique"))
    }
  },[regiterError]);

  useEffect(() => {
    if(account){
      setItem(account)
    }
  },[account]);

  const changeHandler = (e) => {
    const target = e.target
    setItem(item.set(target.name, target.value))
  }

  const submit = () => {
    const _errors = []
    removeErrors()
    //mail 
    if(!item.email){
      _errors.push("email.required")
    }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(item.email)) {
      _errors.push("email.email:filter")
    }
    //password 
    if(!item.password){
      _errors.push("password.required")
    }else if(item.password.length < config.get("PASSWORD_MIN")){
      _errors.push("password.min")
    }

    //password confirm  
    if(!item.password_confirmation){
      _errors.push("password_confirmation.required")
    }else if(item.password && item.password !== item.password_confirmation){
      _errors.push("password.confirmed")
    }    
    setErrors(_errors)

    
    if(_errors.length > 0){
      //ErrorMessage
      setMessage(_errors.map(error => {
       return ( <li>{Message.getMessage(error)}</li>)
      }))
    }else{
      //Regiter
      saveAccountInfo(item)
      setMessage(null)
      window.location.href = "/register/confirm"
    }
  }

  

  return(
    <div>
    <section className="contentsin">
      {message ?
          <ul className="errorbox">
            {
              message
            }
          </ul>
         : null
      }
      </section>
      	<section className="s-contentsin2 delivery-list">
      <div className="box">
        <form id="register">
            <table className="form-table address-table">
                <tr className={Message.hasMessge(erros, "email") ? "err" : null}>
                    <th>
        <span className="thname">ID（メールアドレス）</span><span className="required">必須</span>
                    </th>
                    <td>
        <div className="textbox">
            <div>
                <input type="email" name="email" id="member-id" className="inputbox02" placeholder="" value={item.email} required autocomplete="email" onChange={changeHandler}/>
            </div>
        </div>
                    </td>
                </tr>

                <tr className={Message.hasMessge(erros, "password") ? "err" : null}>
                    <th>
        <span className="thname">パスワード</span><span className="required">必須</span>
                    </th>
                    <td>
        <div className="textbox">
            <div>
                <input type="password" name="password" id="password" className="inputbox02" placeholder="" required value={item.password} autocomplete="new-password" onChange={changeHandler}/>
            </div>
        </div>
                    </td>
                </tr>
                <tr className={Message.hasMessge(erros, "password_confirmation") ? "err" : null}>
                    <th>
        <span className="thname">パスワード（確認用）</span><span className="required">必須</span>
                    </th>
                    <td>
        <div className="textbox">
            <div>
                <input type="password" id="password2" className="inputbox02" placeholder="" value={item.password_confirmation} name="password_confirmation" required autocomplete="new-password" onChange={changeHandler}/>
            </div>
        </div>
                    </td>
                </tr>
            </table>
            <p className="min atten">8文字以上の半角英数字でパスワードを入力してください。<br />
            確認のため、パスワード（確認用）にも入力をお願いします。</p>
            <div className="buttonbox">
                <div className="button or">
                    <div onClick={submit}>仮登録内容確認</div>
                </div>
            </div>
        </form>
      </div>
    </section>
    </div>
  )
}

export default RegisterForm


