import React, { useState, useEffect } from 'react'

import Message from '../../message/Message'
import RequestValue from '../RequestValue'
import AdditionalPlacement from '../additional_placement/AdditionalPlacement'

function ValiableItemElement(props:PropsValue) {
  const [item, setItem ] = useState(new AdditionalPlacement())
  const [error, setError] = useState(false)
  //const [options, setOptions] = useState(null)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if(props.item){
      setItem(props.item)
    }
    //
  },[props.item]);

  useEffect(() => {
    setError(props.error)
    //
  },[props.error]);

  useEffect(() => {
  
    /***
    const items = (new Array(props.max_length )).fill(null).map((item, index) => {
      return <div ></div>
    }) */

    setDisabled(props.selectable ? false : true)
   
    //setOptions(items) 
  },[props.max_length, props.selectable]);

  const changeHandler = (e) => {
    const target = e.target
    const _item = item.set(target.name, target.value)
    props.changeHandler(props.index, _item)
  }
  const changQreHandler = (e) => {
    const _error_data = props.data.filter(item =>  !item.match(/^[\x20-\x7e]*$/))
    if(_error_data.length === 0) {
      const _value = item.qr_code === 0 ? 1 : 0 
      const _item = item.set("qr_code", _value)
      props.changeHandler(props.index, _item)
      props.setError(null)
    }else{

      props.setError(Message.getMessage("qr.formaterror"))
      setItem(props.item)
    }
   
  }
  /** "@if(array_key_exists("item2$i", $errors->toArray())) err @endif" */
  
  return(
      <tr className={error ? "err" : null}>
      <th>
          項目{props.index + 1}
      </th>
      <td>

          <select name="placement" onChange={changeHandler}>
              <option value="0">配置する面</option>
              
              {( props.plan !== RequestValue.PLAN_ATENA )
                ? <option value="A" selected= {item.placement === "A" ? true : false}>宛名面</option> : null
              }

              {( props.specification === RequestValue.SPECIFICATION_V )
                ? <option value="C" selected= {item.placement === "C" ? true : false}>中面</option> : null
              }

              
              
              
              { ( props.specification === RequestValue.SPECIFICATION_Z)
                ? <option value="D" selected= {item.placement === "D" ? true : false}>中面</option> : null
              }


      
          </select>
      </td>
      <td>
          <div className="list">
              <div>
                  <span>
                      <input type='checkbox' checked={item.qr_code ? true : false} disabled={disabled} name='qr_code' value='1' id={'qr_code' + props.index} onChange={changQreHandler}/>
                      <label for={'qr_code' + props.index}>ＱＲコードに変更</label>
                  </span>
              </div>
          </div>
      </td>
  </tr>
  )
  
}

export default ValiableItemElement

