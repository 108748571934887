import React, {  useEffect } from 'react'
import Header from '../../header/Header'
import Menu from '../../menu/Menu'
import Footer from '../../footer/Footer'
import useAuthState from '../../auth/useAuthState'
import step_image from "../../images/member/step4.png"


function PasswordResetSuccessApp(props:PropsValue) {


  const {token, auth} = useAuthState()



  useEffect(() => {
    auth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  return(

        <div id="page" className="member login password resetting"> 
          <Header token={token} />
          <Menu />
          <article id="contents">
           
            <section className="s-contentsin2 topcomment1">
            <div className="step-box">
              <img src={step_image} alt="" />
            </div>
            <h1 className="stitle3">パスワード再設定完了</h1>
              <p>パスワードの再設定を完了しました。<br />
              ログインページへお進みください。</p>
            </section>
            <section className="s-contentsin2 delivery-list">
              <div className="box">
                <div className="buttonbox">
                  <div className="button or">
                    <a href="/login">ログインページへ</a>
                  </div>
                </div>
              </div>

          </section>
            </article>
          <Footer />
        </div>
  

  )
}

export default PasswordResetSuccessApp
