import React, {  } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import "./static.css"
import "../css/base.css"
function HomeApp(props:PropsValue) {

  return(
      <div id="page" className="index">
        <Header noAuth={true} />
        <Menu />



        <article id="contents">

	<main id="main" class="contentsin">
		<div id="main-box">
			<img src="/images/n/top/main01.jpg" alt="" />
		</div>
	</main>

	<section class="contentsin topcomment">
		<p class="catch-copy">
			<img src="/images/n/top/catchcopy.png" alt="圧着ハガキ専門の印刷ショップ！" />
		</p>
		<p class="comment">折は<span>2</span>種類（V型・Z型）<br class="pcbr" />印刷方法は<span>3</span>種類からお選びいただけます。</p>
	</section>

	<section class="contents" id="box-link">
		<div class="contentsin column col3">
			<div class="box">
				<div class="boxin">
					<div class="title-box">
						<h2>
							<span>配送代行も承ります</span>
							<div>宛名あり印刷</div>
						</h2>
						<p>表面に宛名を配置した圧着ハガキ印刷。宛名の位置は自由に調整いただけます。</p>
					</div>
					<div class="box-img">
						<img src="/images/n/top/img_plan01.png" alt="宛名あり印刷" />
					</div>
				</div>
				<div class="button">
					<a href="/howto"><span>入稿方法</span></a>
				</div>
			</div>

			<div class="box">
				<div class="boxin">
					<div class="title-box">
						<h2>
							<span>個別DMに最適</span>
							<div>宛名あり+可変項目印刷</div>
						</h2>
						<p>表面に宛名を中面に可変項目を配置した圧着ハガキ印刷です。宛名、可変項目の位置は自由に調整いただけます。</p>
					</div>
					<div class="box-img">
						<img src="/images/n/top/img_plan02.png" alt="宛名あり+可変項目印刷" />
					</div>
				</div>
				<div class="button">
					<a href="/howto"><span>入稿方法</span></a>
				</div>
			</div>

			<div class="box">
				<div class="boxin">
					<div class="title-box">
						<h2>
							<span>宛名印字はお客様で</span>
							<div>宛名なし印刷</div>
						</h2>
						<p>宛名はお客様で印刷いただく、シンプルな圧着印刷のみのサービスです。</p>
					</div>
					<div class="box-img">
						<img src="/images/n/common/img_plan03.png" alt="宛名なし印刷" />
					</div>
				</div>
				<div class="button">
					<a href="/howto"><span>入稿方法</span></a>
				</div>
			</div>
		</div>

	</section>




	<section class="contentsin box-plan plan01">
		<h2 class="box-title">
			<div>宛名あり印刷</div>
			<u1 class="icon">
				<li><img src="/images/n/top/print-icon01.png" alt="V字・Z字選択可能" /></li>
				<li><img src="/images/n/top/print-icon02.png" alt="宛名位置調整OK" /></li>
			</u1>
		</h2>

		<p class="explanation">表面に宛名を配置した圧着ハガキ印刷です。宛名の位置は自由に調整いただけます。</p>

		<div class="box">
			<div class="r">
				<img src="/images/n/top/img_plan01.png" alt="宛名あり印刷" />
			</div>

			<div class="l">
				<div class="box-howto">
					<h3><img src="/images/n/top/submit.png" alt="入稿方法" /></h3>
					<p>弊社で用意しておりますテンプレートを使用し、背景と宛名用のCSV（可変項目含む）をご準備ください。<br/>
					準備ができましたら、入稿ステップへお進みください。</p>
					<ul class="box-templatelink">
						<li>
							<div class="temp-box temp-ai-box">
								<a href="/howto/#download-design">デザインテンプレート<br/>
								（Adobe Illustrator）</a>
							</div>
						</li>
						<li>
							<div class="temp-box temp-xls-box">
								<a href="/howto/#download-csv">宛名テンプレート<br/>（Microsoft Exel）</a>
							</div>
						</li>
					</ul>
				</div>

				<div class="box-download">
					<div class="pattern pt-01">
						<div class="frame">
							<h4 class="frame-title"><img src="/images/n/top/preparation01.png" alt="デザインの準備" /></h4>
							<p>ハガキの背景デザインを専用テンプレートに配置し、PDFにしてご入稿ください。</p>
						</div>

						<div class="frame">
							<h4 class="frame-title"><img src="/images/n/top/preparation02.png" alt="CSVの準備" /></h4>
							<p>宛名を専用のCSVに記載し、ご入稿ください。</p>
						</div>
					</div>


					<div class="link">
						<a href="/howto">▶ データの入稿方法について詳しくはこちら</a>
					</div>
				</div>
			</div>
		</div>
		<div class="preparations-link-box">
			<div class="preparations-link-boxin">
				<p>準備が出来たら</p>
				<div class="button or">
					<a href="/order/step1?type=new" target='_blank'>
						<span>入稿ステップへ</span>
					</a>
				</div>
			</div>
		</div>
	</section>


	<section class="contentsin box-plan plan02">
		<h2 class="box-title">
			<div>宛名あり+可変項目印刷</div>
			<u1 class="icon">
				<li><img src="/images/n/top/print-icon01.png" alt="V字・Z字選択可能" /></li>
				<li><img src="/images/n/top/print-icon02.png" alt="宛名位置調整OK" /></li>
				<li><img src="/images/n/top/print-icon03.png" alt="可変項目3つ迄OK" /></li>
				<li><img src="/images/n/top/print-icon04.png" alt="QRコード変換OK" /></li>
			</u1>
		</h2>

		<p class="explanation">表面に宛名を中面に可変項目を配置した圧着ハガキ印刷です。<br/>
		宛名、可変項目（最大3つ）の位置は自由に調整いただけます。</p>

		<div class="box">
			<div class="r">
				<img src="/images/n/top/img_plan02.png" alt="宛名あり印刷" />
			</div>

			<div class="l">
				<div class="box-howto">
					<h3><img src="/images/n/top/submit.png" alt="入稿方法" /></h3>
					<p>弊社で用意しておりますテンプレートを使用し、背景と宛名用のCSV（可変項目含む）をご準備ください。<br/>
					準備ができましたら、入稿ステップへお進みください。</p>
					<ul class="box-templatelink">
						<li>
							<div class="temp-box temp-ai-box">
								<a href="/howto/#download-design">デザインテンプレート<br/>
								（Adobe Illustrator）</a>
							</div>
						</li>
						<li>
							<div class="temp-box temp-xls-box">
								<a href="/howto/#download-design">宛名テンプレート<br/>（Microsoft Exel）</a>
							</div>
						</li>
					</ul>
				</div>

				<div class="box-download">
					<div class="pattern pt-01">
						<div class="frame">
							<h4 class="frame-title"><img src="/images/n/top/preparation01.png" alt="デザインの準備" /></h4>
							<p>ハガキの背景デザインを専用テンプレートに配置し、PDFにしてご入稿ください。</p>
						</div>

						<div class="frame">
							<h4 class="frame-title"><img src="/images/n/top/preparation02.png" alt="CSVの準備" /></h4>
							<p>宛名を専用のCSVに記載し、ご入稿ください。</p>
						</div>
					</div>


					<div class="link">
						<a href="/howto">▶ データの入稿方法について詳しくはこちら</a>
					</div>
				</div>
			</div>
		</div>

		<div class="preparations-link-box">
			<div class="preparations-link-boxin">
				<p>準備が出来たら</p>
				<div class="button or">
					<a href="/order/step1?type=new" target='_blank'>
						<span>入稿ステップへ</span>
					</a>
				</div>
			</div>
		</div>
	</section>




	<section class="contentsin box-plan plan03">
		<h2 class="box-title">
			<div>宛名なし印刷</div>
			<u1 class="icon">
				<li><img src="/images/n/top/print-icon01.png" alt="V字・Z字選択可能" /></li>
			</u1>
		</h2>

		<p class="explanation">宛名はお客様で印刷いただく、シンプルな圧着印刷のみのサービスです。</p>

		<div class="box">
			<div class="r">
				<img src="/images/n/top/img_plan03.png" alt="宛名あり印刷" />
			</div>

			<div class="l">
				<div class="box-howto">
					<h3><img src="/images/n/top/submit.png" alt="入稿方法" /></h3>
					<p>弊社で用意しておりますテンプレートを使用し、背景をご準備ください。<br/>
					準備ができましたら、入稿ステップへお進みください。</p>
					<ul class="box-templatelink">
						<li>
							<div class="temp-box temp-ai-box">
								<a href="/howto/#download-design">デザインテンプレート<br/>
								（Adobe Illustrator）</a>
							</div>
						</li>
					</ul>
				</div>

				<div class="box-download">
					<div class="pattern pt-02">
						<div class="frame">
							<h4 class="frame-title"><img src="/images/n/top/preparation01.png" alt="デザインの準備" /></h4>
							<p>ハガキの背景デザインを専用テンプレートに配置し、PDFにしてご入稿ください。</p>
						</div>
					</div>


					<div class="link">
						<a href="/howto">▶ データの入稿方法について詳しくはこちら</a>
					</div>
				</div>
			</div>
		</div>

		<div class="preparations-link-box">
			<div class="preparations-link-boxin">
				<p>準備が出来たら</p>
				<div class="button or">
					<a href="/order/step1?type=new" target='_blank'>
						<span>入稿ステップへ</span>
					</a>
				</div>
			</div>
		</div>
	</section>


</article>



        <Footer />
      </div>


  )
}

export default HomeApp
