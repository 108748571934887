
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import Action from '../action/Action';

const ProfileAction = {


  saveOnCookie(item){
    cookie.save('tmp_profile', item, { path: '/' })
  },

  loadFromCookie(callback){
    var item =  cookie.load("tmp_profile")
    callback(item)
  },

  removeFromCookie(){
    cookie.remove("tmp_profile",{ path: '/' })
  },


  get( token,  handler){

    const path = config.get("API_PATH") + "/api/profiles/me" 
    Action.fetchAuthorizedJson(  path,token, "GET", {}, null,  handler)
  },

  update(token, item, handler, errorHandler){
    const path = config.get("API_PATH") + "/api/profiles/me" 
    const header =  {'Content-Type': 'application/json'}

    Action.fetchAuthorizedJson(  path,token, "PATCH", header, JSON.stringify(item),  handler)
    /**
    fetch(config.get("API_PATH") + "/api/profiles/me"  , {
      method: "PATCH",
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        handler(data)
      }else{
        errorHandler(data)
      }
    }) */
  },

  updateEmail(token, email, handler, errorHandler){
    const path = config.get("API_PATH") + "/api/profiles/email/reset" 
    const header =  {'Content-Type': 'application/json'}

    Action.fetchAuthorizedJson(  path,token, "PATCH", header, JSON.stringify({email:email}),  handler, errorHandler)
    /**
    fetch(config.get("API_PATH") + "/api/profiles/me"  , {
      method: "PATCH",
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        handler(data)
      }else{
        errorHandler(data)
      }
    }) */
  },

  delete( token, password,  handler, errorHandler){
    const path = config.get("API_PATH") + "/api/profiles/me" 
    Action.fetchAuthorizedJson( path, token, "POST", {"content-type":"application/json"}, JSON.stringify({password:password}),  handler, errorHandler)
  },

  removeErrors(){
    cookie.remove("tmp_account_error",{ path: '/' })
  },

  loadErrors(callback){
    var item =  cookie.load("tmp_account_error")
    callback(item)
  },

 
}

export default ProfileAction
