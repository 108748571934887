import React, { useState, useEffect } from 'react'
import ProfileDetail from './ProfileDetail';


function ProfileWindow(props:PropsValue) {

  const [is_confirm, setIsConfirm] = useState(false)
  const [item, setItem] = useState(null)

  
  useEffect(() => {
    if(props.item){
      setItem(props.item)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.item]);

  const confirm = (_item) => {
    setIsConfirm(true)
    //props.submit(_item)
  }
  const submit = (_item) => {
    props.submit(_item)
  }

  const cancel = () => {
    setIsConfirm(false)
  }

  return(
    <div id="openwindow">
       <article id="contents" className="">
        <p></p>
        <div id="contentsbox" className="contentsin s-contentsin2 delivery-list">
          <div className="topcomment">
              
          </div>
          { !is_confirm ? <ProfileDetail profile={item} is_confirm={false} confirm={confirm} submit_text="登録内容を確認"/> : 
          <ProfileDetail profile={item} is_confirm={true} confirm={submit} submit_text="登録" btn={
            <div className="button gr">
              <div onClick={cancel} >修正する</div>
            </div>
          }/> }
        </div> 
        </article>
      <div id="window-bg"></div>
	</div>
  )
}

export default ProfileWindow
