
import 'whatwg-fetch'
import config from 'react-global-configuration';
import Action from '../action/Action';


const PriceAction = {

  get( token, specififation, number, delivery_pref_id, thickness, handler, errorHandler = null){

    var path = config.get("API_PATH") + "/api/prices?specification=" + specififation + "&sheet=" +number 
    if(delivery_pref_id) {
      path = path + "&delivery_pref_id=" + delivery_pref_id
    }
    if(thickness) {
      path = path + "&thickness=" + thickness
    }
    Action.fetchAuthorizedJson(  path,token, "GET", {}, null,  handler, errorHandler, {number:number})
  
  },

  getWithCsv( token, item, handler, errorHandler = null){

    const formData = new FormData();
    formData.append('csv', item.csv);
    formData.append('specification', item.specification);
    formData.append('plan', item.plan);

    const header = {}

    Action.fetchAuthorizedJson(config.get("API_PATH") + "/api/prices/csv" , token, "POST", header, formData, handler, errorHandler)


  },

  
 
}

export default PriceAction
