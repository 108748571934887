import React ,{useEffect} from 'react';
import PropTypes from 'prop-types';




export const Pageing = ({total_number,current_page, path,limit,additional_params}) => {

  const page_number = Math.ceil(total_number / limit)

  const params =  "&" + additional_params.join("&")

  useEffect(() => {
    console.log(total_number)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[total_number]);


  return (
    <div className="pagenavi">
		{Number(current_page) !== 1 ? <a className="previouspostslink" rel="prev" href={path + "?page=" + (Number(current_page) - 1) + params} >前へ</a> : null }
		  
        {
          (new Array(page_number)).fill(0).map((i,index) => {
           
            return(
               Number(current_page) === (index+1) ? 
                <span className="current">{index + 1}</span>
                : <a href={path + "?page=" + (index + 1) + params}  key={"page" + index} className="page larger">{index + 1}</a>
            
            )
          })
        }
			 				
		
		{Number(current_page)  !== page_number  ? <a href={path + "?page=" + (Number(current_page) + 1) + params} className="nextpostslink"> 次へ</a> : null}
	</div>
)};

Pageing.propTypes = {
  total_number:PropTypes.number,
  current_page:PropTypes.number,
  path:PropTypes.string,
  limit:PropTypes.number,
  additional_params:PropTypes.array
};

Pageing.defaultProps = {
  total_number: null,
  current_page:1,
  limit:10,
  path:"",
  additional_params:[]

};

