import React, { useEffect , useState} from 'react'
import { useLocation } from 'react-router-dom';
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import UserMenu from '../menu/UserMenu'

import HistoryItem from './HistoryItem'
import { Pageing } from '../pageing/Pageing'
import useAuthState from '../auth/useAuthState'
import useSendingState from './useSendingState'

function PasswordApp(props) {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const page = query.get('page')
  const current_page = page ? page : 1
  const [orders_html, setOrdersHtml] = useState(null)
  const {token, auth} = useAuthState()
  const [total_number, setTotalNumber] = useState(null)
  const {orders, order_total_munber, deletes, duplicated_date, removeSending, sending_updated_date, getSending, duplicate, duplicateWithCsv} = useSendingState()
  
  useEffect(() => {
    auth()
    reloadSending()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);

  useEffect(() => {
    if(duplicated_date)
      window.location.href = "/order/step6"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[duplicated_date]);


  useEffect(() => {
    
    setTotalNumber(order_total_munber)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[order_total_munber]);


  const deletItem = (id) => {
    removeSending(token, id)
  }

  const duplicateItem = (id) => {
    duplicate(token, id)
  }

  const duplicateItemWithCsv = (id, csv) => {
    duplicateWithCsv(token, id, csv)
  }

  useEffect(() => {
 
    if(orders){
      setOrdersHtml(
        orders.map((item, index) => {
     
           return <HistoryItem
             key={`sending_${index}`}
             token={token}
             item={item}
             index={index}
             callback={reloadSending}
             deletItem={deletItem}
             duplicate={duplicateItem}
             duplicateItemWithCsv={duplicateItemWithCsv}
           />
        })
       )
    }
      

      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sending_updated_date,orders,deletes]);
  
  const reloadSending = () => {
    if(token) {
      getSending(token, current_page)
    }
  }

  return(
    <div id="page"> 
      <Header token={token} className="member order"/>
      <Menu />
      <article id="contents">
     
        <UserMenu selectMenuItem="order" />

        <section className="s-contentsin2 draft-data-list">
        <h1 className="stitle">注文情報一覧</h1>

        <p className="comment">過去にご注文いただいた情報をご確認いただけます。<br/>
        注文内容の変更、お知らせがあった場合は、管理者より備考欄にてお知らせいたします。</p>

          {orders_html}
          {total_number ? 
          
          <Pageing limit={10} current_page={current_page} total_number={total_number} path={"/order-history"}/>
          :null}
        </section>  
        </article>
      <Footer />
    </div>
  )
}

export default PasswordApp
