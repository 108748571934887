import React, { useState, useEffect } from 'react'
import Account from '../Account'
import config from 'react-global-configuration';
import Message from '../../message/Message'
import useLoginState from './useLoginState';
import "../../css/member.css"

function LoginForm(props:PropsValue) {


  const [item, setItem] = useState(new Account())
  const [erros, setErrors] = useState([])



  const {token, loginError, login} = useLoginState()



  useEffect(() => {
    if(loginError && loginError.error === 1){
      props.messageHandler( <li>{Message.getMessage("login.error")}</li>)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loginError]);

  useEffect(() => {
    //TODO  redirect
    if(token)
      window.location.href = "/order-history"
  },[token]);

  const changeHandler = (e) => {
    const target = e.target
    setItem(item.set(target.name, target.value))
  }

  const submit = () => {
    const _errors = []
    //mail 
    if(!item.email){
      _errors.push("email.required")
    }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(item.email)) {
      _errors.push("email.email:filter")
    }
    //password 
    if(!item.password){
      _errors.push("password.required")
    }else if(item.password.length < config.get("PASSWORD_MIN")){
      _errors.push("password.min")
    }

    setErrors(_errors)

    
    if(_errors.length > 0){
      //ErrorMessage
      props.messageHandler(_errors.map(error => {
       return ( <li>{Message.getMessage(error)}</li>)
      }))
    }else{
      //Login
      login(item)
      props.messageHandler(null)
    }
  }
  

  return(
    <section className="contentsin loginbox">
    

      <div className="loginboxin">
        <form id="login">
            <div className="l">
                <h2>ログイン</h2>
                <dl className={Message.hasMessge(erros, "email") ? "err" : null}>
                    <dt>ID（メールアドレス）</dt>
                    <dd>
                        <input type="email" name="email" id="id" className="inputbox02" value={item.email} placeholder="ID（メールアドレス）" required autocomplete="email" autofocus onChange={changeHandler} />
                    </dd>
                </dl>
                <p class="explanation"><a href="/public/inquiry">IDを忘れた方は弊社までお問い合わせください。</a></p>

                <dl className={Message.hasMessge(erros, "password") ? "err" : null}>
                    <dt>パスワード</dt>
                    <dd>
                        <input type="password" name="password" id="password" className="inputbox02" value={item.password}  placeholder="パスワード" required autocomplete="current-password" onChange={changeHandler}/>
                    </dd>
                </dl>
                <p class="explanation"><a href="/password/reset">パスワードを忘れた方はこちらへ</a></p>
                

                <div className="input-button login">
                    <input type='button' name='login' value='ログイン' id='register' />
                    <label for='login' onClick={submit}>ログイン</label>
                </div>

                
            </div>
        </form>

        <div className="r">
            <p className="ct">仮会員登録はこちらからご登録ください</p>

            <div className="input-button register" >
                <input type='button' name='register' value='会員登録' />
                <label for='register' onClick={() => window.location.href="/register"}>仮会員登録</label>
            </div>
            
            <a id="goRegister" href="/register" className="d-none"> </a>

            <p>入稿ページにお進みいただくためには、仮登録を行っていただく必要がございます。ご入稿前にご登録をお願いいたします。</p>
        </div>
      </div>
    </section>

  )
}

export default LoginForm


