const PdfLayoutDefaultInfo = {
  DEFAULT_WIDTH : 50,
  DEFAULT_HEIGHT : 5,
  ITEM_MARGIN:5,
  DEFAULT_W_1:2,
  DEFAULT_W_2:3.8,


  SPEC_V:{
    X0:45,
    X1:70,
    X2:160,
    X3:91.6,
    XR:140,
    XR0:45,
    Y0:95,
    Y1:38.5,
    Y2:60,
    Y3:85,
    Y4:120,
    Y5:140,
    Y6:160,
    W1:95,
    W2:58,
    W3:65,
    W4:18,
    W5:24,
    H1:8,
    H2:8,
    H3:12,
    QR_X:185,
    QR_X_B:45,
    QR_Y:35,
    QR_Y_B:130,
    POST_CODE_FONT:19,
    POST_CODE_LETTER_SPACE:9.5,
    ERROR_RECT_1:[
      {x1:0, x2:245, y1:0, y2:28},
      {x1:0, x2:28, y1:0, y2:203},
      {x1:0, x2:245, y1:176, y2:203},
      {x1:220, x2:250, y1:0, y2:203}
    ],  
    ERROR_RECT_2:[
      {x1:0, x2:245, y1:0, y2:28},
      {x1:0, x2:28, y1:0, y2:203},
      {x1:0, x2:245, y1:176, y2:203},
      {x1:220, x2:250, y1:0, y2:203}
    ],
    
  },
  SPEC_Z:{
    X0:45,
    X1:70,
    X2:180,
    X3:91.6,
    XR:140,
    XR0:140,
    Y0:95,
    Y1:38.5,
    Y2:60,
    Y3:85,
    Y4:120,
    Y5:140,
    Y6:160,
    W1:95,
    W2:58,
    W3:65,
    W4:18,
    W5:24,
    H1:5,
    H2:8,
    H3:12,
    QR_X:170,
    QR_X_B:170,
    QR_Y:95,
    QR_Y_B:95,
    POST_CODE_FONT:19,
    POST_CODE_LETTER_SPACE:9.5,
    ERROR_RECT_1:[
      {x1:0, x2:346, y1:0, y2:28},
      {x1:0, x2:28, y1:0, y2:203},
      {x1:0, x2:346, y1:176, y2:203},
      {x1:317, x2:346, y1:0, y2:203}
    ],
    ERROR_RECT_2:[
      {x1:0, x2:346, y1:0, y2:28},
      {x1:0, x2:28, y1:0, y2:203},
      {x1:0, x2:346, y1:176, y2:203},
      {x1:317, x2:346, y1:0, y2:203}
    ],
  },
  ADDTIONAL_POSITION_Z_1:["A","B","C"],
  ADDTIONAL_POSITION_Z_2:["D","E","F"],
  ADDTIONAL_POSITION_V_1:["A","B"],
  ADDTIONAL_POSITION_V_2:["C","D"],
}
export default PdfLayoutDefaultInfo

