import React, { } from 'react'

function ConfirmDetailItem(props:PropsValue) {


  return(

    <section className="s-contentsin2 section pb-5 mb-5">
    <h2 className="titlebox">
        <div className="titleboxin">宛名データの確認</div>
    </h2>

    <p>お客様がCSVに記載された『通し番号1』の内容をサンプルとして表示しています。<br />
    項目と列が間違っていないかをご確認ください。</p>

    <table className="csv-check-table">
        <thead>
            <tr>
                <th>
                    見出し
                </th>
                <th>
                    通し番号1の内容
                </th>
            </tr>
        </thead>

        <tbody>
            <tr>
                <th>
                    郵便番号
                </th>
                <td>
                    <div>
                        <label for="paper">
                          {props.csv ? props.csv[0][1] : null}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <th>
                    住所
                </th>
                <td>
                    <div>
                        <label for="paper">
                        {props.csv ? props.csv[0][2] : null}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <th>
                    会社名
                </th>
                <td>
                    <div>
                        <label for="paper">
                        {props.csv ? props.csv[0][6] : null}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <th>
                    お名前
                </th>
                <td>
                    <div>
                        <label for="paper">
                        {props.csv ? props.csv[0][7] : null}{props.csv ? props.csv[0][8] : null}{props.csv ? props.csv[0][9] : null}
                        </label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</section>

  )
}

export default ConfirmDetailItem
