import React, { useEffect , useState} from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import UserMenu from '../menu/UserMenu'

import SendingHistoryContent from './SendingHistoryContent'

import useAuthState from '../auth/useAuthState'
import useSendingState from './useSendingState'

function SendingApp(props) {
  const [copy_error, setCopyError] = useState(null)
  const [orders_html, setOrdersHtml] = useState(null)
  const [deletes_html, setDeletsHtml] = useState(null)
  const {token, auth} = useAuthState()
  const {orders, error, deletes, duplicated_date, removeSending, sending_updated_date, getSending, duplicate, duplicateWithCsv} = useSendingState()
  
  useEffect(() => {
    auth()
    reloadSending()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);

  useEffect(() => {
    if(duplicated_date)
      window.location.href = "/order/step7"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[duplicated_date]);

  useEffect(() => {
    if(error)
      setCopyError("枚数は、1000枚以上, 20000枚以下を指定してください")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[error]);


  const deletItem = (id) => {
    removeSending(token, id)
  }

  const duplicateItem = (id) => {

    if(orders.length >= 3){
      alert("入稿データがすでに3つ保存されています。マイページからデータを1つ削除してから、新しい入稿を行ってください。")
    }else{
      duplicate(token, id)
    }

    
  }

  const duplicateItemWithCsv = (id, csv) => {

    if(orders.length >= 3){
      alert("入稿データがすでに3つ保存されています。マイページからデータを1つ削除してから、新しい入稿を行ってください。")
    }else{
      duplicateWithCsv(token, id, csv)
    }
   
  }


 


  useEffect(() => {
 
    if(orders){
      setOrdersHtml(
        orders.reverse().map((item, index) => {
     
           return <SendingHistoryContent
             key={`sending_${index}`}
             token={token}
             item={item}
             index={index}
             callback={reloadSending}
             deletItem={deletItem}
             duplicate={duplicateItem}
             duplicateItemWithCsv={duplicateItemWithCsv}
           />
        })
       )
    }
      
    if(deletes){
      setDeletsHtml(
        deletes.map((item, index) => (
          <div className="box delete" key={`delete_${index}`}>
            <div className="boxin">
              <p><strong>{item.filename ? item.filename : item.data_name}</strong>　は削除されました</p>
            </div>
          </div>		
        ))
      )
    }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sending_updated_date,orders,deletes]);
  
  const reloadSending = () => {
    if(token) {
      getSending(token)
    }
  }

  return(
    <div id="page"> 
      <Header token={token} />
      <Menu />
      <article id="contents">
        <section className="topcomment">
          <h1>マイページ・入稿データ一覧</h1>
          <p>入稿データや会員情報の編集、過去の注文履歴の確認などを行っていただけます。</p>
          {/*
          <p className="text-blue">{'message'}</p>
          <p className="text-red">{'error'}</p>
          */}
          {copy_error ? <p className="text-red">{copy_error}</p> : null}
        </section>
        <UserMenu selectMenuItem="mypage" />

        <section className="s-contentsin2 draft-data-list">
          <h2 className="stitle">入稿データ一覧・再入稿</h2>

          <p className="comment">過去に入稿いただいたデータを最大3つまで保存し、再入稿できます。<br />
          再入稿では、印刷枚数（「宛名あり」をお選びであればCSVの行数の変更が枚数となります）や紙質などを変更していただけます。<br />
          詳しくは、ヘルプ　　をご覧ください。</p>


          {orders_html}

          {deletes_html}

          {(!orders || orders.length < 3) &&
            <div className="button new or">
              <a href="/order/step1">新規入稿</a>
            </div>
          }

        </section>
        </article>
      <Footer />
    </div>
  )
}

export default SendingApp
