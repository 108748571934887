import React, { useState, useEffect } from 'react'
import config from 'react-global-configuration';


function CsvPreview(props:PropsValue) {

  const [th_row, setThRow] = useState(null)
  const [tritems, setTrItems] = useState(null)

  useEffect(() => {
    var nums = props.csv[0].length  - config.get("CSV_FIXED_LENGTH")
    nums = nums > 3 ? 3 : nums
    const items = (new Array(nums )).fill(null).map((item, index) => {
      return <th>項目{index+1}</th>
    })
   
    setThRow(items) 
    const tr_items = props.csv.map((item) => {
      const _tds = item.map((ele,index) => {
        if(index < nums + config.get("CSV_FIXED_LENGTH"))
          if(index === 1){
            return [<td>{ele.substr(0,3)}</td>,<td>{ele.substr(4,7)}</td>]
          }else{
            return [<td>{ele}</td>]
          }
          
        else
          return null
      })
      return <tr>{_tds}</tr>
    })
   
    setTrItems(tr_items) 
  },[props.max_length, props.csv]);

  return(

    <section className="contents pb-5 mb-5">
                <div className="csv-include">
                    <table>
                      <thead>
                        <tr>
                            <th>通し番号</th>
                            <th colspan="2">郵便番号</th>
                            
                            <th>都道府県</th>
                            <th>市町村</th>
                            <th>番地</th>
                            <th>その他住所</th>
                            <th>会社名</th>
                            <th>性</th>
                            <th>名</th>
                            <th>敬称</th>
                            {th_row}
                        </tr>
                        </thead>
                        <tbody>
                        {tritems}
                        </tbody>
                    </table>
                </div>
            </section>


  )
}

export default CsvPreview
