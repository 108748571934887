import React, { useState, useEffect } from 'react'
import GetCsvRowItem from '../GetCsvRowItem';
import useSamplePdfState from './useSamplePdfState';
import RequestValue from '../../RequestValue';

function SamplePdfDownload(props:PropsValue) {
  
  const [target_page, setTargetPage] = useState(null)
  const {pdf,pdfUpdatedDate,createPdf} = useSamplePdfState()

  useEffect(() => {
    if(pdfUpdatedDate){
      const a = document.createElement("a");
      const url = props.url.createObjectURL(pdf)
      document.body.appendChild(a);
      a.download = 'sample_' + props.item.id + ".pdf";
      a.href = url;
      a.click();
      a.remove();
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 1E4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pdfUpdatedDate])
  /**
  useEffect(() => {
    if(template ){
        template['columns'].push('watermark');
        template['schemas'][0]['watermark'] = {
          type: 'text',
          //fontName : 'ipag',
          fontColor : '#F3F3F3',
          fontSize : 30,
          position : { x: 70, y: 130 },
          rotate : 35,
          width : 150,
          height: 20,
        };
      
      const sche = template.schemas[0]
     
     
   
      sample['watermark'] = 'あさひ高速印刷サンプルPDF';
      //water mark
      const sample_with_mark = pdfUtils.addWaterMarkOnSchema(sample)
     

      const inputs = [sample_with_mark]

      generate({ template, inputs, options:{ fontList}} ).then((_pdf) => {

        const blob = new Blob([_pdf.buffer], { type: 'application/pdf' })
        const a = document.createElement("a");
        const url = URL.createObjectURL(blob)
        document.body.appendChild(a);
        a.download = 'sample_' + current_id + ".pdf";
        a.href = url;
        a.click();
        a.remove();
        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 1E4);

      }); 
      
      if(current_id === 1){
        setCurrentId(2)
        //裏
        getTemplate(props.token, props.item.id, 2)
      }
      else if(current_id === 2){
        setCurrentId(1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[template_updated_at]);

 */

  const downloadTargetSample = () => {
  
    const data =  GetCsvRowItem.getPageItem(props.item, props.item.csv[target_page - 1])
    
    const sample = GetCsvRowItem.getFullDate(data)

    createPdf(props.token, props.item.id, sample)
    //const sample = GetCsvRowItem.getSampleData(data,sche)
  }

  const downloadSample = () => {
  
    const data = GetCsvRowItem.getLongestItem(props.item.csv )
    
    const sample = GetCsvRowItem.getFullDate(data)

    createPdf(props.token, props.item.id, sample)
    //const sample = GetCsvRowItem.getSampleData(data,sche)
  }

  const downloadSampleWithOutData = () => {
    createPdf(props.token, props.item.id, null)
    //const sample = GetCsvRowItem.getSampleData(data,sche)
  }


  return(
    <div className="contents button-area">
      
      <p>配置イメージを PDF でご確認いただけます。ダウンロードしてご確認ください。</p>
      {props.item && props.item.plan !== RequestValue.PLAN_NO_ATENA ? 
      <div>
      <dl>
        <dt>
          <div className="input-button-download">
            <div className="buttonbox">
              <span><input type='button' name='download' value='サンプルページをダウンロード' id='download' onClick={downloadSample} /><label for='download'>サンプルページをダウンロード</label></span>
            </div>
          </div>
        </dt>
        <dd>CSV の各項目の一番長い文字列を配置した確認用のページになります。</dd>
      </dl>
      <dl>
        <dt>
          <span className="numname1">通し番号</span><input type="" name="pagenum" id="pagenum" className="pagenum" placeholder="" onChange={(e) => setTargetPage(Number(e.target.value))} /><span className="numname2">ページを</span>
          <div className="input-button-download specify">
            <div className="buttonbox">
              <span><input type='button' name='download' value='指定番号のページをダウンロード' id='downloadP' onClick={downloadTargetSample} /><label for='downloadP'>ダウンロード</label></span>
            </div>
          </div>
        </dt>
        <dd>ご指定いただいたページをサンプルとしてダウンロードできます。</dd>
      </dl> 
      </div>
      :
      <div>
      <dl>
      <dt>
        <div className="input-button-download">
          <div className="buttonbox">
            <span><input type='button' name='download' value='サンプルページをダウンロード' id='download' onClick={downloadSampleWithOutData} /><label for='download'>サンプルページをダウンロード</label></span>
          </div>
        </div>
      </dt>
     
    </dl>
   </div> }


    </div>
  )
}

export default SamplePdfDownload
