import React, { useState, useEffect } from 'react'
import Request from '../Request';
import RequestValue from '../RequestValue';
import useRequestState from './useRequestState';
import useCartState from '../cart/useCartState';
import image_v from "../../images/step/specification-v.png"
import image_z from "../../images/step/specification-z.png"

function OrderForm(props:PropsValue) {
  
  const [item, setItem] = useState(new Request())
  const [price, setTotalPrice] = useState(null)
  const [is_loading, setIsLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const [message_num, setMessageNum] = useState([])
  const [csv_number, setCsvNumber] = useState(null)
  const {total_price, total_number_in_csv, price_update_at, price_error, getTotalPrice} = useRequestState()
  const {cart, createCartItem} = useCartState()


  
  useEffect(() => {

    if(props.item){
      setItem(new Request(props.item))
      console.log(props.item.plan)
      console.log(RequestValue.PLAN_NO_ATENA)
      console.log(RequestValue.PLAN_NO_ATENA)
      if(props.item.plan !== RequestValue.PLAN_NO_ATENA){
  
        setTotalPrice(null)
      }else{

        getTotalPrice(props.token, props.item)
      }
     
      if(props.item.csv) setCsvNumber(props.item.csv.length)
      //getTotalPrice(props.token, props.item)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.item]);

  useEffect(() => {
    if(cart && cart.id){
      props.moveToNextStep(cart)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[item]);

  useEffect(() => {
    if(cart && cart.id){
      props.moveToNextStep(cart)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cart]);

  useEffect(() => {
 
    setIsLoading(false)
    setTotalPrice(total_price)
       // eslint-disable-next-line react-hooks/exhaustive-deps
  },[price_update_at,total_price]);


  useEffect(() => {

    if(price_error){
      setIsLoading(false)
      if(price_error === "error1"){
        setMessage(<li>枚数は、1,000枚から20,000枚までのCSVデータをご利用ください。</li>)
      } else if(price_error === "error3" || price_error === "error2"){
        setMessage(<li>項目が規定の数を超過しています。CSVデータをご確認ください。</li>)
      } else if(price_error === "error4"){
        setMessage(<li>可変項目のデータがありません。CSVデータをご確認ください。</li>)
      }else{  
        setMessage([<li> CSVデータ以外はアップロードいただけません。</li>,<li> CSVデータの列が破損している可能性。CSVデータをご確認ください。</li>])
      }
      

    }else{
      setMessage(null)
    }
     
       // eslint-disable-next-line react-hooks/exhaustive-deps
  },[price_error]);

  useEffect(() => {

    if(total_number_in_csv)
     
      setCsvNumber(total_number_in_csv)
       // eslint-disable-next-line react-hooks/exhaustive-deps
  },[total_number_in_csv,price_update_at]);


 const changeHandler = (e) => {
    if(is_loading) return false 

    const target = e.target
    const _item = item.set(target.name, target.value)
   
    if(target.name === "plan" && target.value === RequestValue.PLAN_NO_ATENA){
      setCsvNumber(null)
      setTotalPrice(null)
      setItem(_item)
    }else if(target.name === "plan"){
      const __item = _item.set("sheet", null)
      setTotalPrice(null)
      setCsvNumber(null)
      setItem(__item)
    }else if(target.name === "specification"){
      if(target.value === RequestValue.SPECIFICATION_V){
        const __item = _item.set("thickness", RequestValue.THICKNESS_110)
        setItem(__item)
      }
      if(target.value === RequestValue.SPECIFICATION_Z){
        const __item = _item.set("thickness", RequestValue.THICKNESS_90)
        setItem(__item)
      }
    }else{
      
      if((_item.plan !== item.plan || _item.sheet !== item.sheet) && Number(_item.sheet) >= 1000 ){
        setTotalPrice(null)
        setIsLoading(true)
        getTotalPrice(props.token, _item)
      }
  
      setItem(_item)
    }
    
  }

  const changeCsvFileHandler  = (e) => {
    const target = e.target
    if(target.files && target.files.length !== 0){
      const _item = item.set("csv", target.files[0]).set("sheet", null)
     
      setItem(_item)
      setMessage(null)
      setMessageNum([])
      setTotalPrice(null)
      setCsvNumber(null)
      getTotalPrice(props.token, _item)
      target.value = null
    }
  }
  /**
  const estimate = () => {
    setMessage(null)
    getTotalPrice(props.token, item)
  }
 */
  const nextStep = (e) => {
    e.preventDefault();

    const errors = []
    if((item.plan === RequestValue.PLAN_NO_ATENA  &&  !item.sheet ) || (item.plan === RequestValue.PLAN_NO_ATENA  &&  item.sheet < 1000) || (item.plan === RequestValue.PLAN_NO_ATENA  &&  item.sheet > 20000) || (item.plan === RequestValue.PLAN_NO_ATENA  && item.sheet && item.sheet > 0 &&  item.sheet % 100 !== 0 )){
      errors.push("sheet_number")
      //setMessageNum("枚数は100枚単位で入力してください。")
    }
    if(!item.plan){
      errors.push("plan")
    }

    if(item.plan !== RequestValue.PLAN_NO_ATENA  && ( !csv_number)){
      errors.push("csv")
      //setMessageNum("枚数は100枚単位で入力してください。")
    }
    
    if(!item.aggrement  || item.aggrement !== "1"){
      errors.push("aggrement")
    }
    if(errors.length > 0){
      console.log(errors)
      setMessageNum(errors)
    }
    else{
      console.log("no error")
      setMessageNum([])
      createCartItem(props.token, item)  
    }
    //
  }

  return(
      <div>
        <section className="s-contentsin2 section form my-5 py-5">
            <h2 className="titlebox mb-p0">
                <div className="titleboxin">ご注文フォーム<span className="required">必須</span></div>
                <div className="question"><a href="/#"><img src="../images/common/question.png" alt="" /></a></div>
            </h2>
    
            <table className="form-table">
                <tr className="">
                    <th>
                        <span className="ls10">仕様</span>
                    </th>
                    <td>
                        <div className="list specification">
                            <div>
                                <img src={image_v} alt="" />
                                <span>
                                    <input type='radio' name='specification' value={RequestValue.SPECIFICATION_V}  id='specification01'  checked={item.specification === RequestValue.SPECIFICATION_V ? "checked" : "" } onChange={changeHandler}/>
                                    <label htmlFor='specification01'>{RequestValue.SPECIFICATION_V}</label>
                                </span>
                            </div>
                            <div>
                                <img src={image_z} alt="" />
                                <span>
                                    <input type='radio' name='specification' value={RequestValue.SPECIFICATION_Z} id='specification02'  checked={item.specification === RequestValue.SPECIFICATION_Z ? "checked" : "" } onChange={changeHandler}/>
                                    <label htmlFor='specification02'>{RequestValue.SPECIFICATION_Z}</label>
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>
                        <span className="ls10">用紙</span>
                    </th>
                    <td>
                        <div className="list">
                            <div>
                                <span>
                                    <input type='radio' name='paper'  id='paper01' value={RequestValue.PAPER_MAT}  checked={item.paper === RequestValue.PAPER_MAT ? "checked" : "" } onChange={changeHandler}/>
                                    <label for='paper01'>{RequestValue.PAPER_MAT}</label>
                                </span>
                            </div>
                            
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>
                        用紙の厚み
                    </th>
                    <td>
                        <div className="list">
           
                          { item.specification === RequestValue.SPECIFICATION_Z ?
                            <div>
                                <span>
     
                                  <input type='radio' name='thickness'  id='thickness01' value={RequestValue.THICKNESS_90}  checked={item.thickness === RequestValue.THICKNESS_90 ? "checked" : "" } onChange={changeHandler}/>
                                    <label for='thickness01'>{RequestValue.THICKNESS_90}</label>
                                </span>
                            </div>
                             : null}
                            <div>
                                <span>
                                    <input type='radio' name='thickness' value={RequestValue.THICKNESS_110}  checked={item.thickness === RequestValue.THICKNESS_110 ? "checked" : "" } onChange={changeHandler} />
                                    <label for='thickness02'>{RequestValue.THICKNESS_110}</label>
                                </span>
                            </div>
                            { item.specification === RequestValue.SPECIFICATION_V ?
                            <div>
                                <span>
                                    <input type='radio' name='thickness' value={RequestValue.THICKNESS_135}  checked={item.thickness === RequestValue.THICKNESS_135 ? "checked" : "" } onChange={changeHandler}/>
                                    <label for='thickness03'>{RequestValue.THICKNESS_135}</label>
                                </span>
                            </div>
                            : null}
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>
                        加工方法
                    </th>
                    <td>
                        <div className="list">
                            <div>
                                <span>
                                    <input type='radio' name='processing' id='processing01'  value={RequestValue.PROCESSING_ZURASHI}  checked={item.processing === RequestValue.PROCESSING_ZURASHI ? "checked" : "" } onChange={changeHandler} />
                                    <label for='processing01'>{RequestValue.PROCESSING_ZURASHI}</label>
                                </span>
                            </div>
                        </div>
                      </td>
                </tr>

                <tr>
                    <th>
                        圧着方法
                    </th>
                    <td>
                        <div className="list">
                            <div>
                                <span>
                                    <input type='radio' name='process_type' id='process_type01'  value={RequestValue.PROCESS_TYPE_NORMAL}  checked={item.process_type === RequestValue.PROCESS_TYPE_NORMAL ? "checked" : "" } onChange={changeHandler} />
                                    <label for='process_type01'>{RequestValue.PROCESS_TYPE_NORMAL}</label>
                                </span>
                            </div>
                        </div>
                      </td>
                </tr>

                <tr className={message_num.findIndex(error => error === "plan") !== -1 ? "err" : null}>
                    <th>
                        印刷プラン
                    </th>
                    <td data-trigger="plan">
                        <div className="input-button-plan">
                            <div>
                                <span>
                                    <input type='radio' name='plan' id='plan01'  value={RequestValue.PLAN_ATENA}  checked={item.plan === RequestValue.PLAN_ATENA ? "checked" : "" } onChange={changeHandler} />
                                    <label for='plan01'>
                                        <span>{RequestValue.PLAN_ATENA }</span>
                                        <span>宛名CSV+背景データが必要です</span>
                                    </label>
                                </span>
                            </div>

                            <div>
                                <span>
                                    <input type='radio' name='plan' id='plan02'  value={RequestValue.PLAN_ATENA_NAKA}  checked={item.plan === RequestValue.PLAN_ATENA_NAKA ? "checked" : "" } onChange={changeHandler} />
                                    <label for='plan02'>
                                        <span>{RequestValue.PLAN_ATENA_NAKA }</span>
                                        <span>CSVデータ+背景データが必要です</span>
                                    </label>
                                </span>
                            </div>

                            <div>
                                <span>
                                    <input type='radio' name='plan' id='plan03'  value={RequestValue.PLAN_NO_ATENA}  checked={item.plan === RequestValue.PLAN_NO_ATENA ? "checked" : "" } onChange={changeHandler} />
                                    <label for='plan03'>
                                        <span>{RequestValue.PLAN_NO_ATENA }</span>
                                        <span>背景データが必要です</span>
                                    </label>
                                </span>
                            </div>
                            {message_num.findIndex(error => error === "plan") !== -1 ? 
                              <ul class="error-box">
                               <li>印刷プランを指定してください。</li>
                              </ul>
                             : null
                            }

                        </div>
                    </td>
                </tr>

                { item.plan === RequestValue.PLAN_NO_ATENA ? 
                <tr className={message_num.findIndex(error => error === "sheet_number") !== -1 ? "err" : null}　>
                    <th>
                        <span className="ls10">枚数</span>
                    </th>
                    <td>
                        <div className="textbox">
                            <div>
                                
                                <input type="number" name="sheet" id="sheet" className="inputbox01" min="1000" step="100" maxLength="5" placeholder="" value={item.sheet} onChange={changeHandler} />　枚
                            </div>
                            <p className="min">※1000枚から100枚単位でご入力ください。最大枚数は20000枚です。</p>
                        </div>
                        {
                          message_num.findIndex(error => error === "sheet_number") !== -1 ? 
                          <ul class="error-box">
                           <li>枚数は、1,000枚から20,000枚まで、100枚単位で指定してください</li>
                          </ul>
                         : null
                        
                        }
                        
                    </td>
                </tr>
                 : null }
            </table>
        </section>
    
        <section id="memo" className="s-contentsin2 section my-5 py-5">
            <h2 className="titlebox">
                <div className="titleboxin">備考欄</div>
            </h2>
            <div className="box">
                <p className="mb-p20">※配送元を変更の場合は、こちらにご記入ください</p>
                <textarea name="memo" className="textarea" onChange={changeHandler} value={item.memo} />
            </div>
        </section>
    
    
        <section className="s-contentsin2 section mb-5 pb-5">
            <h2 className="titlebox mb-p0">
                <div className="titleboxin">
                    利用規約
                    <span className="required">必須</span></div>
                <div className="question"><a href="/#"><img src="../images/common/question.png" alt="" /></a></div>
            </h2>
            <div className="box handling pt-4">
                <div className="agreement pt-5">
                    <div className="include">
                    <p>あさひ高速印刷株式会社（以下「当社」といいます）は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。</p>

                    <h2>個人情報の管理</h2>
                    <p>当社は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。</p>

                    <h2>個人情報の利用目的</h2>
                    <p>お客さまからお預かりした個人情報は、当社からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。</p>

                    <h2>個人情報の第三者への開示・提供の禁止</h2>
                    <p>当社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。</p>
                    <ul>
                    <li>お客さまの同意がある場合</li>
                    <li>お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合</li>
                    <li>法令に基づき開示することが必要である場合</li>
                    </ul>

                    <h2>個人情報の安全対策</h2>
                    <p>当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。</p>

                    <h2>ご本人の照会</h2>
                    <p>お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。</p>

                    <h2>法令、規範の遵守と見直し</h2>
                    <p>当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。</p>

                    <h2>お問い合せ</h2>
                    <p>当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。</p>
                    <address>あさひ高速印刷株式会社<br/>
                    〒550-0002<br/>
                    大阪市西区江戸堀2丁目1番地13<br/>
                    TEL：06-6448-7521
                    </address>
                    </div>
                </div>
                <div className="personal pb-5 pt-3">
                    <label>
                        <input type="checkbox" autocomplete="off" id="aggrement" name="aggrement" value={item.aggrement==="1" ? "0" : "1" }  onChange={changeHandler} />
                        <span className="chk-parts">利用規約に同意する</span>
                    </label>
                </div>
            </div>
            {
                          message_num.findIndex(error => error === "aggrement") !== -1 ? 
                          <ul class="error-box">
                           <li>利用規約に同意してください</li>
                          </ul>
                         : null
                        
                         
            }


        </section>

        { item.plan === RequestValue.PLAN_ATENA  || item.plan === RequestValue.PLAN_ATENA_NAKA  ? 
        <section id="csvUpload" className="s-contentsin2 section pb-5 my-5 ">
                
                <h2 className="titlebox">
                    <div className="titleboxin">
                      CSVデータをアップロード
                      <span className="required">必須</span>
                    </div>
                    <div className="question">
                        <a href="/#"><img src="../images/common/question.png" alt="" /></a>
                    </div>
                </h2>
            
                  
                
                

                {csv_number ? 
                <div className="csv-numbox">
                  <p>CSVから以下の件数を確認しました</p>
                  <div className="num">
                    {csv_number ? csv_number.toLocaleString() : "-"}<span>件</span>
                  </div>
                </div>
                : null }

                {message ? 
                  <ul className="errorbox">
                    {  message }
                  </ul>
                : null }
                

                <div id="fileUp1" className="box input-button-up py-5 mb-5">
                    <div className="buttonbox">
                        <span onClick={() => document.getElementById('csv').click()}>
                            <input type="file" name="csv" id="csv" accept='.csv' onChange={changeCsvFileHandler}/>
                            <label for='csvup'>CSVデータをアップロード</label>
                        </span>
                    </div>
                </div>

                {
                          message_num.findIndex(error => error === "csv") !== -1 ? 
                          <ul class="error-box">
                           <li>CSVファイルをアップロードしてください</li>
                          </ul>
                         : null
                        
                         
            }
               
               
          </section>
          : null}

        <footer id="footer-step">
            <div className="s-contentsin">
                <div className="l">
                    <p>必須項目の記載、選択を行うと見積金額<sup>※</sup>が表示され、<br />
                    アップロードした可能データお進みいただけます</p>
                    <p className="min">※送料は含まれておりません。送料は最終確認で計算されます。</p>
                </div>
                <div className="r">
                    <div className="totalprice">
                        <span className="total">合計金額<sup>※</sup></span>
                        <span id="priceLabel" className="price">{price ? Math.round(price).toLocaleString() : "-" }</span>
                       
                    </div>
                    
                    <div onClick={nextStep} id="confirmOne" className="button next">
                      <span>次のステップへ</span>
                    </div>

                </div>
            </div>
        </footer>


        </div>

  )
}

export default OrderForm
