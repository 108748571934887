import React, { useState } from 'react'

function SendingNewCsvModalContent(props) {


  const [item, setItem] = useState(null)

  const changeCsvFileHandler = (e) => {
    const target = e.target
    if(target.files && target.files.length !== 0){
     
      setItem(target.files[0])
    }
  }

  const uploadCsvHandler = () => {
  
    if(item) {
      props.onUploaded(props.item.id, item);
    }
  }

  return (
    <div id="openwindowfloat">
      <div id="contentsbox" className="contentsin">
        <div id="closebox" onClick={() => props.onClose()}>
          <p>閉じる</p>
          <div id="window-toggle">
            <div>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>

        <div className="topcomment">
				  <h1>再入稿で使用する新しいCSVを選択</h1>
        </div>

        <div className="contents">
          <dl>
            <dt></dt>
            <dd>
              <input type="file" name="csv" accept=".csv" onChange={changeCsvFileHandler} required />
            </dd>
          </dl>
        </div>
        <div className="button-area">
          <div className="buttonbox">
            <button
              type="button"
              className="btn btn-secondary fs-7 px-44 py-11 ms-22"
              onClick={uploadCsvHandler}
              disabled={item === null}
            >
              再入稿へ進む
            </button>
          </div>
        </div>
      </div>
      <div id="window-bg" onClick={() => props.onClose()}></div>
    </div>
  )
}

export default SendingNewCsvModalContent
