import React, {  useEffect , useState } from 'react'
import { useParams } from 'react-router-dom';

import useCartState from '../cart/useCartState'
import useAuthState from '../../auth/useAuthState'
import PdfEditor from './PdfEditor'
import EditorTopMenu from './pdf_me/EditorTopMenu'
import useSampleState from './pdf_me/useSampleState'
import useTemplateState from '../template/useTemplateState';
import RequestValue from '../RequestValue';
import GetCsvRowItem from './GetCsvRowItem';

function EditElementApp(props:PropsValue) {
  var _is_loading = false

  const {type} = useParams()
  const [isEditing, setIsEditing] = useState(true)
  const [currentPageId, setCurrentPageId] = useState(null)
  const [template, setTemplate] = useState(null)
  const [move_page, setMovePage] = useState(null)
  const {token, auth} = useAuthState()
  const {cart, current_cart_id, getCart, getCurrentCartId} = useCartState()
  const {current_page, setPage} = useSampleState()
  const {createTemplate, updatedTemplate} = useTemplateState()

  useEffect(() => {
    auth()
    getCurrentCartId()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    getCurrentCart()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[current_cart_id]);

  useEffect(() => {
    getCurrentCart()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);

  useEffect(() => {

  },[cart ]);

  useEffect(() => {

    if(updatedTemplate){
      moveTo(move_page)
      //moveTo()
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[updatedTemplate ]);


  useEffect(() => {

      setCurrentPageId(current_page)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[current_page]);

  const getCurrentCart = () =>{
    if(token && current_cart_id && !cart && !_is_loading) {
      _is_loading = true
      getCart(token, current_cart_id)
    }
  }

  const setCurrentPage = (page) => {

    if(Number(page)){
      setPage(Number(page))
    }else if(page === null ){
      setPage(null)
    }
  }

  const moveTo = (step) => {
    if(step)
      window.location.href = "/order/" + step
  }

  const saveTemplate = (page) => {
    if(template){
      console.log(template.sampledata[0])
      if(JSON.stringify(template.sampledata[0]) === "{}"){
        //template.sampledata[0] = {"d":"d"}
      }
      setMovePage(page ? page : Number(type) === 1 ? "step4/2" : "step3")
      const sche = template.schemas ? template.schemas[0] : null
      const new_data = GetCsvRowItem.getLongestItem(cart.csv)
      const sample = sche ? GetCsvRowItem.getSampleData(new_data,sche) : {}

      
      createTemplate(token, cart.id, template, sample ? sample : null, Number(type) === 1 ? "front" : "back")
    }
  }

  const updateDesigner= (data,  sample) => {
    setTemplate(data)
  
  }

  const editModeChangeHandler = () => {
    setIsEditing(isEditing ? false : true)
  }

  const moveToFronPage = () => {
    setMovePage("step4/1")  
    
    createTemplate(token, cart.id, template, template.sampledata ? template.sampledata[0] : null,  Number(type) === 1 ? "front" : "back")

  }

  const moveToBackPage = () => {
    setMovePage("step4/2")
    createTemplate(token, cart.id, template, template.sampledata ? template.sampledata[0] : null,   Number(type) === 1 ? "front" : "back")
  
  }

  const getTitleImgLink = () => {
    if(!cart ) return null
    if(cart.specification === RequestValue.SPECIFICATION_V){
      if(Number(type) === 1 ){
        return "/images/step/step4-v-atena.png"
      }else{
         return "/images/step/step4-v-naka.png"
      }
    }else{
      if(Number(type) === 1 ){
        return "/images/step/step4-z-atena.png"
      }else{
         return "/images/step/step4-z-naka.png"
      }
    }
  }

  const getPageName = () => {
    if(currentPageId){
      return currentPageId + "ページ目を表示中"
    }
    return "サンプルモード(各項目の一番長い文字列を配置)"
  }

  return(

        <div id="step" className="step step4"> 
          
          <article id="contents">   
            {
              cart && cart.plan !== RequestValue.PLAN_NO_ATENA ?
              <section className="contents" id="control-panel">
  
                <EditorTopMenu is_frontpage={Number(type) === 1} is_backpage={Number(type) === 2} is_editing={isEditing} current_page={currentPageId} setCurrentPage={setCurrentPage} cart={cart} editModeChangeHandler={editModeChangeHandler} moveToFronPage={moveToFronPage} moveToBackPage={moveToBackPage}/>
              </section>
             : null
            }
          

            <section className="contents" id="surface">
              <section className="s-contentsin topcomment">
                  <div className="title-box">
                  <h1>レイアウトを<br />調整してください</h1>
                  </div>
                  <div class="commentbox">
                    <ul>
                      <li>各ボックスは一番長い文字列にあわせサイズ変更できるようにしております。</li>
                      <li>サンプルモードは、各項目の一番長い文字列を配置したモードです。</li>
                      <li>宛名面、中面の確認が完了しまたら「レイアウト調整を完了」ボタンで「レイアウト編集」ページに戻り、<br />「レイアウトイメージ確認」へお進みください。</li>
                    </ul>
                  </div>
              </section>
            </section>  
            <section class="contents" id="editbox"> 
              <section class="editboxin" id="table">
                <div class="surface-titlebox">
                  <h2 class="surface-title"><img src={getTitleImgLink()}alt="" /></h2>
                </div>
                {
                  cart ? <PdfEditor  is_editing={true} current_page={currentPageId} token={token} cart={cart} type={type}  setDesigner={updateDesigner }/>
                  : null
                }
                

                <div className="pagename">
                  <span>ページ名</span>
                  <span>{getPageName()}</span>
                </div>
                
              </section>
            </section>  

          </article>

          <footer id="footer-step" style={{zIndex: "9999"}}>
            <div className="s-contentsin">
                <div className="l">
                    <div className="button forward" onClick={() => saveTemplate("step3")}>
                        <span>
                           レイアウト編集へ戻る
                        </span>
                    </div>
                    <p>ステップを進めるには、<br/><strong>必ず中面も確認</strong>してください。</p>
                </div>

                <div className="r">
                    <div className={template ? "button next " : "button next off"}>
                      {type === "1" ?
                         <div id="nextPage" onClick={() => saveTemplate()}>
                          <span>
                            中面を確認する
                          </span>
                        </div>
                      :
                      <div id="nextPage" onClick={() => saveTemplate()}>
                        <span>
                            レイアウト調整を終了
                        </span>
                      </div>
                      }
                       
                    </div>
                </div>
            </div>
          </footer>
         
        </div>
  

  )
}

export default EditElementApp
