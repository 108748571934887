import React, {  useEffect, useState } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import useAuthState from '../auth/useAuthState'
import InqueriProps from './InqueriProps'


import usePostcodeState from '../postcode/usePostcodeState'
import InqueriForm from './InqueriForm'
import useInqueriState from './useInqueriState'
function InqueryApp(props:PropsValue) {
  
  const {token, auth} = useAuthState()
  const [errors, setErrors] = useState(null)
  const [item, setItem] = useState(new InqueriProps())


  const {addresses, getAddressInfo} = usePostcodeState()
  const {inqueri, loadFromCookie, save} = useInqueriState()
  useEffect(() => {
    auth()
    loadFromCookie()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
   if(inqueri){
    const new_item = new InqueriProps(inqueri)
    setItem(new_item)
   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inqueri]);

  useEffect(() => {

    if(addresses && addresses.length > 0){
      const address = addresses[0]
      const _item = item.set("prefecture_id",  address.prefcode)
      setItem(_item.set("address1", address.address2 + address.address3))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[addresses]);


  const changeHandler =(e) => {
    const target = e.target 
    const new_item = item.set(target.name, target.value)
    setItem(new_item)

    if(target.name === "post1" || target.name === "post2"){
      var code = "";
      if(target.name === "post1" && item.post2){
        code = target.value + item.post2
      }
      else if(target.name === "post2" && item.post1){
        code = item.post1 + target.value 
      }

       if(code.length === 7){
        getAddressInfo(code)
      } 
        
    }

  }


  const btnClickHandler = () => {
 
    const _errors = []
    setErrors([])

    if(!item.name || item.name === ""){
      _errors.push("name.required")
    }else if(item.name.length > 25){
      _errors.push("name.max")
    }

    if(!item.name_kana || item.name_kana === ""){
      _errors.push("name_kana.required")
    }else if(!item.name_kana.length > 25){
      _errors.push("name_kana.max")
    }else if(item.name_kana.match(/[^ァ-ヴー]+/)){
      _errors.push("name_kana.regex")
    }


    if(!item.email){
      _errors.push("email.required")
    }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(item.email)) {
      _errors.push("email.email:filter")
    }

  
    if(!item.memo || item.memo === ""){
      _errors.push("memo.required")
    }

 
    if(_errors.length > 0){
      //ErrorMessage
      setErrors(_errors)
    }else{
      setErrors(null)
      save(item)
      window.location.href= "/inquiry/confirm"
    }

  }


  
  return(

        <div id="page" className="page contact"> 
          <Header token={token} noAuth={true} />
          <Menu />
          <article id="contents">
          <section className="s-contentsin2 topcomment">
            <h1 className="stitle">お問い合わせ</h1>
            <p>お問い合わせを受付けております。必須項目にご記入の上、お問い合わせください。<br/>
            会員登録されている方は、会員IDもあわせご入力ください。</p>
          </section> 
          
         
 

          <section className="s-contentsin2 form-box">
            <div className="box">
             
              <InqueriForm changeHandler={changeHandler} item={item} message={errors} />

             

              <div className="buttonbox">
                <div className="button or">
                  <div onClick={btnClickHandler}>登録内容を確認</div>
                </div>
              </div>
            </div>
          </section>



          </article>
          <Footer />
        </div>
  

  )
}

export default InqueryApp
