import React, { useState, useEffect } from 'react'
import Message from '../message/Message'
import usePrefectreState from '../prefectre/usePrefectreState'
import Profile from '../profile/Profile'
import usePostcodeState from '../postcode/usePostcodeState'

/**
 * 
 * @param {*} props 
 * @props  profile
 * @props confirm()
 *
 * @returns 
 */

function DeliveryDetail(props:PropsValue) {

  const [item, setItem] = useState(props.item ?  new Profile(props.item ) : new Profile())
  const [is_open, setIsOpen] = useState(false)
  const [message, setMessage] = useState(null)
  const [options, setOptions] = useState([])
  const [updated, setUpdated] = useState(null)
  const {prefectures,getPrefectures} = usePrefectreState()
  const {addresses, getAddressInfo} = usePostcodeState()

  useEffect(() => {
    getPrefectures()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  useEffect(() => {

    setItem(new Profile(props.item ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.item]);



  useEffect(() => {
   if(props.created_delivery_id)
      setItem(new Profile())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.created_delivery_id]);


  useEffect(() => {
    if(prefectures){
      const _ops = prefectures.map(pref => {
        return (
          <option value={pref.id} selected={item && item.prefecture_id === pref.id ? "selected": ""}>{pref.prefecture_name}</option>
        )
      })
      const __ops = [<option value={0}>選択してください</option>].concat(_ops)
      setOptions(__ops)

    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[prefectures]);


  useEffect(() => {
    
    if(addresses && addresses.length > 0){
      const address = addresses[0]
      const _item = item.set("prefecture_id",  address.prefcode)
      setItem(_item.set("address", address.address2 + address.address3))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[addresses]);


  const changeHandler = (e) => {
    const target = e.target
    setItem(item.set(target.name, target.value))

    if(target.name === "post1" || target.name === "post2"){
      var code = "";
      if(target.name === "post1" &&  item.post2){
        code = target.value + item.post2
      }
      else if(target.name === "post2" && item.post1){
        code = item.post1 + target.value 
      }

       if(code.length === 7){
        getAddressInfo(code)
      } 
        
    }
  } 

  const submit = () => {
    //TODO validation
    const _errors = []


    if(!item.name || item.name === ""){
      _errors.push("name.required")
    }else if(item.name.length > 25){
      _errors.push("name.max")
    }

    if(!item.name_kana || item.name_kana === ""){
      _errors.push("name_kana.required")
    }else if(!item.name_kana.length > 25){
      _errors.push("name_kana.max")
    }else if(item.name_kana.match(/[^ァ-ヴー]+/)){
      _errors.push("name_kana.regex")
    }

    if(!item.post1 || item.post1 === "" || !item.post2 || item.post2 === ""){
      _errors.push("zip.required")
    }else if(item.post1.length < 1 || item.post1.length > 3 ){
      _errors.push("zip.regex")
    }else if(item.post2.length < 1 || item.post2.length > 6 ){
      _errors.push("zip.regex")
    }else if(item.post1.match(/[^0-9]+/)){
      _errors.push("zip.regex")
    }else if(item.post2.match(/[^0-9]+/)){
      _errors.push("zip.regex")
    }

    if(!item.prefecture_id || item.prefecture_id === "" || item.prefecture_id === "0"){
      _errors.push("prefecture_id")
    }

    if(!item.address || item.address === ""){
      _errors.push("address")
    }else if(item.address.length > 255 ){
      _errors.push("address.max")
    } 


    if(!item.tel || item.tel === ""){
      _errors.push("tel.required")
    }else if(item.tel.match(/[^0-9]+/)){
      _errors.push("tel.regex")
    }else if(item.tel.length > 11){
      _errors.push("tel.max") 
    }else if(item.tel.length < 10){
      _errors.push("tel.min") 
    }

 
    if(_errors.length > 0){
      //ErrorMessage
      setMessage(_errors)
    }else{
      setMessage(null)
      props.submit(item)
      
      if(!item.id){ 
        setItem( new Profile())
      }else{
        setUpdated("編集が完了しました")
      }

      

    }
    
  }



  return(
   
 
    <div className="box">
         <div class="boxin">
            <table  className="form-table address-table">
              {props.caption ? <caption>{props.caption }</caption>: null}
                      
                          <tr >
                              <th>
                                  <span className="thname">会社名</span>
                              </th>
                              <td>
                                  <div className="textbox">
                                      <div>
                                          <input type="text" name="company_name" id="comname" className="inputbox02" placeholder="" value={item && item.company_name ? item.company_name : undefined} onChange={changeHandler}/>
                                      </div>
                                  </div>
                              </td>
                          </tr>
          
                          <tr className={Message.hasMessge(message, "name") ? "err" : null}>
                              <th>
                                  <span className="thname">お名前</span><span className="required">必須</span>
                              </th>
                              <td>
                                  <div className="textbox">
                                      <div>
                                          <input type="text" name="name" id="name" className="inputbox02" placeholder="" value={item ? item.name : undefined} onChange={changeHandler}/>
                                      </div>
                                  </div>
                                  <ul class="error-box">
                                   {message ? Message.getMessgeFromList(message,"name") : null}
                                  </ul>
                                  
                              </td>
                          </tr>
                          { is_open || (item && !item.id )? 
                     
                          <tr className={Message.hasMessge(message, "name_kana") ? "err" : null}>
                              <th>
                                  <span className="thname">お名前（フリガナ）</span><span className="required">必須</span>
                              </th>
                              <td>
                                  <div className="textbox">
                                      <div>
                                          <input type="text" name="name_kana" id="name" className="inputbox02" placeholder="" value={item ? item.name_kana : undefined} onChange={changeHandler}  required />
                                      </div>
                                  </div>
                                  <ul class="error-box">
                                   {message ? Message.getMessgeFromList(message,"name_kana") : null}
                                  </ul>
                              </td>
                          </tr>
                           :null}
                           { is_open || (item && !item.id )? 
                          <tr className={Message.hasMessge(message, "zip") ? "err" : null}>
                              <th>
                                  <span className="thname">郵便番号</span><span className="required">必須</span>
                              </th>
                              <td>
                                  <div className="textbox">
                                      <div>
                                          <input type="text" name="post1" id="post1" className="inputbox03" placeholder="" maxlength="3" value={item ? item.post1 : undefined} onChange={changeHandler}/> - 
                                          <input type="text" name="post2" id="post2" className="inputbox04" placeholder="" maxlength="6" value={item ? item.post2 : undefined} onChange={changeHandler}/>
                                      </div>
                                  </div>
                                  <ul class="error-box">
                                   {message ? Message.getMessgeFromList(message,"zip") : null}
                                  </ul>
                              </td>
                          </tr>
                            :null}
                           { is_open || (item && !item.id )? 
                          <tr className={Message.hasMessge(message, "prefecture_id") ? "err" : null}>
                              <th>
                                  <span className="thname">都道府県</span><span className="required">必須</span>
                              </th>
                              <td>
                                <select name="prefecture_id" id="prefecture_id" className="inputbox02" placeholder="" value={item ? item.prefecture_id : undefined} onChange={changeHandler}  >
                                  {
                                    options
                                  }
                                  </select>
                                   <ul class="error-box">
                                   {message ? Message.getMessgeFromList(message,"prefecture_id") : null}
                                  </ul>
                              </td>
                          </tr>
                              :null}
                           { is_open || (item && !item.id )? 
                          <tr className={Message.hasMessge(message, "address") ? "err" : null}>
                              <th>
                                  <span className="thname ls10">住所</span><span className="required">必須</span>
                              </th>
                              <td>
                                  <div className="textbox">
                                      <div>
                                          <input type="text" name="address" id="address1" className="inputbox02" placeholder="" value={item ? item.address : undefined} onChange={changeHandler} />
                                      </div>
                                  </div>
                                  <ul class="error-box">
                                   {message ? Message.getMessgeFromList(message,"address") : null}
                                  </ul>
                              </td>
                          </tr>
                            :null}
                           { is_open || (item && !item.id )? 
                          <tr className={Message.hasMessge(message, "address_add") ? "err" : null}>
                              <th>
                                  <span className="thname">マンション名など</span>
                              </th>
                              <td>
                                  <div className="textbox">
                                      <div>
                                          <input type="text" name="address_add" id="address2" className="inputbox02" placeholder="" value={item ? item.address_add : undefined} onChange={changeHandler}  />
                                      </div>
                                  </div>
                              </td>
                          </tr>
                            :null}
                           { is_open || (item && !item.id )? 
                          <tr className={Message.hasMessge(message, "tel") ? "err" : null}>
                              <th>
                                  <span className="thname ls10">電話番号</span><span className="required">必須</span>
                              </th>
                              <td>
                                  <div className="textbox">
                                      <div>
                                          <input type="text" name="tel" id="tel" className="inputbox02" placeholder="" value={item ? item.tel : undefined}  onChange={changeHandler}  />
                                      </div>
                                  </div>
                                  <ul class="error-box">
                                   {message ? Message.getMessgeFromList(message,"tel") : null}
                                  </ul>
                              </td>
                          </tr>
                       
                          :null}
                      </table>
                      {

                      item && item.id ? 
                      is_open ?
                      <div class="buttonbox pt1">
                        {
                        updated ? <div className="comp">{updated}</div>: null
                        }
                        <div className="button or">
                          <div onClick={submit}>編集する</div>
                        </div>
                        <div className="button rd" onClick={() => props.deleteItem(item)}>
                          <div>削除する</div>
                        </div>
                      </div>
                      :null :
                      <div className="buttonbox">
                          <div className="button or">
                              <div onClick={submit} >{props.submit_text ? props.submit_text :"登録"}</div>
                          </div>
                      </div>
                      
          
                      }
                     



             </div>
             {item && item.id ? 
             <div className="accordion-box" onClick={() => setIsOpen(!is_open)}>
                <label className={is_open ? "check-label open" :"check-label" }></label>
              </div>
              :null}
          </div>
      
 
  )
}

export default DeliveryDetail
   
