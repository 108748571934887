import React, { useState, useEffect} from 'react'
import Header from '../../header/Header'
import Menu from '../../menu/Menu'
import Footer from '../../footer/Footer'
import usePasswordState from './usePasswordState'
import step_image from "../../images/member/step1.png"



function PasswordResetCodeApp(props:PropsValue) {

  const [mail, setMail] = useState(null)
  const [message, setMessage ] = useState(null)
  const {passwordError,passwordGetStatus, mailSubmit} = usePasswordState()

  
  useEffect(() => {
    if(passwordGetStatus)
      window.location.href = "/password/reset/sent"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[passwordGetStatus]);



  useEffect(() => {
    if(passwordError)
      setMessage(<li>ご入力のID（メールアドレス）に間違いがあります</li>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[passwordError]);

  const changeMailHandler = (e) => {
    const target = e.target 
    setMail(target.value)
  }
  const submit = function() {
    setMessage(null)
    mailSubmit( mail)
  }
  return(
      <div id="page" className="member login password resetting"> 
        <Header />
        <Menu />
        <article id="contents">
        <section className="s-contentsin2 topcomment1">
          <div className="step-box">
              <img src={step_image} alt="" />
            </div>
          <h1 className="stitle3">パスワード再設定</h1>
          <p>パスワードを再設定するためのアドレスをIDとして登録されたメールアドレスにお送りします。<br/>
              メールの内容に沿ってアドレスをクリックし、パスワード再設定ページへお進みください。</p>
        </section>
        {message ? 
       
            <ul className="errorbox" role="alert">
                {message}
            </ul>
   
        : null}
        <section class="s-contentsin2 delivery-list">
          <div className="box">
            <table className="form-table address-table">
                    <tr>
                        <th>
                            <span className="thname">ID（メールアドレス）</span>
                        </th>
                        <td>
                            <div className="textbox">
                                <div>
                                    <input id="member-id" type="email" className="inputbox02" name="email" value={mail} required autocomplete="email" autofocus onChange={changeMailHandler} />
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>

                <div className="input-button reset ">
                    <input type='button' name='reset' value='再設定アドレスを送信' id='reset' />
                    <label for='reset' onClick={submit}>再設定アドレスを送信</label>
                </div>

               
		      </div>
        </section>
           
        </article>
        <Footer />
      </div>


  )
}

export default PasswordResetCodeApp

