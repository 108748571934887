
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'

const RegisterAction = {

  saveOnCookie(item){
    cookie.save('tmp_account', item, { path: '/' })
  },

  loadFromCookie(callback){
    var item =  cookie.load("tmp_account")
    callback(item)
  },

  removeFromCookie(){
    cookie.remove("tmp_account",{ path: '/' })
  },

  create( item, handler, errorHandler){
  
    const formData = new FormData();
    formData.append('email', item.email);
    formData.append('password', item.password);


    fetch(config.get("API_PATH") + "/api/register"  , {
      method: "POST",
      headers: {
       //'Authorization': 'Bearer ' + token,
       //'Content-Type': 'application/json'
      },
      body: formData
    }).then(function(response) {
      
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        if(data.status === "0"){
          cookie.save('tmp_account_error', data, { path: '/' })
          errorHandler(data)
        }else{
          handler(item)
          
        }
      }else{
        cookie.save('tmp_account_error', data, { path: '/' })
        errorHandler(data)
      }
    })
  },

  removeErrors(){
    cookie.remove("tmp_account_error",{ path: '/' })
  },

  loadErrors(callback){
    var item =  cookie.load("tmp_account_error")
    console.log(item)
    callback(item)
  },

 
}

export default RegisterAction
