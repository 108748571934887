import { useState } from 'react';

import OrderAction from './OrderAction';


function useOrderState() {
  const [error, setError] = useState(null)
  const [order, setOrder] = useState(null)
  const [created_at, setCreatedAt] = useState(null)
  const [pdf_created_at, setPdfCreatedAt] = useState(null)
  //const [pdf_created_at, setPdfCreatedAt] = useState(null)

  const orderChangeHandler = (data) => {
    setOrder(data.data)
    setCreatedAt(new Date())
  }

  const orderChangePdfHandler = (data) => {
    setPdfCreatedAt(new Date())
  }

  const errorHandler = (data) => {
    setError(new Date())
  }

  const createPdf = (token, id) => {
    OrderAction.createPdf(token, id, orderChangePdfHandler, errorHandler)
  } 

  const create = (token, id) => {
    OrderAction.create(token, id, orderChangeHandler, errorHandler)
  } 

  return { order:order, error, createPdf:createPdf, order_created_at:created_at, pdf_created_at, createOrder:create};
}

export default useOrderState