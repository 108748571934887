
import RequestValue from "../../RequestValue"
import PdfLayoutDefaultInfo from "./layoutinfo/PdfLayoutDefaultInfo"

const Schema =  {
    
    getItems(item){
      const list = []
      list.push(item.氏名)
      list.push(item.郵便番号1)
      list.push(item.郵便番号2)
      list.push(item.住所)
      list.push(item.会社名)
      if(item.可変項目1) list.push(item.可変項目1)
      if(item.可変項目2) list.push(item.可変項目2)
      if(item.可変項目3) list.push(item.可変項目3)
      return list
    },

    findeMoviedItem(current_schema, new_scema ){
 
      if(current_schema.氏名.position.x !== new_scema.氏名.position.x 
        || current_schema.氏名.position.y !== new_scema.氏名.position.y) return {氏名:new_scema.氏名} 

      if(current_schema.郵便番号1.position.x !== new_scema.郵便番号1.position.x 
        || current_schema.郵便番号1.position.y !== new_scema.郵便番号1.position.y) return {郵便番号1:new_scema.郵便番号1}
      
        if(current_schema.郵便番号2.position.x !== new_scema.郵便番号2.position.x 
        || current_schema.郵便番号2.position.y !== new_scema.郵便番号2.position.y) return {郵便番号2:new_scema.郵便番号2}
      
      if(current_schema.住所.position.x !== new_scema.住所.position.x 
        || current_schema.住所.position.y !== new_scema.住所.position.y) return {住所:new_scema.住所}
      
      if(current_schema.会社名.position.x !== new_scema.会社名.position.x 
         || current_schema.会社名.position.y !== new_scema.会社名.position.y) return {会社名:new_scema.会社名}
   
      if(current_schema.可変項目1 && (current_schema.可変項目1.position.x !== new_scema.可変項目1.position.x 
        || current_schema.可変項目1.position.y !== new_scema.可変項目1.position.y)) return{可変項目1:new_scema.可変項目1}
      
      if(current_schema.可変項目2 && (current_schema.可変項目2.position.x !== new_scema.可変項目2.position.x 
        || current_schema.可変項目2.position.y !== new_scema.可変項目2.position.y)) return{可変項目2:new_scema.可変項目2}
      
      if(current_schema.可変項目3 && (current_schema.可変項目3.position.x !== new_scema.可変項目3.position.x 
        || current_schema.可変項目3.position.y !== new_scema.可変項目3.position.y)) return {可変項目3:new_scema.可変項目3}
      
      return null
    },
    
    getDefault(cart, font_size, item, type){

      if(!item ) return {nocontent:true}
 
      if(Number(type) === 2  ||  type === "back") return {}

      const layout = cart.specification === RequestValue.SPECIFICATION_V ? PdfLayoutDefaultInfo.SPEC_V :PdfLayoutDefaultInfo.SPEC_Z
      //var current_y = layout.Y0
      const _w_name = Schema.getTextWidth(cart,1,item.name ? item.name.value : null)
      //const _h_name = Schema.getTextHeight(cart,1,item.name ? item.name.value : null)
      const obj_name = {
        type: 'text',
        fontSize: parseInt(font_size),
        //fontName: 'ipag',
        position: { x: layout.X0, y: layout.Y0 },
        width: _w_name,
        height: layout.H1,
        name:"name"
      }
      //current_y += (_h_name + PdfLayoutDefaultInfo.ITEM_MARGIN)
      
      const obj_postcode_1 = {
        type: 'text',
        //fontName: 'ipag',
        fontSize: parseInt(layout.POST_CODE_FONT),
        position: { x: layout.X1, y: layout.Y1},
        width: layout.W4,
        characterSpacing:layout.POST_CODE_LETTER_SPACE,
        height: 7,
        name:"postcode1"
      }

      const obj_postcode_2 = {
        type: 'text',
        //fontName: 'ipag',
        fontSize: parseInt(layout.POST_CODE_FONT),
        position: { x: layout.X3, y: layout.Y1},
        width: layout.W5,
        characterSpacing:layout.POST_CODE_LETTER_SPACE,
        height: 7,
        name:"postcode2"
      }
      //current_y += (PdfLayoutDefaultInfo.DEFAULT_HEIGHT + PdfLayoutDefaultInfo.ITEM_MARGIN)
      

      //const _h_address = Schema.getTextHeight(cart,1,item.address ? item.address.value : null)
      const obj_address = {
        type: 'text',
       // fontName: 'ipag',
        fontSize: parseInt(font_size),
        position: { x: layout.X0, y: layout.Y2},
        width:layout.W2, 
        height: layout.H3,
        name:"address"
      }
      //current_y += (_h_address + PdfLayoutDefaultInfo.ITEM_MARGIN)


      //const _w_companuy = Schema.getTextWidth(cart,1,item.company_name ? item.company_name.value : null)
      //const _h_companuy = Schema.getTextHeight(cart,1,item.company_name ? item.company_name.value : null)

      const obj_company ={
        type: 'text',
       // fontName: 'ipag',
        fontSize: parseInt(font_size),
        position: { x: layout.X0,y: layout.Y3},
        width: layout.W3 ,
        height: layout.H2,
        name:"company"
      }
        
      return {
        氏名: obj_name,
        郵便番号1: obj_postcode_1,
        郵便番号2: obj_postcode_2,
        住所: obj_address,
        会社名: obj_company
      }
  },

  getWithAdditionalData(cart, type, font_size, item){
 
    var default_data = Schema.getDefault(cart, font_size, item, type)
  
    if(!item){
      return {}
    }
    

    //const layout = item.specification === RequestValue.SPECIFICATION_V ? PdfLayoutDefaultInfo.SPEC_V :PdfLayoutDefaultInfo.SPEC_Z
   // var current_y_1  = layout.Y4
  //  var current_y_2  = layout.Y5
  //  var current_y_3  = layout.Y6 


    const in_1 = Schema.isInPage(cart,1,type) 

    if( in_1 >= 0 && item.additional_1.value !== ""){
     // const _y = in_1 === 0 ? current_y_1 :  in_1 === 1 ?  current_y_2 : current_y_3
      
      default_data.可変項目1 = {
        type: Schema.getType(cart,1),
        fontSize: parseInt(font_size),
        position: { x: Schema.getXPosition(type, cart ,1), y:  Schema.getYPosition(Number(type), cart , 1)},
        width: (item.additional_1 ? Schema.getWidth(cart, 1, item.additional_1.value) : 50),
        height: Schema.getHeight(cart, 1),
        name:"additional_1"
      } 
      // Number(type) === 1 ? layout.Y4 : layout.Y1
     // const _h = (Schema.getHeight(cart, 1) + PdfLayoutDefaultInfo.ITEM_MARGIN)
     // if(in_1 === 0) current_y_1 += _h
      //if(in_1 === 1) current_y_2 += _h
      //if(in_1 === 2) current_y_3 += _h
      
    }
    const in_2 = Schema.isInPage(cart,2,type) 
    if(in_2 >= 0 && item.additional_2.value !== ""){
      //const _y = in_2 === 0 ? current_y_1 :  in_2 === 1 ?  current_y_2 : current_y_3
      default_data.可変項目2 = {
        type: Schema.getType(cart,2),
        fontSize: parseInt(font_size),
        position: { x: Schema.getXPosition(type, cart , 2), y:  Schema.getYPosition(Number(type), cart , 2)},
        width: (item.additional_1 ? Schema.getWidth(cart, 2, item.additional_1.value) : 50),
        height: Schema.getHeight(cart, 2),
        name:"additional_2"
        
      } 
     // const _h = (Schema.getHeight(cart, 2) + PdfLayoutDefaultInfo.ITEM_MARGIN)
      //if(in_2 === 0) current_y_1 += _h
      //if(in_2 === 1) current_y_2 += _h
      //if(in_2 === 2) current_y_3 += _h
    }
    const in_3 = Schema.isInPage(cart,3,type) 
    if(in_3 >= 0 && item.additional_3.value !== ""){
     
      //const _y = in_3 === 0 ? current_y_1 :  in_3 === 1 ?  current_y_2 : current_y_3
      default_data.可変項目3 = {
        type: Schema.getType(cart,3),
        fontSize: parseInt(font_size),
        position: { x: Schema.getXPosition(type, cart,3), y: Schema.getYPosition(Number(type), cart , 3)},
        width: (item.additional_1 ? Schema.getWidth(cart, 3, item.additional_1.value) : 50),
        height: Schema.getHeight(cart, 3),
        name:"additional_3"
      } 
    }



    return default_data
  },

  getTextWidth(cart, pageIndex, text){
    if(!text || text.length === 0) return 0;
    const layout_info = Schema.getLayoutInfo(cart)
    const page_w = pageIndex === 1  ? layout_info.W1  :  pageIndex === 2 ? layout_info.W2 : layout_info.W3 
    const _w = text.length * PdfLayoutDefaultInfo.DEFAULT_W_2 
    
    if(_w < page_w) return _w
    return  page_w
    
  },

  getTextHeight(cart, pageIndex, text, width){
    //return 20
    if(!text || text.length === 0) return 0;
    const layout_info = Schema.getLayoutInfo(cart)
    const page_w = pageIndex === 0  ? layout_info.W1  :  pageIndex === 1 ? layout_info.W2 : layout_info.W3 
    if(width < page_w ) return PdfLayoutDefaultInfo.DEFAULT_HEIGHT

    const _w = text.length * PdfLayoutDefaultInfo.DEFAULT_W_2 

    return Math.ceil(_w / page_w) * PdfLayoutDefaultInfo.DEFAULT_HEIGHT
  },

  getDefaultTextHeight(cart, item){
    const _h_name = Schema.getTextHeight(cart,1,item.name ? item.name.value : null)
    const _h_zip = PdfLayoutDefaultInfo.DEFAULT_HEIGHT
    const _h_address = Schema.getTextHeight(cart,1,item.address ? item.address.value : null)
    const _h_companuy = Schema.getTextHeight(cart,1,item.company_name ? item.company_name.value : null)
    return _h_name + _h_zip + _h_address +  _h_companuy + (PdfLayoutDefaultInfo.ITEM_MARGIN * 4)
  },

  isInPage(cart, num, type){

    if(cart.plan !== RequestValue.PLAN_ATENA_NAKA) return -1
    if(cart.additional_placements.length <  num) return -1

    const target = cart.additional_placements[num - 1]

    if(cart.specification === RequestValue.SPECIFICATION_V){
      if(Number(type) === 1 ) return PdfLayoutDefaultInfo.ADDTIONAL_POSITION_V_1.indexOf(target.placement) 
      if(Number(type) === 2 ) return PdfLayoutDefaultInfo.ADDTIONAL_POSITION_V_2.indexOf(target.placement)
    }

    if(cart.specification === RequestValue.SPECIFICATION_Z){
      if(Number(type) === 1 ) return PdfLayoutDefaultInfo.ADDTIONAL_POSITION_Z_1.indexOf(target.placement)
      if(Number(type) === 2 ) return PdfLayoutDefaultInfo.ADDTIONAL_POSITION_Z_2.indexOf(target.placement)
    }
    return -1
    
  },

  getXPosition(type, cart, index){
    //type==1:表 2:裏
    const layout_info = Schema.getLayoutInfo(cart)
    const qr_type = Schema.getType(cart,index)
    if(qr_type === "qrcode") {

      if(Number(type)=== 1) {
        return  layout_info.QR_X
      }
      else {
        return layout_info.QR_X_B
      }
    }
    if(!layout_info) return 0
    if(Number(type)=== 1) {
      return  layout_info.XR
    }
    else {
      return layout_info.XR0
    }

    
    
  },

  getYPosition(type, cart, index){
   
    const layout_info = Schema.getLayoutInfo(cart)
    const _type = Schema.getType(cart,index)
    if(_type === "qrcode") {
      if(Number(type)===1) {
        return layout_info.QR_Y
      }
      return layout_info.QR_Y_B
    }

    if(!layout_info) return 0
    if(type === 1) return  layout_info.Y1 + index * 10
    if(type === 2) return  layout_info.Y1 + index * 10
    return 0
  },
  


  

  getLayoutInfo( cart){
    if(cart.specification === RequestValue.SPECIFICATION_V)  return PdfLayoutDefaultInfo.SPEC_V
    if(cart.specification === RequestValue.SPECIFICATION_Z)  return PdfLayoutDefaultInfo.SPEC_Z
    return null
  },

  getType(cart, num) {
    const target = cart.additional_placements[num - 1]
    if(target && target.qr_code === 1) return "qrcode"
    return "text"
  },

  getHeight(cart, num) {
    const target = cart.additional_placements[num - 1]
    if(target && target.qr_code === 1) return 30
    return 5
  },

  getWidth(cart, num ,value){
    const target = cart.additional_placements[num - 1]
    if(target && target.qr_code === 1) return 30
    return value.length * 3.5
  }
}
export default Schema  