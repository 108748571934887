
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'

const SendingAction = {
  async get(token,  page, handler){
    const str = page ? "?offset=" + (page - 1) * 10 : ""

    fetch(config.get("API_PATH") + "/api/order/items" +str , {
      method: "GET",
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
      }
    }).then(function(response) {
      if(response.status === 200) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
        } else {
           return {"error":response.status}
        }
      } else if(response.status === 401) {
        return {"error":401}
      } else if(response.status === 403) {
        return  {"error":413}
      } else {
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        handler(data)
      }else{
       // errorHandler(data)
      }
    })
  },

  async duplicate(token, id,  handler){
    fetch(config.get("API_PATH") + "/api/cart/items/" + id + "/duplicate"  , {
      method: "POST",
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
      }
    }).then(function(response) {
      if(response.status === 200) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
        } else {
           return {"error":response.status}
        }
      } else if(response.status === 401) {
        return {"error":401}
      } else if(response.status === 403) {
        return  {"error":413}
      } else {
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        handler(data)
      }else{
       // errorHandler(data)
      }
    })
  },
  

  async duplicateWithCsv(token, id, item, handler){

    const formData = new FormData();
    formData.append('csv', item);


    fetch(config.get("API_PATH") + "/api/cart/items/" + id + "/duplicate_with_csv"  , {
      method: "POST",
      headers: {
       'Authorization': 'Bearer ' + token,
      },
      body: formData
    }).then(function(response) {
      if(response.status === 200) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
        } else {
           return {"error":response.status}
        }
      } else if(response.status === 401) {
        return {"error":401}
      } else if(response.status === 403) {
        return  {"error":413}
      } else {
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        handler(data)
      }else{
       // errorHandler(data)
      }
    })
  },

  delete(token, id, handler){
    if (!token || !id) {
      return;
    } 
    const url = config.get("API_PATH") + "/api/order/items/" + id
    fetch( url, {
      method: "DELETE",
      headers: {
       'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {
      if(response.status === 200 || response.status === 204) {
        return {"status":1}
      } else if(response.status === 401) {
        return {"error":401}
      } else if(response.status === 403) {
        return  {"error":413}
      } else {
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error && handler ){
        handler(token)
      }
    })
  },

  removeErrors(){
    cookie.remove("tmp_account_error",{ path: '/' })
  },

  loadErrors(callback){
    var item =  cookie.load("tmp_account_error")
    callback(item)
  },
}

export default SendingAction
