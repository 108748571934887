import React, { useState, useEffect } from 'react'


function Status(props:PropsValue) {

  const [item, setItem] = useState(null)


  useEffect(() => {
    setItem(props.profile)
  },[props.profile]);

  return(
    item ? 
    <div className="box">
			<table className="form-table address-table">
      <tr className="id-tb">
					<th>
						<span className="thname">ID（メールアドレス）</span>
					</th>
					<td>
						<div className="id-name">
							{item.email}
            </div>
            <div className="button or">
              <a href="/profile/email/reset"> メールアドレス再設定 </a>
            </div>
						
					</td>
				</tr>
				<tr>
					<th>
						<span className="thname">パスワード</span>
					</th>
					<td>
						<div className="textbox">
							<div className="button or">
                                <a href="/profile/password/reset">
                                パスワード再設定
                                </a>
								
							</div>
						</div>
					</td>
				</tr>
      
          
			</table>
      <p class="min atten">パスワードは、8文字以上の半角英数字で入力してください。<br />パスワード（確認用）にも入力をお願いします。</p>
		</div>
    : null
  )
}

export default Status
   
