import React, { } from 'react'
import BackgroundLoader from './BackgroundLoader';

function SendingShowBackgroundModalContent(props) {

  return (
    <div id="openwindowfloat">
      <div id="contentsbox" className="contentsin">
        <div id="closebox" onClick={() => props.onClose()}>
          <p>閉じる</p>
          <div id="window-toggle">
            <div>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>

        <div className="topcomment">
				  <h1>「{props.item.filename ? props.item.filename : props.item.data_name}」の背景データ</h1>
        </div>

        <div className="contents">
        {/* @foreach ($order['background_data'] as $path) */}
          <dl>
            <dd className="halfwidth">
              <BackgroundLoader type={1} item={props.item} token={props.token} />
            </dd>
            <dd className="halfwidth">
              <BackgroundLoader type={2} item={props.item} token={props.token} />
            </dd>
          </dl>
        {/* @endforeach */}
        </div>
      </div>
      <div id="window-bg" onClick={() => props.onClose()}></div>
    </div>
  )
}

export default SendingShowBackgroundModalContent
