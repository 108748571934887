import { useState } from 'react';

import RequestValue from '../RequestValue';
import PriceAction from '../PriceAction';


function useRequestState() {

  const [total_price, setTotalPrice] = useState(null)
  const [error, setError] = useState(null)
  const [delivery_price, setDeliveryPrice] = useState(null)
  const [total_number_in_csv, setTotalNumberInCsv] = useState(null)
  const [price_update_at, setPriceUpdate] = useState(null)
  const errorHandler = (data) => {
    setError(data.message ? data.message : data)
  }


  const priceChangeHandler = (data) => {
 
    setDeliveryPrice(data.data.delivery_price)

    setTotalPrice(data.data.price)
    setPriceUpdate(new Date())
  }

  const priceChangeWithCsvHandler = (data) => {
    setTotalPrice(data.data.price)
    setTotalNumberInCsv(data.data.number )
    setPriceUpdate(new Date())
  }
  
  const getTotalPrice = (token, item, delivery_pref_id = null, thickness = null) => {
    setError(null)
   if(item.sheet) {
    PriceAction.get(token, item.specification, item.sheet, delivery_pref_id, thickness, priceChangeHandler,errorHandler )
   } else  if(item.plan !== RequestValue.PLAN_NO_ATENA && item.csv) {
    PriceAction.getWithCsv(token, item, priceChangeWithCsvHandler,errorHandler )
   }  else{
    
   }
  } 


  return { total_price:total_price, delivery_price:delivery_price, price_error:error,price_update_at, getTotalPrice:getTotalPrice,setDeliveryPrice:setDeliveryPrice, total_number_in_csv:total_number_in_csv};
}

export default useRequestState