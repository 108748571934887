import { useState } from 'react';
import DeliveryAction from './DeliveryAction';


function useDeliveryState() {

  const [items, setItems] = useState(null)
  const [updated_at, setUpdatedAt] = useState(null)
  const [created_id, setCreatedId] = useState(null)


  const deletedHandler = (data) => {
    DeliveryAction.getList( data.token, loadedHandler, errordHandler)
  }

  const loadedHandler = (data) => {
    setItems(data.data)
    setUpdatedAt(new Date())
  }

  const createdHandler = (data) => {
    setCreatedId(data.data.id)
    getList(data.token)
  }
  

  const errordHandler = (data) => {
    console.log(data)
  }

  const getList = (token) => {    
    DeliveryAction.getList( token, loadedHandler, errordHandler)
  }

  const create = (token, item) => {    
    DeliveryAction.create( token, item, createdHandler, errordHandler)
  }

  const update = (token, item) => {    
    DeliveryAction.update( token, item, createdHandler, errordHandler)
  }

  const getItemById = (id) => {    
    if(!items) return null
   return items.find(item => item.id === id)
  }

  const deleteItem = (token, item) => {    
    DeliveryAction.delete( token, item.id, deletedHandler, errordHandler)
  }



  return { delivery_list:items,  created_delivery_id:created_id, delivery_list_updated_at:updated_at, deleteDerivery:deleteItem, getDeliveryItemById:getItemById, getDeliveryList:getList, createDelivery:create, update:update,updateDelivery:update};
}

export default useDeliveryState