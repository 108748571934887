
import 'whatwg-fetch'
import config from 'react-global-configuration';
import Action from '../../action/Action';

const PasswordAction = {

 

  reset( token, password, callback, errorHandler){
  
    const item = {
      password:password
    }
    const header =  {
      'Content-Type': 'application/json'
     }

    const path = config.get("API_PATH") + "/api/password/reset"
    Action.fetchAuthorizedJson(  path,token, "PATCH", header, JSON.stringify(item),  callback, errorHandler)
    
  },


  getCode( mail, callback, errorHandler){
    const path = config.get("API_PATH") + "/api/resetpassword/code"
    const data = {
      email:mail
    }
    const header =  {
      'Content-Type': 'application/json'
     }

    Action.fetchJson( path, "POST", header, JSON.stringify(data),  callback, errorHandler)
  
  },



 
}

export default PasswordAction
