import React, {  } from 'react'
import Header from '../../header/Header'
import Menu from '../../menu/Menu'
import Footer from '../../footer/Footer'

import step_image from "../../images/member/step1.png"

function PasswordResetApp(props:PropsValue) {




  return(

        <div id="page" className="password"> 
          <Header token={null} />
          <Menu />
          <article id="contents">
            
            <section className="s-contentsin2 topcomment1">

            <div className="step-box">
              <img src={step_image} alt="" />
            </div>

            <h1 class="stitle3">パスワード再設定用メールの送信を完了</h1>
          <p>パスワードを再設定するためのアドレスをメールでお送りいたしました。<br/>
          メールの内容に沿ってアドレスをクリックし、パスワード再設定ページへお進みください。</p>
      

            </section>
           
            </article>
          <Footer />
        </div>
  

  )
}

export default PasswordResetApp
