
import 'whatwg-fetch'
import config from 'react-global-configuration';

const MediaAction = {  

  getPdf(token, cart_id, type, handler, errorHandler){

    
    fetch(config.get("API_PATH") + "/api/cart/items/" + cart_id + "/medias/" + type , {
      method: "GET", 
      headers: {
       'Authorization': 'Bearer ' + token
       
      },

    }).then(function(response) {
      
      if(response.status === 200  ){
        return response.blob()
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        handler(data)
      }else{
        errorHandler(data)
      }
    })
  },


  getThumnail(token, cart_id, type, handler, errorHandler){

    
    fetch(config.get("API_PATH") + "/api/cart/items/" + cart_id + "/medias/thum/" + type , {
      method: "GET", 
      headers: {
       'Authorization': 'Bearer ' + token
       
      },

    }).then(function(response) {
      
      if(response.status === 200  ){
        return response.blob()
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        handler({data:data, type:type})
      }else{
        console.log(data)
        if(errorHandler)
          errorHandler(data)
      }
    })
  },

  getOrderThumnail(token, order_id, type, handler, errorHandler){
    fetch(config.get("API_PATH") + "/api/order/items/" + order_id + "/medias/thum/" + type , {
      method: "GET", 
      headers: {
       'Authorization': 'Bearer ' + token
      },

    }).then(function(response) {
      
      if(response.status === 200  ){
        return response.blob()
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        handler({data:data, type:type})
      }else{
       
        if(errorHandler)
          errorHandler(data)
      }
    })
  },


  create(token, cart_id, type, file, callback, errorHandler){

    const formData = new FormData();
    formData.append('pdf', file);
    formData.append('type', type);
    fetch(config.get("API_PATH") + "/api/cart/items/" + cart_id + "/medias" , {
      method:  "POST",
      headers: {
          'Authorization': 'Bearer ' + token
      },
      body: formData
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":403, "message":response.json()}
      }else if(response.status === 410){
        return  {"error":410, "message":"nopdf"}
      }else if(response.status === 411){
        return  {"error":410, "message":"sizeerror"}
      }else if(response.status === 500){
        return  {"error":410, "message":"error"}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        callback(data)
      }else{
        console.log(data)
        errorHandler(data)
      }
    })
  },

 
}

export default MediaAction
