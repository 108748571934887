import React, { useState, useEffect } from 'react'
import Message from '../message/Message'
import usePrefectreState from '../prefectre/usePrefectreState'
import usePostcodeState from '../postcode/usePostcodeState'
/**
 * 
 * @param {*} props 
 * @props  profile
 * @props confirm()
 *
 * @returns 
 */

function ProfileDetail(props:PropsValue) {

  const [item, setItem] = useState(null)

  const [message, setMessage] = useState(null)

  const {prefectures,getPrefectures,getPrefectureName} = usePrefectreState()
  const [options, setOptions] = useState([])
  const {addresses, getAddressInfo} = usePostcodeState()

  useEffect(() => {
    getPrefectures()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    const _ops = prefectures.map(pref => {
      return (
        <option value={pref.id} selected={item && item.prefecture_id === pref.id ? "selected": ""}>{pref.prefecture_name}</option>
      )
    })
    const __ops = [<option value={0}>選択してください</option>].concat(_ops)
    setOptions(__ops)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[prefectures]);


  useEffect(() => {
    
    if(addresses && addresses.length > 0){
      const address = addresses[0]
      const _item = item.set("prefecture_id",  address.prefcode)
      setItem(_item.set("address", address.address2 + address.address3))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[addresses]);
  

  useEffect(() => {
    setItem(props.profile)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.profile]);

  const changeHandler = (e) => {
    const target = e.target
    
    setItem(item.set(target.name, target.value))

    if(target.name === "post1" || target.name === "post2"){
      var code = "";
      if(target.name === "post1" &&  item.post2){
        code = target.value + item.post2
      }
      else if(target.name === "post2" && item.post1){
        code = item.post1 + target.value 
      }

       if(code.length === 7){
        getAddressInfo(code)
      } 
        
    }
  } 

  const submit = () => {
    //TODO validation
    const _errors = []


    if(!item.name || item.name === ""){
      _errors.push("name.required")
    }else if(item.name.length > 25){
      _errors.push("name.max")
    }

    if(!item.name_kana || item.name_kana === ""){
      _errors.push("name_kana.required")
    }else if(!item.name_kana.length > 25){
      _errors.push("name_kana.max")
    }else if(item.name_kana.match(/[^ァ-ヴー]+/)){
      _errors.push("name_kana.regex")
    }

    if(!item.post1 || item.post1 === "" || !item.post2 || item.post2 === ""){
      _errors.push("zip.required")
    }else if(item.post1.length < 1 || item.post1.length > 3 ){
      _errors.push("zip.regex")
    }else if(item.post2.length < 1 || item.post2.length > 6 ){
      _errors.push("zip.regex")
    }

    if(!item.prefecture_id || item.prefecture_id === "" || item.prefecture_id === "0"){
      _errors.push("prefecture_id.required")
    }

    if(!item.address || item.address === ""){
      _errors.push("address")
    }else if(item.address.length > 255 ){
      _errors.push("address.max")
    } 

  
    
    if(!item.tel || item.tel === ""){
      _errors.push("tel.required")
    }else if(item.tel.match(/[^0-9]+/)){
      _errors.push("tel.regex")
    }else if(item.tel.length > 11){
      _errors.push("tel.max") 
    }else if(item.tel.length < 10){
      _errors.push("tel.min") 
    }


 
    if(_errors.length > 0){
      //ErrorMessage
      setMessage(_errors )
    }else{
      setMessage(null)
      props.confirm(item)
    }
    
  }

  return(
    item ? 
    <div className="box">
          <form id="myPageEdit" >
          
            <table className="form-table address-table">
              <caption>基本情報</caption>
                      
                          <tr className={Message.hasMessge(message, "company_name") ? "err" : null} >
                              <th>
                                  <span className="thname">会社名</span>
                              </th>
                              <td>
                                  <div className="textbox">
                                      <div>
                                        {props.is_confirm ? item.company_name :
                                          <input type="text" name="company_name" id="comname" className="inputbox02" placeholder="" value={item.company_name} onChange={changeHandler}/>
                                        }
                                        </div>
                                  </div>
                              </td>
                          </tr>
          
                          <tr className={Message.hasMessge(message, "name") ? "err" : null}>
                              <th>
                                  <span className="thname">お名前</span><span className="required">必須</span>
                              </th>
                              <td>
                                  <div className="textbox">
                                      <div>
                                      {props.is_confirm ? item.name :
                                          <input type="text" name="name" id="name" className="inputbox02" placeholder="" value={item.name} onChange={changeHandler}/>
                                      }</div>
                                      <ul class="error-box">
                                   {message ? Message.getMessgeFromList(message,"name") : null}
                                  </ul>
                                  </div>
                              </td>
                          </tr>
          
                          <tr className={Message.hasMessge(message, "name_kana") ? "err" : null}>
                              <th>
                                  <span className="thname">お名前（フリガナ）</span><span className="required">必須</span>
                              </th>
                              <td>
                                  <div className="textbox">
                                      <div>
                                      {props.is_confirm ? item.name_kana :
                                          <input type="text" name="name_kana" id="name" className="inputbox02" placeholder="" value={item.name_kana} onChange={changeHandler}  required />
                                      }</div>
                                      <ul class="error-box">
                                      {message ? Message.getMessgeFromList(message,"name_kana") : null}
                                      </ul>
                                  </div>
                              </td>
                          </tr>
          
                          <tr className={Message.hasMessge(message, "zip") ? "err" : null}>
                              <th>
                                  <span className="thname">郵便番号</span><span className="required">必須</span>
                              </th>
                              <td>
                                  <div className="textbox">
                                      <div>
                                      {props.is_confirm ? item.post1 + "- " + item.post2:
                                          <div><input type="text" name="post1" id="post1" className="inputbox03" placeholder="" maxlength="3" value={item.post1} onChange={changeHandler}/> - 
                                          <input type="text" name="post2" id="post2" className="inputbox04" placeholder="" maxlength="6" value={item.post2} onChange={changeHandler}/></div>
                                    }</div>
                                    <ul class="error-box">
                                    {message ? Message.getMessgeFromList(message,"zip") : null}
                                    </ul>
                                  </div>
                              </td>
                          </tr>
          
                          <tr className={Message.hasMessge(message, "prefecture_id") ? "err" : null}>
                              <th>
                                  <span className="thname">都道府県</span><span className="required">必須</span>
                              </th>
                              <td>
                              {props.is_confirm ? getPrefectureName(item.prefecture_id) :
                                <select name="prefecture_id" id="prefecture_id" className="inputbox02" placeholder="" value={item.prefecture_id} onChange={changeHandler}  >
                                  {
                                    options
                                  }
                                  </select>
                                  
                                  
                              }
                              <ul class="error-box">
                                   {message ? Message.getMessgeFromList(message,"prefecture_id") : null}
                                  </ul>
                              </td>
                          </tr>
                          
                          <tr className={Message.hasMessge(message, "address") ? "err" : null}>
                              <th>
                                  <span className="thname ls10">住所</span><span className="required">必須</span>
                              </th>
                              <td>
                                  <div className="textbox">
                                      <div>
                                      {props.is_confirm ? item.address :
                                          <input type="text" name="address" id="address" className="inputbox02" placeholder="" value={item.address} onChange={changeHandler} />
                              }
                                <ul class="error-box">
                                   {message ? Message.getMessgeFromList(message,"address") : null}
                                  </ul>
                              </div>
                                  </div>
                              </td>
                          </tr>
          
                          <tr className={Message.hasMessge(message, "address_add") ? "err" : null}>
                              <th>
                                  <span className="thname">マンション名など</span>
                              </th>
                              <td>
                                  <div className="textbox">
                                      <div>
                                      {props.is_confirm ? item.address_add :
                                          <input type="text" name="address_add" id="address2" className="inputbox02" placeholder="" value={item.address_add} onChange={changeHandler}  />
                                      }
                                      
                                      </div>
                                  </div>
                              </td>
                          </tr>

                          <tr className={Message.hasMessge(message, "tel") ? "err" : null}>
                              <th>
                                  <span className="thname ls10">電話番号</span><span className="required">必須</span>
                              </th>
                              <td>
                                  <div className="textbox">
                                      <div>
                                        {props.is_confirm ? item.tel :
                                          <input type="text" name="tel" id="tel" className="inputbox02" placeholder="" value={item.tel} onChange={changeHandler}  />
                                        }
                                        
                                        <ul class="error-box">
                                   {message ? Message.getMessgeFromList(message,"tel") : null}
                                  </ul>
                                  </div>
                                  </div>
                              </td>
                          </tr>
                      </table>
                      <div className="buttonbox pt1">
                        {
                          props.btn ? props.btn : null
                        }
                          <div className="button or">
                              <div onClick={submit} >{props.submit_text ? props.submit_text :"変更内容を確認"}</div>
                          </div>
                      </div>
                  </form>
          </div>
    : null
  )
}

export default ProfileDetail
   

/**
 * {!! Form::select(
                                      'prefecture_id',
                                      $select['prefecture'],
                                      isset($user['prefecture_id']) ? $user['prefecture_id'] : null,
                                      [
                                          'id' => 'prefecture_id',
                                          'class' => 'form-select ' . ($errors->has('prefecture_id') ? 'is-invalid' : ''),
                                      ])
                                  !!}
 */