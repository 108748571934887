
const config = {
  VERSION:  "0.0.3",
  
  API_PATH:                 process.env.REACT_APP_API_PATH ? process.env.REACT_APP_API_PATH  : 'http://localhost:5991',
  POST_CODE_API:            "https://zipcloud.ibsnet.co.jp/api/search",


  CLIENT_ID:process.env.REACT_APP_CLIENT_ID ? process.env.REACT_APP_CLIENT_ID  :2,
  CLIENT_SECRET:process.env.REACT_APP_CLIENT_SECRET ? process.env.REACT_APP_CLIENT_SECRET  :"Zbinc1cB4jt6ssaLC1cEursuAHviPiU5gu4meq5p",

  PASSWORD_MIN:8, 
  CSV_FIXED_LENGTH:10,
  STATUS_1:"注文受付",
  STATUS_2:"データ確認中",
  STATUS_3:"発送完了",
  STATUS_4:"納品完了",
  STATUS_6:"製造中",

  MESSAGES: [
    {key:'address_add',value:'マンション名などを入力してください'},
    {key:'address.max', value:'住所は :max文字以内で入力してください'},
    {key:'address.regex', value:'住所にスペースキーは使用できません'},
    {key:'address', value:'住所を入力してください'},
    {key:'company_name', value:'会社名を入力してください'},
    {key:'csv', value:'宛名CSVをアップロードを選択してください'},
    {key:'email_password.current_password', value:'現在のパスワードを入力してください'},
    {key:'email.email:filter', value:'メールアドレスの形式が無効です'},
    {key:'email.max', value:'正しいメールアドレスを入力して下さい'},
    {key:'email.required', value:'メールアドレスを入力して下さい'},
    {key:'email.unique', value:'既に使用されているメールアドレスです'},
    {key:'name_kana.max', value:'名前(カナ)は25文字以内で入力して下さい'},
    {key:'name_kana.regex', value:'名前(カナ)は全角カタカナのみ入力してください'},
    {key:'name_kana.required', value:'名前(カナ)を入力して下さい'},
    {key:'name_kana', value:'フリガナを入力してください'},
    {key:'name.max', value:'名前は25文字以内で入力して下さい'},
    {key:'name.regex', value:'名前は全角ひらがな・全角カタカナ・漢字のみ入力してください'},
    {key:'name.required', value:'お名前を入力して下さい'},
    {key:'name', value:'お名前を入力してください'},
    {key:'new_password_confirmation.confirmed', value:'新しいパスワード(確認)と新しいパスワードが一致していません'},
    {key:'new_password.min', value:'新しいパスワードは最低8文字以上入力して下さい'},
    {key:'new_password.required', value:'新しいパスワードを入力してください'},
    {key:'paper', value:'用紙を選択してください'},
    {key:'password_confirmation.required', value:'パスワード(確認)を入力して下さい'},
    {key:'password.confirmed', value:'パスワード(確認)を確認して下さい'},
    {key:'password.current_password', value:'現在のパスワードを入力してください'},
    {key:'password.min', value:'パスワードは最低8文字以上入力して下さい'},
    {key:'password.required', value:'パスワードを入力して下さい'},
    {key:'pdf_back_path', value:'中面のデザインデータを選択してください'},
    {key:'pdf_back', value:'中面のデザインデータを選択してください'},
    {key:'pdf_front_path', value:'表面のデザインデータを選択してください'},
    {key:'pdf_front', value:'表面のデザインデータを選択してください'},
    {key:'plan', value:'印刷プランを選択してください'},
    {key:'zip.regex', value:'郵便番号の形式をご確認下さい'},
    {key:'zip.required', value:'郵便番号を入力してください'},
    {key:'post1.regex', value:'郵便番号の形式をご確認下さい'},
    {key:'post1.required', value:'郵便番号を入力してください'},
    {key:'post2.regex', value:'郵便番号の形式をご確認下さい'},
    {key:'zip.regex', value:'郵便番号の形式をご確認下さい'},
    {key:'post2.required', value:'郵便番号を入力してください'},
    {key:'prefecture_id.required', value:'都道府県を選択してください'},
    {key:'prefecture_id', value:'都道府県を選択してください'},
    {key:'processing', value:'加工方法を選択してください'},
    {key:'sheet.max', value:'枚数 :max を入力してください'},
    {key:'sheet.min', value:'枚数 :min を入力してください'},
    {key:'sheet.numeric', value:'枚数 :max を入力してください'},
    {key:'sheet', value:'枚数を入力してください'},
    {key:'memo.required', value:'お問い合わせ内容を入力してください'},
    {key:'shipping_address', value:'配送先を選択してください'},
    {key:'shipping', value:'配送方法を選択してください'},
    {key:'specification', value:'仕様を選択してください'},
    {key:'tel.max', value:'電話番号11文字以内で入力してください'},
    {key:'tel.min', value:'電話番号10文字以上で入力してください'},
    {key:'tel.regex', value:'電話番号は半角数字のみ有効です'},
    {key:'tel.required', value:'電話番号を入力してください'},
    {key:'tel', value:'電話番号を入力してください'},
    {key:'temp_back_path', value:'裏面テンプレートを選択してください'},
    {key:'temp_front_path', value:'表面テンプレートを選択してください'},
    {key:'thickness', value:'用紙の厚みを選択してください'},
    {key:'pdf_front.mimes', value:'PDF以外のデータがアップロードされました。PDFで保存した背景をアップロードしてください。'},
    {key:'pdf_back.mimes', value:'PDF以外のデータがアップロードされました。PDFで保存した背景をアップロードしてください。'},
    {key:'csv.mimes', value:'CSV以外のデータがアップロードされました。CSVで保存した背景をアップロードしてください。'},
    {key:'item21.required', value:'項目1配置する面を選択して下さい。'},
    {key:'csv_list', value:'宛名CSVをアップロードを選択してください'},
    {key:'csv_list.*.no', value:'通し番号を正しく入力してください'},
    {key:'csv_list.*.postal_code', value:'郵便番号を正しく入力してください'},
    {key:'csv_list.*.prefecture', value:'都道府県を正しく入力してください'},
    {key:'csv_list.*.address_1', value:'市町村を正しく入力してください'},
    {key:'csv_list.*.address_2', value:'番地を正しく入力してください'},
    {key:'csv_list.*.address_3', value:'その他住所を正しく入力してください'},
    {key:'csv_list.*.corp_name', value:'会社名を正しく入力してください'},
    {key:'csv_list.*.family_name', value:'姓を正しく入力してください'},
    {key:'csv_list.*.first_name', value:'名を正しく入力してください'},
    {key:'csv_list.*.honorific_title', value:'敬称を正しく入力してください'},
    {key:'csv_list.*.variable', value:'中面可変の項目指定に指定を行ってください。'},
    {key:'csv_list.*.variable_1', value:'中面可変の項目1を正しく入力してください'},
    {key:'csv_list.*.variable_3', value:'中面可変の項目2を正しく入力してください'},
    {key:'csv_list.*.variable_2', value:'中面可変の項目3を正しく入力してください'},
    {key:'qr.formaterror', value:'「連携させたい列」の中にQRコードに生成できない文字が含まれています。半角英数以外が使用されていないかご確認ください。'},
    {key:'login.error', value:'IDまたはパスワードが正しくありません。'}
  ]
}   


export default config;
