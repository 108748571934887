import { useState } from 'react';
import AuthAction from './AuthAction';


function useAuthState() {

  const [token, setToken] = useState(null)
  const [error, setError] = useState(null)


  const completedHandler = (data) => {
    setToken(data)
  }

  const errorHandler = (data) => {
    window.location.href = "/login"
    //setError(data)
  }
  const errorNHandler = (data) => {
    //window.location.href = "/login"
    setError(data)
  }

  const auth = (need_auth) => {
    if(!need_auth){
      AuthAction.getToken( completedHandler, errorHandler)
    }else{
      AuthAction.getToken( completedHandler, errorNHandler)
    }
    
  }
  
  const logout = (url) => {

    AuthAction.logout()
    
    window.location.href = "/"
  }


  return { token:token, loginError:error, auth:auth, logout:logout};
}

export default useAuthState