
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import Action from '../action/Action';

const InqueriAction = {


  saveOnCookie(item){
    cookie.save('inqueri', item, { path: '/' })
  },

  loadFromCookie(callback){
    var item =  cookie.load("inqueri")
    callback(item)
  },

  removeFromCookie(){
    cookie.remove("inqueri",{ path: '/' })
  },



  create(item, handler, errorHandler){
    const path = config.get("API_PATH") + "/api/inquires" 
    const header =  {'Content-Type': 'application/json'}

    Action.fetchJson(  path, "POST", header, JSON.stringify(item),  handler)
  
  },


 
}

export default InqueriAction
