import React, { useState, useEffect } from 'react'
import { DesignerV,DesignerZ }from '@spursjp/pdfme-ui';
//import { Designer } from '@pdfme/ui';
import useMediaState from '../media/useMediaState';
import Schema from './pdf_me/schema';
import GetCsvRowItem from './GetCsvRowItem';
import * as pdfUtils from './pdf_me/pdf_utils';
import fontList from './pdf_me/fontList';
import UsePdfState from './UsePdfState';
import useTemplateState from "../template/useTemplateState"
import RequestValue from '../RequestValue';
/** import svg_v_omote from "./svg/v_omote.svg"
import svg_v_ura from "./svg/v_ura.svg";
import svg_z_omote from "./svg/z_omote.svg"
import svg_z_ura from "./svg/z_ura.svg" **/

import  "./pdfme.css"

function PdfEditor(props:PropsValue) {
  //const [schema, setSchema] = useState(null)
  const [designer, setDesigner] = useState(null)
  const [update_template_date, setTemplateUpdate] = useState(null)
  const [new_designer, setNewDesigner] = useState(null)
  const [new_template, setNewTemplate]= useState(null)
  //const [svg, setSvg] = useState(null)

  const [longet_html_data, setLongetHtmlData] = useState(null)
  const {pdf,getPdf} = useMediaState()
  const {template,schema,getTemplate ,setSchema ,getSchema} = useTemplateState()
  const {getPossibleSnapPostionList} = UsePdfState()
  const longet_data = GetCsvRowItem.getLongestItem(props.cart.csv)
  useEffect(() => {
  
    if(designer){

      props.setDesigner(designer.getTemplate())
    }
      
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[designer]);


  useEffect(() => {

    if(props.token && props.cart && props.type){

     if((Number(props.type) === 1 && props.cart.temp_front_path) || (Number(props.type) === 2 && props.cart.temp_back_path) ){
      //getPdf(props.token, props.cart.id, props.type)
      //load front page template
      
      getTemplate(props.token,props.cart.id, props.type)
      /** setSvg(props.cart.specification === RequestValue.SPECIFICATION_V ?
        props.type === 1? svg_v_omote : svg_v_ura  
        : props.type === 1? svg_z_omote : svg_z_ura ) **/
     }else{
      //PDFロード
      getPdf(props.token, props.cart.id, props.type)
      
     }
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.token, props.cart, props.type]);


  useEffect(() => {

    if(props.is_editing && new_template && new_designer ){
      
      ///最長HTMLの情報を一度だけ取得
      var l_data = longet_html_data

      if(!l_data){
        l_data = getLongestData()
          setLongetHtmlData(l_data)
      }


     const msg = new_template.schemas && new_template.schemas.length > 0 ? pdfUtils.checkAllCollisions(new_template, props.cart.specification, props.type ,longet_data) : null
     
      if(msg){
        alert(msg)
        
        if(schema){
          new_template.schemas = [getSchema()]
          new_designer.updateTemplate(new_template);
        }else{
          console.log("no schema")
        }
      
      }else{
       setSchema(new_template.schemas ? new_template.schemas[0] : null)
      }

      if(new_template.schemas && new_template.schemas.length > 0){
        const snap_positions = getPossibleSnapPostionList(new_template)
        new_designer.updateHorizontalGuidelines(snap_positions.horizontal)
        new_designer.updateVerticalGuidelines(snap_positions.vertical)
      }
       
      props.setDesigner(new_designer.getTemplate(), null )  

    
    } 
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[update_template_date]);  

  //temleteがロードされた時
  
  useEffect(() => {
    
    if(template){
      
      const domContainer = document.getElementById('domContainer');

      setSchema(template.schemas ? template.schemas[0] : null)
      if(!template.sampledata || Object.values(template.sampledata[0]).length === 0){
        template.sampledata = [{}]
      }

      var snap_positions = null
      if(template.schemas && template.schemas.length > 0){
       snap_positions = getPossibleSnapPostionList(template)
      }

      const _designer =  props.cart.specification === RequestValue.SPECIFICATION_V ? new DesignerV({ 
        domContainer, 
        template,  
        options : { lang: "ja", font : fontList,  horizontalGuidelines:snap_positions ? snap_positions.horizontal : [100], verticalGuidelines:snap_positions ? snap_positions.vertical: [100]} ,
        onChangeTemplateCallback:templateChngeHandler
      }) :new DesignerZ({ 
        domContainer, 
        template,  
        options : { lang: "ja", font : fontList,  horizontalGuidelines:snap_positions ? snap_positions.horizontal : [100], verticalGuidelines:snap_positions ? snap_positions.vertical: [100]} ,
        onChangeTemplateCallback:templateChngeHandler
      })
      if(props.cart.specification === RequestValue.SPECIFICATION_V ){
        _designer.setSpecification("v" + props.type)
      }else{
        _designer.setSpecification("z" + props.type)
      }
      
      _designer.onChangeTemplate(_t => {
         templateChngeHandler(_designer, _t)
      })
     
      setDesigner(_designer )
      

      if(!longet_html_data){
        getLongestData()
      }

    }
  
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[template]);  


  //PDFがロードされた時
  useEffect(() => {

    if(pdf && props.cart){

      const domContainer = document.getElementById('domContainer');
      const longest_data = GetCsvRowItem.getLongestItem(props.cart.csv)
      const sche = Schema.getWithAdditionalData(props.cart, props.type, 10, longest_data)

      setSchema(sche)
      const sample = getSampleData(longest_data,sche)
        
      let reader = new FileReader();
      
      reader.onload = function() {
        
        const _template = {
          basePdf : reader.result,  
        };
    
    
        if(Object.keys(sche).length > 0){
          _template.sampledata  =  [sample]
          _template.schemas = [sche]
        }

        var snap_positions = null
        if(_template.schemas && _template.schemas.length > 0){
         snap_positions = getPossibleSnapPostionList(_template)
        }
       
        const _designer = props.cart.specification === RequestValue.SPECIFICATION_V ? new DesignerV({
           domContainer, 
           template:_template, 
           options : { lang: "ja", font : fontList,  horizontalGuidelines:snap_positions ? snap_positions.horizontal : [100], verticalGuidelines:snap_positions ? snap_positions.vertical: [100]} ,
           
          } ) :  new DesignerZ({
            domContainer, 
            template:_template, 
            options : { lang: "ja", font : fontList,  horizontalGuidelines:snap_positions ? snap_positions.horizontal : [100], verticalGuidelines:snap_positions ? snap_positions.vertical: [100]} ,
          } )
        
          if(props.cart.specification === RequestValue.SPECIFICATION_V ){
            _designer.setSpecification("v" + props.type)
          }else{
            _designer.setSpecification("z" + props.type)
          }
          
        _designer.onChangeTemplate(__template => {
            templateChngeHandler(_designer, __template)
          })
        setDesigner(_designer,sample)
        
      };
      reader.readAsDataURL(pdf); // blob を base64 へ変換し onload を呼び出します
      //
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pdf,props.cart]); 

  //ページが変更になった時
  useEffect(() => {
 
    if(designer){
      const template = designer.getTemplate();
      //const longet_data = GetCsvRowItem.getLongestItem(props.cart.csv)

      const sche = template.schemas ? template.schemas[0] : null
      
      if(props.current_page){

        const new_data = GetCsvRowItem.getPageItem(props.cart, props.cart.csv[props.current_page - 1])
        const sample = sche ? GetCsvRowItem.getSampleDataWithLongest(new_data,sche, longet_data) : {}
   
        //const sample = sche ? GetCsvRowItem.getSampleData(new_data,sche) : {}

        template.sampledata = [sample]
        designer.updateTemplate(template);
      }else {
     
        const new_data = longet_data
        const sample = sche ? GetCsvRowItem.getSampleDataWithLongest(new_data,sche, longet_data) : {}
    
        //const sample = GetCsvRowItem.getSampleData(longet_data,sche)
        template.sampledata = [sample]
        
        designer.updateTemplate(template);
      }
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.current_page, designer]);


  const templateChngeHandler = (_designer, _template) => {
    setNewDesigner(_designer)
    setNewTemplate(_template)
    setTemplateUpdate(new Date())

  }



  
  const getLongestData = ( ) => {
    //const company = document.getElementById("pefme-text-company")
    //console.log(company.scrollHeight)
    //console.log(company.clientHeight)
    return {}
  }

  const getSampleData = (item, sche ) => {

    var _sample = {}
   if(sche.郵便番号1){
     _sample.郵便番号1 = item.zip.value.substr(0,3)
   }
   if(sche.郵便番号2){
    _sample.郵便番号2 = item.zip.value.substr(4,7)
  }
   if(sche.氏名){
     _sample.氏名 = item.name.value
   }
   if(sche.住所){
     _sample.住所 = item.address.value
   }
   if(sche.会社名){
     _sample.会社名 = item.company_name.value
   }


   if(sche.可変項目1){
     _sample.可変項目1 = item.additional_1.value
   }
   if(sche.可変項目2){
     _sample.可変項目2 = item.additional_2.value
   }
   if(sche.可変項目3){
     _sample.可変項目3 = item.additional_3.value
   }
  
   return _sample
 }



  return(
    <div className='surface'>
      <div id="domContainer" >
          
      </div>
    
    </div>
  ) 
}

export default PdfEditor
/**
 *    <div className={ props && props.cart && props.cart.specification === RequestValue.SPECIFICATION_V ? "svg-box v" : " svg-box z"}>
        <img src={svg} alt="" />
      </div>
 */