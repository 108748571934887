import React ,{} from 'react'
import "../css/member.css"
//import useAuthState from '../auth/useAuthState'



function UserMenu(props) {
 // const {logout} = useAuthState()
 /** 
  const logoutClickHandler = () => {
    logout()
  }
  */
  return(
    <div className={props.selectMenuItem}>
      

      <nav id="member-navi" className="s-contentsin2">
        <ul>
         
            <li>
                <a href="/order-history">注文情報一覧</a>
            </li>
            <li>
                <a href="/profile/delivery">追加配送先変更</a>
            </li>
            <li>
                <a href="/mypage">会員情報変更</a>
            </li>
            
        </ul>
      </nav>
    </div>
  )
}

export default UserMenu

