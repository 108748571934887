import React, { useState, useEffect } from 'react'

import Pdf3dPreview from './Pdf3dPreview';
import SamplePdfDownload from './samplePdf/SampleDownload';
import RequestValue from '../RequestValue';

function LayoutConfirmWindow(props:PropsValue) {

  
  const [item, setItem] = useState(null)

  
  useEffect(() => {
    if(props.item){
    
      setItem(props.item)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.item]);


  const getNextStepBtnLabel = () => {
    return item && item.pdf_front_path && item.pdf_back_path && item.plan !== RequestValue.PLAN_NO_ATENA? "最終印刷データの確認へ" : "注文内容を確認へ"
  }

  const getSubTitle = () => {
    return item && item.pdf_front_path && item.pdf_back_path && item.plan !== RequestValue.PLAN_NO_ATENA? "配置イメージをご確認ください。問題なければ「最終印刷データの確認」へお進みください。" : "  配置イメージをご確認ください。問題なければ「注文内容確認」へお進みください。"
  }




  return(
    <div id="openwindow" className="step5">
      
		<div id="contentsbox" className="contentsin">
      
			<div className="topcomment">
      <h1>レイアウトイメージを確認してください</h1>
	      <p>{getSubTitle()}</p>
			</div>
			<Pdf3dPreview item={item} token={props.token} url={props.url} />
	
      { item && item.plan !== RequestValue.PLAN_NO_ATENA ? 
			<SamplePdfDownload item={props.item} token={props.token} url={props.url}/> 
      : null}
      

      <div id="step-box">
			<div id="step-boxin">
				<div className="l">
					<div className="button bucol2 forward r off" onClick={() => props.close()}>
						<div><span>レイアウトを編集へ</span></div>
					</div>
				</div>
       
				<div className="m">
        { item && item.plan !== RequestValue.PLAN_NO_ATENA ? <div className="button bucol2 forward l" onClick={() => props.moveTo(props.layoutUrl)}>
						<div><span>レイアウト調整へ</span></div>
					</div> : null}
				</div>
       
				<div className="r">
					<div className="button next" onClick={() => props.moveTo(props.nextPage)}>
         
						<div><span>{getNextStepBtnLabel()}</span></div>
					</div>
				</div>
			</div>
		</div>
     
		</div> 

   


		<div id="window-bg"></div>
	</div>
  )
}

export default LayoutConfirmWindow
