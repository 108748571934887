
import 'whatwg-fetch'
import config from 'react-global-configuration';

const AdditionalPlacementAction = {  


  create(token, cart_id, item, handler, errorHandler){

    
    fetch(config.get("API_PATH") + "/api/cart/items/" + cart_id + "/additional"  , {
      method: "POST",
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
       
      },
      body: JSON.stringify({items:item})
    }).then(function(response) {
      
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        handler(data)
      }else{
        errorHandler(data)
      }
    })
  },


 
}

export default AdditionalPlacementAction
