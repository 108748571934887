import React, {  useEffect, useState } from 'react'
import Header from '../../header/Header'
import Menu from '../../menu/Menu'
import Footer from '../../footer/Footer'
import useAuthState from '../../auth/useAuthState'
import usePasswordState from './usePasswordState'
import step_image from "../../images/member/step3.png"
import Message from '../../message/Message'

function PasswordResetApp(props:PropsValue) {
  const [error, setError] = useState(null)
  const [password, setPassword] = useState(null)
  const [password_c, setPasswordC] = useState(null)
  const {token, auth} = useAuthState()

  const  {passwordGetStatus, resetPassword} = usePasswordState()

  useEffect(() => {
    auth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(passwordGetStatus){
      window.location.href = "/mypage/password/updated"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[passwordGetStatus]);


  const update = () => {
    setError(null)
    if(password === null || password === ""){
      setError(Message.getMessage("password.required"))
    }else if(password !== password_c){
      setError(Message.getMessage("new_password_confirmation.confirmed"))
    }else if(password.length < 8){
      setError(Message.getMessage("new_password.min"))
    }else{
      resetPassword(token, password)
    }

  }

  return(

        <div id="page" className="password"> 
          <Header token={token} />
          <Menu />
          <article id="contents">
            
            <section className="s-contentsin2 topcomment1">

            <div className="step-box">
              <img src={step_image} alt="" />
            </div>

            <h1 className="stitle3">パスワード再設定</h1>
              <p>パスワードを再設定いたします。8文字以上の半角英数字でパスワードを入力してください。<br />
              確認のため、パスワード（確認用）にも入力をお願いします。<br />
              入力できましたら、「パスワードを再設定する」ボタンを押して下さい。<br />
              完了後、登録メールアドレスに再設定完了通知をお送りいたします。</p>
      

            </section>
            {error ?
            <section className="contentsin">
              <ul className="errorbox"><li>{error}</li></ul>
           </section>
           : null}


           <section className="s-contentsin2 delivery-list">

              <div className="box">
                          <table className="form-table address-table">
                              <tr>
                                  <th>
                                      <span className="thname">パスワード</span>
                                  </th>
                                  <td>
                                      <div className="textbox">
                                          <div>
                                              <input id="password" type="password" className="inputbox02" name="password" required value={password} onChange={(e) => setPassword(e.target.value)} />
                                          </div>
                                      </div>
                                  </td>
                              </tr>
                              <tr>
                                  <th>
                                      <span className="thname">パスワード（確認用）</span>
                                  </th>
                                  <td>
                                      <div className="textbox">
                                          <div>
                                              <input id="password_confirmation" type="password" className="inputbox02" name="password_confirmation" required value={password_c} onChange={(e) => setPasswordC(e.target.value)} />
                                          </div>
                                      </div>
                                  </td>
                              </tr>
                          </table>

                          <div className="buttonbox">
                              <div className="button or">
                                  <div onClick={update}>パスワードを再設定する</div>
                              </div>
                          </div>
           
              </div>

	          </section>
            </article>
          <Footer />
        </div>
  

  )
}

export default PasswordResetApp
