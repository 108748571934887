import React from 'react'
import "./navi.css"
function Menu(props:PropsValue) {
  
  return(
    <section className="contents" id="header-navi">
      <nav className="contentsin">
          <ul>
              <li><a href="/"><div>HOME</div></a></li>
              <li><a href="/order"><div>入稿の流れ</div></a></li>
              <li><a href="/howto"><div>入稿方法<br/><span>（データ作成方法）</span></div></a></li>
              <li><a href="/delivery"><div>料金・発送・<br/>お支払いについて</div></a></li>
              <li><a href="/faq"><div>Q&A</div></a></li>
              <li><a href="/company"><div>会社概要</div></a></li>
              <li><a href="/inquiry"><div>お問合せ</div></a></li>
          </ul>
      </nav>
    </section>
  )
}

export default Menu

