import React, { useState, useEffect } from 'react'
import DeliveryDetail from './DeliveryDetail';
import DeliveryList from "./DeliveryList"

function DeliveryWindow(props:PropsValue) {

  
  const [item, setItem] = useState(null)



  
  useEffect(() => {

    if(props.delivery_list && props.delivery_list.length < 6){
      setItem(<DeliveryDetail profile={props.item} submit={submit} />)
    }else if(props.delivery_list){
      setItem(<DeliveryList items={props.delivery_list} delete={props.delete} submit={submit} />)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.delivery_list]);

  const submit = (_item) => {
    props.submit(_item)
  }

  return(
    <div id="openwindow">
       <article id="contents" className="">
     
        <div id="contentsbox" className="contentsin s-contentsin2 delivery-list">
        <div id="closebox" onClick={props.close}>
          <p><div>確認画面を閉じる</div></p>
          <div id="window-toggle">
            <div>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
          {
            props.delivery_list && props.delivery_list.length < 6 
             ? 
             <div className="topcomment">
                <h2>配送先を入力してください</h2>
                <p className="ct">配送先は最大6つまで登録可能です。下記の必須項目を入力してご登録ください。</p>
              </div>
             : 
             <div className="topcomment">
            <h2>配送先を編集してください</h2>
            <p>配送先は最大6つまで登録可能です。<br/>
            新規に配送先を指定されたい場合は、下記の配送先を1つ削除してから新規にご入力いただくか、またはどれか一つの編集を行ってください。<br/>
            必須項目は必ずご入力ください。</p>
            </div>
          }
          {item}
        </div> 
        </article>
     
      

      <div id="window-bg"></div>
	</div>
  )
}

export default DeliveryWindow
