export const DEFAULT_LANG = 'en' as const;

export const DESTROYED_ERR_MSG = 'this instance is already destroyed';

export const ZOOM = 3.7795275591;

export const SELECTABLE_CLASSNAME = 'selectable';

export const RULER_HEIGHT = 30;

export const SIDEBAR_WIDTH = 300;
