
import { Record } from 'immutable'

const CsvRowItem = Record({
  value:"",
  x:0,
  y:0,
});
export default CsvRowItem;


