import React, { } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import "../css/base.css"
import "../css/page.css"

function LawApp(props:PropsValue) {
  

  
  return(

        <div id="page" className="page law"> 
          <Header noAuth={true} />
          <Menu />
          <article id="contents">
          <section className="s-contentsin2 topcomment">
           <h1 className="stitle-line">特定商取引法に基づく表記</h1>
          </section>

          <section className="s-contentsin2 box-contents">
		<table className="form-table address-table">
			<tr>
				<th>
					会社名
				</th>
				<td>
					あさひ高速印刷株式会社
				</td>
			</tr>
			<tr>
				<th>
					販売責任者
				</th>
				<td>
					岡　達也
				</td>
			</tr>
			<tr>
				<th>
					所在地
				</th>
				<td>
					〒550-0002　<br className="brsp" />大阪府大阪市西区江戸堀2丁目1番地13
				</td>
			</tr>
			<tr>
				<th>
					連絡先
				</th>
				<td>
					06-6448-7521
				</td>
			</tr>
			<tr>
				<th>
					商品代金
				</th>
				<td>
					印刷部数により変わります。料金表をご確認ください。
				</td>
			</tr>
			<tr>
				<th>
					商品代金以外<br className="brsp" />の必要金額
				</th>
				<td>
					消費税（価格表記は全て消費税込みです）<br />
					送料・配送手数料は注文時に別途表示します。
				</td>
			</tr>
			<tr>
				<th>
					お支払い方法
				</th>
				<td>
					クレジットカード・代金引換え（30万円（税込）以内のみご利用可能）
				</td>
			</tr>
			<tr>
				<th>
					お支払い時期
				</th>
				<td>
					クレジットカード支払いの場合・・・商品入稿時<br />
					代金引換えの場合・・・商品到着時
				</td>
			</tr>
			<tr>
				<th>
					商品のお届け時期
				</th>
				<td>
          ご注文確定後3週間以内に発送いたします。<br />
					※一部地域・離島を除く。<br />
					※繁忙期は受注状況によって通常よりお時間をいただく場合がございます。
				</td>
			</tr>
			<tr>
				<th>
					返品・交換・不良品<br className="brsp" />について
				</th>
				<td>
        完全受注生産のため、一度注文されました商品は、返品・交換・キャンセルはできません。<br />万一不良品がある場合は、商品引渡し時より7日以内に弊社にメールもしくは電話にてその旨をお伝えください。<br />
        商品を交換いたしますので、着払いにて商品をお送りください。<br />
					<br/>
					　1．お名前	<br/>
					　2．メールアドレス	<br/>
					　3．注文番号	<br/>
					　4．交換理由
				</td>
			</tr>
		</table>

	</section>


          </article>
          <Footer />
        </div>
  

  )
}

export default LawApp
