import RequestValue from "../../../RequestValue"
import PdfLayoutDefaultInfo from "./PdfLayoutDefaultInfo"

class Layout {
  constructor(type,page_type) {

    this.type = type
    this.page_type = page_type
    this.rectangle = type === RequestValue.SPECIFICATION_V ? Number(page_type) === 1 ? PdfLayoutDefaultInfo.SPEC_V.ERROR_RECT_1 : PdfLayoutDefaultInfo.SPEC_V.ERROR_RECT_2  :  Number(page_type) === 1 ?PdfLayoutDefaultInfo.SPEC_Z.ERROR_RECT_1 : PdfLayoutDefaultInfo.SPEC_Z.ERROR_RECT_2 
  }


  checkEdge(rects){

    for(var i = 0; i < rects.length; i ++){
      const target = rects[i]
      var rect_target = {x1:target.x, x2:(target.x + target.width), y1:target.y, y2:(target.y + target.height)}
      var check = this.checkByRectangle(rect_target, this.rectangle)
      if(!check) {
        return false
      }
    }
    return true
  }

  checkByRectangle(rect1, rect_2_array){
    const center_1_x = rect1.x1 + (rect1.x2 - rect1.x1) / 2
    const center_1_y = rect1.y1 + (rect1.y2 - rect1.y1) / 2
    for(var i = 0; i < rect_2_array.length; i++){
      const rect2 = rect_2_array[i]
      //中心点算出
      const center_2_x = rect2.x1 + (rect2.x2 - rect2.x1) / 2
      const center_2_y = rect2.y1 + (rect2.y2 - rect2.y1) / 2

      //X軸の距離
      const dx =  Math.abs(center_1_x - center_2_x)
      //Y軸の距離
      const dy =  Math.abs(center_1_y - center_2_y)

      //X軸のサイズの和
      const sum_x = ((rect1.x2 - rect1.x1) + (rect2.x2 - rect2.x1)) / 2

      //Y軸のサイズの和
      const sum_y = ((rect1.y2 - rect1.y1) + (rect2.y2 - rect2.y1)) / 2

      if(dx < sum_x && dy < sum_y){
        return false
      }
    }
    return true
  }
}
export default Layout