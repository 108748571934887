import React, { useState, useEffect } from 'react'
import usePrefectreState from '../prefectre/usePrefectreState'
import DeliveryListItem from './DeliveryListItem'


/**
 * 
 * @param {*} props 
 * @props  profile
 * @props confirm()
 *
 * @returns 
 */

function DeliveryList(props:PropsValue) {

  const [items, setItems] = useState(null)


  const {prefectures,getPrefectures} = usePrefectreState()


  useEffect(() => {
    getPrefectures()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  const deleteItem = (item) => {
    props.delete(item)
  }


  useEffect(() => {
    if(prefectures && props.items){
      const _items = props.items.map(item => {
        return (<DeliveryListItem delete={deleteItem} submit={props.submit} item={item} prefectures={prefectures} edit={true}/>)
      })
      setItems(_items)
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[prefectures,props.items]);



  return(

      
      <div>{items}</div>
 
  )
}

export default DeliveryList
   
