import { useState } from 'react';
import LoginAction from './LoginAction';


function useLoginState() {

  const [token, setToken] = useState(null)
  const [error, setError] = useState(null)


  const completedHandler = (data) => {
    setToken(data.access_token)
  }

  const errorHandler = (data) => {
    setError(data)
  }

  const login = (data) => {
    LoginAction.login(data, completedHandler, errorHandler)
  }
  



  return {login:login, token:token, loginError:error};
}

export default useLoginState