
import KOSUGI_R from "../../../fonts/Kosugi-Regular.ttf"
import KOSUGI_MR from "../../../fonts/KosugiMaru-Regular.ttf"
import NO_BL from "../../../fonts/NotoSerifJP-Black.otf"
import NO_BO from "../../../fonts/NotoSerifJP-Bold.otf"
import NO_EL from "../../../fonts/NotoSerifJP-ExtraLight.otf"
import NO_L from "../../../fonts/NotoSerifJP-Light.otf"
import NO_M from "../../../fonts/NotoSerifJP-Medium.otf"
import NO_R from "../../../fonts/NotoSerifJP-Regular.otf"
import NO_SB from "../../../fonts/NotoSerifJP-SemiBold.otf"

const fontList =  {
   
  KosugiRegular: {
      label: "Kosugi-Regular",
      data: KOSUGI_R,
      fallback: true,
  },
  KosugiMaruRegular: {
      label: "KosugiMaru-Regular",
      data: KOSUGI_MR ,
      fallback: false,
  },
  NotoSerifJPBlack: {
    label: "NotoSerifJP-Black",
    data: NO_BL ,
    fallback: false,
  },
  NotoSerifJPBold: {
    label: "NotoSerifJP-Bold",
    data: NO_BO ,
    fallback: false,
  },
  NotoSerifJPExtraLight: {
    label: "NotoSerifJP-ExtraLight",
    data: NO_EL ,
    fallback: false,
  },
  NotoSerifJPLight: {
    label: "NotoSerifJP-Light",
    data: NO_L ,
    fallback: false,
  },
  NotoSerifJPMedium: {
    label: "NotoSerifJP-Medium",
    data: NO_M ,
    fallback: false,
  },
  NotoSerifJPRegular: {
    label: "NotoSerifJP-Regular",
    data: NO_R ,
    fallback: false,
  },
  NotoSerifJPSemiBold: {
    label: "NotoSerifJP-SemiBold",
    data: NO_SB ,
    fallback: false,
  }

}

export default fontList 