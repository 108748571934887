import React, { useState, useEffect} from 'react'

/**
 * 
 * @param {*} props 
 * @props  profile
 * @props confirm()
 *
 * @returns 
 */

function DeliveryInfoTable(props:PropsValue) {
  const [prefecture_name, setPrefName] = useState(null)
  const [item, setItem] = useState({})

  useEffect(() => {
   
    if(props.item && props.prefectures){

      setItem(props.item)
      const pref = props.prefectures.find(_item => Number(_item.id) === Number(props.item.prefecture_id))
      if(pref)
        setPrefName(pref.prefecture_name)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.prefectures,props.item]);  

  return(
 
          <table className="form-table address-table">
          <tbody>
          
            <tr>
                <th>
                    <span className="thname">会社名</span>
                </th>
                <td>
                {item.company_name}
                </td>
            </tr>
            <tr>
                <th>
                    <span className="thname">お名前</span>
                </th>
                <td>
                {item.name} 
                </td>
            </tr>

            <tr>
                    <th>
                        <span className="thname">お名前（フリガナ）</span>
                    </th>
                    <td>
                    {item.name_kana} 
                    </td>
            </tr>
            <tr>
                    <th>
                        <span className="thname">郵便番号</span>
                    </th>
                    <td>
                    {item.post1}-{item.post2}
                        
                    </td>
            </tr>
            <tr>
                    <th>
                        <span className="thname">都道府県</span>
                    </th>
                    <td>
                    {prefecture_name} 
                    </td>
            </tr>
            <tr>
                    <th>
                        <span className="thname ls10">住所</span>
                    </th>
                    <td>
                    {item.address} 
                    </td>
            </tr>
            <tr>
                    <th>
                        <span className="thname">マンション名など</span>
                    </th>
                    <td>
                    {item.address_add} 
                    </td>
            </tr>
            <tr>
                    <th>
                        <span className="thname">電話番号</span>
                    </th>
                    <td>
                    {item.tel} 
                    </td>
            </tr>
          </tbody>
          </table>
          
         
        
  )
}

export default DeliveryInfoTable
   

/**
 *  <div class="buttonbox pt1">
            {
              props.edit ? 
              <div className="button or">
                <div onClick={props.update}>編集する</div>
              </div>
              : null
            }
             {
              props.delete ? 
              <div className="button gr" onClick={() => props.deleteItem(item)}>
                <div>削除する</div>
              </div>
             : null
            }
          </div>
 */