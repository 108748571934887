import { useState } from 'react';
import SendingAction from './SendingAction';
import cookie from 'react-cookies'

function useSendingState() {

  const [orders, setOrders] = useState([])
  const [order_total_munber, setTotalNumber] = useState(null)
  const [error, setError] = useState(null)
  const [deletes, setDeletes] = useState([])
  const [updated_date, setUpdatedDate] = useState([])
  const [duplicated_date, setDuplicatedDate] = useState(null)

  const loadedHandler = (data) => {

    if(data.orders) {
      const _orders = data.orders.map((item) => {
        return item
      });
      setOrders(_orders);
    }

    if(data.count){
      setTotalNumber(data.count)
    }

    if(data.deletes) {
      const _deletes = data.deletes.map((item) => {
        return item
      });
      setDeletes(_deletes);
    }
    
    setUpdatedDate((new Date()).getMilliseconds())
  }

  const deletedHandler = (token) => {
    SendingAction.get(token, loadedHandler)
  }


  const errorHandler = () => {
    setError(new Date())
  }

  const duplicatedHandler = (data) => {
    if(data){
      cookie.save('current_cart_id', data.id, { path: '/' })
      setDuplicatedDate(new Date())
    }
   
  }

  const get = (token, page) => {
    SendingAction.get(token, page, loadedHandler)
  }
  const removeItem = (token, id) => {
    SendingAction.delete(token, id, deletedHandler)
  }

  const duplicate = (token, id) => {
    SendingAction.duplicate(token, id, duplicatedHandler)
  }

  const duplicateWithCsv = (token, id, item) => {
    SendingAction.duplicateWithCsv(token, id, item, duplicatedHandler, errorHandler)
  }

  return { orders: orders, error:error, deletes: deletes, order_total_munber, duplicated_date, duplicate:duplicate, duplicateWithCsv, getSending: get, removeSending: removeItem , sending_updated_date:updated_date};
}

export default useSendingState