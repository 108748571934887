
import 'whatwg-fetch'
import config from 'react-global-configuration';
import Action from '../../action/Action';

const TemplateAction = {  

  get(token, cart_id, type, handler, errorHandler){
    const path = config.get("API_PATH") + "/api/cart/items/" + cart_id + "/templates/" + (Number(type) === 1 ? "front" : "back")
    Action.fetchAuthorizedJson( path, token, "GET", {}, null,  handler, errorHandler)
  },



  create(token, cart_id, template, sample, type, callback){

    const item = {
      type:type,
      template:template,
      sample:sample
    }
      
  
    fetch(config.get("API_PATH") + "/api/cart/items/" + cart_id + "/templates"  , {
      method:  "POST",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":403}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        callback(data)
      }else{
        //errorHandler(data)
      }
    })    
  },


}

export default TemplateAction
