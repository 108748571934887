
import 'whatwg-fetch'
import config from 'react-global-configuration';
import Action from '../../../action/Action';

const SamplePdfAction = {  



  create(token, cart_id,  sample, callback){

    const item = {sample:sample}

    fetch(config.get("API_PATH") + "/api/cart/items/" + cart_id + "/pdf" , {
      method:  "POST",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200  ){
        return response.blob()
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":403}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        callback(data)
      }else{
        //errorHandler(data)
      }
    })
  },
  downloadZip(token, cart_id, errorHandler){

    fetch(config.get("API_PATH") + "/api/cart/items/" + cart_id + "/pdf/all/download" , {
      method:  "GET",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      }
    }).then(function(response) {
      if(response.status === 200  ){
        return response.blob()
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":403}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        const a = document.createElement("a");
        const url = URL.createObjectURL(data)
        document.body.appendChild(a);
        a.download = 'sample_all_' + cart_id + ".pdf";
        a.href = url;
        a.click();
        a.remove();
        setTimeout(() => {
          URL.revokeObjectURL(url);
      }, 1E4);
      }else{
        errorHandler(data)
      }
    })
  },

  createAllSamle( token, cart_id, handler, errorHandler ){
    const path = config.get("API_PATH") + "/api/cart/items/" + cart_id + "/pdf/all"
    const header = {}
    Action.fetchAuthorizedJson(path, token, "POST", header,  null,  handler, errorHandler)
  },
  
  getStatus( token, cart_id, handler, errorHandler ){
    const path = config.get("API_PATH") + "/api/cart/items/" + cart_id + "/pdf/all/status"
    const header = {}
    Action.fetchAuthorizedJson(path, token, "GET", header,  null,  handler, errorHandler)
  },
}

export default SamplePdfAction
