
import 'whatwg-fetch'
import config from 'react-global-configuration';
import Action from '../action/Action';

const PostCodeAction = {


  getAddressInfo( code, handler, errorHandler ){
    const path = config.get("POST_CODE_API") + "?zipcode=" + code
    Action.fetchText(path, "GET", {}, null,  handler, errorHandler)
  },


 
}

export default PostCodeAction
