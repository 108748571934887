import React, { useState, useEffect } from 'react'


//import background_img_v_omote from "../../images/step/step3-v-omote.png"
//import background_img_v_ura from "../../images/step/step3-v-naka.png"


import RequestValue from '../RequestValue';
import useMediaState from '../media/useMediaState';

function PdfUploader(props:PropsValue) {

  
  const [item, setItem] = useState(null)
  const [error, setError] = useState(null)
  const [back_img_src, setBackImageSrc] = useState(null)
  const {pdf_uploader_error,loaded_image,loaded_images_length,pdfUpdatedDate,getImage, createPdf} = useMediaState()
  
  useEffect(() => {
    if(props.item){
      setItem(props.item)
     if(props.type === 1 && props.item.pdf_front_path){
      getImage(props.token, props.item.id, props.type)
     }
     if(props.type === 2 && props.item.pdf_back_path){
      getImage(props.token, props.item.id, props.type)
     }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.item]);

  useEffect(() => {
    if(loaded_image){
      const _possible = loaded_image.filter(item => item.type === props.type)
      if(_possible.length > 0 ){
        setBackImageSrc(_possible[0].data) 
      }
    }
    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loaded_image, loaded_images_length]);

  useEffect(() => {
    
    props.updatePdf()
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pdfUpdatedDate]);


  useEffect(() => {
    if(pdf_uploader_error){
      if(pdf_uploader_error.message === "nopdf"){
        setError("背景データは、PDFをアップしてください。")
      }else if(pdf_uploader_error.message === "sizeerror"){
        setError("背景データが、規定外のサイズです。ご確認後、再アップしてください。")
      }else if(pdf_uploader_error.message === "error"){
        setError("エラーが発生しました。再アップしてください。")
      }
    }
    
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pdf_uploader_error]);



  const uploadPdf = ( e) => {
    setError(null)
    const _file = e.target.files[0]
    createPdf(props.token, props.item.id, _file, props.type)
  }

  const getTItleImg = () => {
    if(item.specification === RequestValue.SPECIFICATION_V){
      if(props.type === 1) return "/images/step/step3-v-ori.png"
      return "/images/step/step3-v-ori-n.png"
    }else{
       if(props.type === 1) return  "/images/step/step3-z-ori.png"
       return  "/images/step/step3-z-ori-n.png"
    }
  }

  return(
    <section className={item && item.specification === RequestValue.SPECIFICATION_V ? "s-contentsin2 ori-v" :"s-contentsin2 ori-z" } id={props.type === 1 ? "table": "medium"}>
      {item ? 
        <div>
          <div className="surface-titlebox">
            <h2 className="surface-title">
                <span>{item.specification === RequestValue.SPECIFICATION_V ? "V" :"Z" }型</span>{props.type === 1 ? "宛名" : "中"}面
            </h2>
            <div class="ori">
					    <img src={ getTItleImg() }  alt="" />
				    </div>
          </div>
          {  back_img_src ? 
            <div className="surface">
               <img src={ props.url.createObjectURL(back_img_src)} alt="" />
            </div>
            : null  }
            
         

          {error ? 
        <ul class="error-box">
          <li>{error}</li>
        </ul>
          : null }
          <div className="box input-button-up">
            <div className="buttonbox">
              <span onClick={() => {document.getElementById("pdf_"+ props.type).click()} } onChange={(e) => uploadPdf(e)}>
                <input type="file" name="pdf" id={"pdf_" +props.type } accept=".pdf" />
                <label>{props.type === 1 ? "宛名" : "中"}面のデザインデータのアップロード</label>
              </span>
            </div>
          </div>
        </div>
      : null}
		</section>
  )
}

export default PdfUploader
