
import 'whatwg-fetch'
import config from 'react-global-configuration';
import Action from '../action/Action';


const OrderAction = {

  create( token, cart_id,  handler, errorHandler = null){

    var path = config.get("API_PATH") + "/api/orders" 
    const header = {'Content-Type': 'application/json'}
    Action.fetchAuthorizedJson(  path,token, "POST", header,  JSON.stringify({cart_id:cart_id}),  handler, errorHandler, {})
  
  },

  createPdf( token, order_id,  handler, errorHandler = null){

    var path = config.get("API_PATH") + "/api/orders/" + order_id + "/createpdf" 
    const header = {'Content-Type': 'application/json'}
    Action.fetchAuthorizedJson(  path,token, "POST", header,  JSON.stringify({}),  handler, errorHandler, {})
  
  },

  
 
}

export default OrderAction
