import React, { useState } from 'react'
import { format } from "date-fns";
import { ja } from "date-fns/locale";

import SendingNewCsvModalContent from './SendingNewCsvModalContent'
import SendingShowBackgroundModalContent from './SendingShowBackgroundModalContent'
import SendingDeleteOrderModalContent from './SendingDeleteOrderModalContent'
import RequestValue from '../order/RequestValue';

//import RequestValue from '../order/RequestValue';

function SendingHistoryContent(props) {

  const [csvModal, setCsvModal] = useState(null)
  const [backGroundModal, setBackgroundModal] = useState(null)
  const [deleteModal, setDeleteModal] = useState(null)

  const displayCsvModal = () => {
    setCsvModal(
      <SendingNewCsvModalContent
        token={props.token}
        item={props.item}
        index={props.index}
        onUploaded={uploadedCsv}
        onClose={closeCsvModal}
      />
    )
  }

  const uploadedCsv = (id, item) => {
    setCsvModal(null)
    props.duplicateItemWithCsv(id,item)
  }
  const closeCsvModal = () => {
    setCsvModal(null)
  }

  const displayBackgroundModal = () => {
    setBackgroundModal(
      <SendingShowBackgroundModalContent
        token={props.token}
        item={props.item}
        index={props.index}
        onClose={closeBackgroundModal}
      />
    )
  }
  const closeBackgroundModal = () => {
    setBackgroundModal(null)
  }

  const displayDeleteModal = () => {
    setDeleteModal(
      <SendingDeleteOrderModalContent
        token={props.token}
        item={props.item}
        index={props.index}
        onDelete={deleteItem}
        onClose={closeDeleteModal}
      />
    )
  }
  const deleteItem = () => {
    if(props.item.id && props.deletItem) {
      closeDeleteModal()
      props.deletItem(props.item.id)
      
      /** new Promise((resolve) => {
        resolve(removeSending(props.token, props.item.id))
      }).then(() => {
        new Promise((resolve) => {
          closeDeleteModal()
          props.callback()
        }).then((resolve) => {
          resolve(true);
        })
      })**/
    }
  }
  const closeDeleteModal = () => {
    setDeleteModal(null)
  }

 /**  const gotoStep6 = () => {
    if(props.item.plan === RequestValue.PLAN_NO_ATENA) {
      window.location.href = "/order/step7";
    }else{
      window.location.href = "/order/step6";
    }
  } 
*/

  const copy = () => {
    props.duplicate(props.item.id)
  }





  

  if(!props.item) {
    return (<div></div>)
  } else {
    return (
      <div className="box" tabIndex={`history_content-${props.index}`}>
        {csvModal}{backGroundModal}{deleteModal}
        <div className="boxin">
          <dl className="table">
            <dt>注文番号</dt>
            <dd>{props.item.order_no}</dd>
          </dl>
          <dl className="table">
            <dt>入稿日</dt>
            <dd>{format(Date.parse(props.item.created_at), 'yyyy年M月d日', {locale: ja})}</dd>
          </dl>
          <dl className="table">
            <dt>データ名</dt>
            <dd>{props.item.filename ? props.item.filename : props.item.data_name}</dd>
          </dl>
          <dl className="table status">
            <dt>現在のステータス</dt>
            <dd>{props.item.status}</dd>
          </dl>

          {props.item.status !== '納品完了' &&
            <p className="min">※現在のステータスが「納品完了」になりましたらデータの再入稿を行えます</p>
          }

          <dl className="table content">
            <dt>入稿内容</dt>
            <dd>
              <dl className="table">
                <dt>仕様</dt>
                <dd>{props.item.specification}</dd>
              </dl>
              <dl className="table">
                <dt>用紙</dt>
                <dd>{props.item.paper}</dd>
              </dl>
              <dl className="table">
                <dt>用紙の厚み</dt>
                <dd>{props.item.thickness}</dd>
              </dl>
              <dl className="table">
                <dt>圧着方法</dt>
                <dd>{props.item.process_type}</dd>
              </dl>
              <dl className="table">
                <dt>加工方法</dt>
                <dd>{props.item.processing}</dd>
              </dl>
              <dl className="table">
                <dt>印刷プラン</dt>
                <dd>{props.item.plan}</dd>
              </dl>
              <dl className="table">
                <dt>印刷枚数</dt>
                <dd>{props.item.sheet ? props.item.sheet.toLocaleString(): null}枚</dd>
              </dl>
            </dd>
          </dl>

          <nav className="list-navi">
            <ul>								
              {props.item.plan !== RequestValue.PLAN_NO_ATENA ?
              <li>
                <div 
                  onClick={() => displayCsvModal()}
                  className={props.item.status === "納品完了" ? '' : 'disabled'}
                >
                    新しいCSVで再入稿
                </div>
              </li>
               : null }
              <li>
                <div
                  // href={`/print-check?order_id={props.item.id}`}
                  onClick={() => copy()}
                  className={props.item.status === "納品完了" ? '' : 'disabled'}
                >
                  再入稿
                </div>
              </li>
              <li>
                <div
                  onClick={() => displayBackgroundModal()}
                  className={props.item.status === "注文受付" ? 'disabled' : ''}
                >
                  背景データ確認
                </div>
              </li>
              <li>
                <div
                  onClick={() => displayDeleteModal()}
                  className={props.item.status === "納品完了" ? '' : 'disabled'}  
                >
                  入稿データ削除
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    )
  }
}

export default SendingHistoryContent
