import { useState } from 'react';
import PasswordAction from './PasswordAction';



function usePasswordState() {

  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  const codeGetHandler = (data) => {
    if(data){
      setSuccess(data.data)
    }else{
      setError(data)
    }
      
  }


  const errorHandler = (data) => {
    setError(data)
  }

  const getCode = (data) => {
    if(!data || data === "") {
      errorHandler("メールアドレスを入力してください。")
      return null
    }
    PasswordAction.getCode( data,codeGetHandler,errorHandler)
  }

  const reset = (token, password) => {

    PasswordAction.reset( token, password,codeGetHandler,errorHandler)
  }
  



  return {mailSubmit:getCode,resetPassword:reset, passwordGetStatus:success, passwordError:error};
}

export default usePasswordState