import { useState } from 'react';
import InqueriAction from './InqueriAction';


function useInqueriState() {

  const [item, setItem] = useState(null)
  const [error, setError] = useState(null)
  const [created, setCreate] = useState(null)


  const updatedHandler = (data) => {
    if(data){
      setItem(data)
    }
  }

  const createdHandler = (data) => {
    if(data){
      setCreate(new Date())
    }
  }
  const errorHandler = (data) => {
    //TODO 
    setError(data.data)
  }


  

  const updateItem = ( item) => {
    InqueriAction.create( item, createdHandler, errorHandler)
  }


  const save = (data) => {
    InqueriAction.saveOnCookie(data)
  }

  const loadFromCookie = () => {
    InqueriAction.loadFromCookie( updatedHandler)
  }

  const removeFromCookie = () => {
    InqueriAction.removeFromCookie()
  }


  return { inqueri:item,error,created, create:updateItem, save, loadFromCookie,removeFromCookie};
}

export default useInqueriState