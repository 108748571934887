import React from 'react'
const Utile = {

  getLabelByValue: function(list,value){

    const result = list.filter(item => item.value === value) 
    if(result.length > 0) return result[0].label
    return null
  },

  
  

  getObjectByValue: function(list,value){

    const result = list.filter(item => item.value === value) 

    if(result.length > 0) return result[0]
    return null
  },




  nl2br: function(text) {

    let regex = /(\n)/g
    const tex = text.split(regex).map((line, i) => {
        if (line.match(regex)) {
            return (<br key={i} />)
        }else {
            return <span>{line}</span>
        }
    });
    return tex
  },

  getDefaultSelectStyle:function(){
    return {
      control: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: "#f5f5f5",
        padding:".5rem .2rem",
        borderRadius:"10px",
      }),
    }
  
  },

  
  getWeekString: function(target_day){
    switch(target_day.getDay()) {
      case 0 : return "日"
      case 1 : return "月"
      case 2 : return "火"
      case 3 : return "水"
      case 4 : return "木"
      case 5 : return "金"
      case 6 : return "土"
      default: return null
    }
  },

  toHalfWidth: function(str) {
    // 全角英数字を半角に変換
    str = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    return str;
  }
  
}

export default Utile
