const RequestValue = {
  SPECIFICATION_V : 'V型はがき',
  SPECIFICATION_Z :  'Z型はがき',
  PAPER_MAT:"マットコート紙",
  THICKNESS_90:"90kg",
  THICKNESS_110:"110kg",
  THICKNESS_135:"135kg",
  PROCESSING_ZURASHI:"ずらし加工",
  PROCESS_TYPE_NORMAL:"通常圧着",
  PLAN_ATENA:"宛名あり",
  PLAN_ATENA_NAKA:"宛名あり+中面可変あり",
  PLAN_NO_ATENA:"宛名なし",
  DELIVERY_ME:"会員情報で登録した配送先",
  DELIVERY_REGISTERED:"会員情報以外の配送先を指定",
  DELIVERY_ALTER:"配送代行による配送",
  PAYMENT_METHOD_CASHON:"代金引換",
  PAYMENT_METHOD_CREDIT:"クレジットカード"
 }

 export default RequestValue  