import { useState } from 'react';
import TemplateAction from './TemplateAction';



function useTemplateState() {

  const [updated, setUpdated] = useState(false)
  const [template, setTemplate] = useState(null)
  const [updated_at, setUpdatedAt] = useState(null)
  const [schema, setSchemaT] = useState(null)

  const createdHandker = (data) => {
    setUpdated(true)
  }
  

  const loadedHandler  = (data) => {
    setTemplate(data.data)
    setUpdatedAt(new Date())
  }


  const getTemplate = (token, cart_id, type) => {
    TemplateAction.get( token, cart_id, type, loadedHandler)
  }

  const create = (token, cart_id, template,sample, type) => {

    TemplateAction.create( token, cart_id, template, sample, type, createdHandker)
  }

  const getSchema = () => {
    return schema
  }
  const setSchemaTC = (s) => {
    setSchemaT(s)

  }



  return { template:template, schema, updatedTemplate:updated, template_updated_at:updated_at, createTemplate:create, getTemplate:getTemplate ,setSchema:setSchemaTC,getSchema };
}

export default useTemplateState