import React, {  useEffect } from 'react'
import Header from '../../header/Header'
import Menu from '../../menu/Menu'
import Footer from '../../footer/Footer'
import useRegisterState from './useRegisterState'


function RegisterConfirmApp(props:PropsValue) {
  const {account, regiterError, createResult, loadAccountInfo,removeAccountInfo, createUser} = useRegisterState()


  useEffect(() => {
    loadAccountInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    
  },[account]);
  
  useEffect(() => {
    if(regiterError){
      window.location.href = "/register"
    }
  },[regiterError]);

  useEffect(() => {
    if(createResult){
      removeAccountInfo()
      window.location.href = "/register/complete"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[createResult]);


  const create = () => {
    createUser(account)
  }

  return(
      <div id="page" className="member temporary-registration check"> 
        <Header />
        <Menu />
        <article id="contents">
        <section className="topcomment">
          <h1>登録内容確認</h1>
          <p>ご登録の内容に間違えがないかご確認ください。「登録」を押すと入稿ステップに移ります。<br />
		ご登録のメールアドレスに仮登録のお知らせをお送りしますのでご確認ください。<br />
		しばらくたってもメールが届かない場合は、アドレスが間違っている可能性がございますので、再度、仮登録をお願いいたします。</p>
        </section>

        <section className="s-contentsin2 delivery-list">

          <div className="box">
                  <form id="register">
                      <table className="form-table address-table">
                          <tr>
                              <th>
                                  <span className="thname">ID（メールアドレス）</span>
                              </th>
                              <td>
                                  {account ? account.email : null}
                                  
                              </td>
                          </tr>
                          <tr>
                              <th>
                                  <span className="thname">パスワード</span>
                              </th>
                              <td>
                                  ※※※※※※※※※※※※※※
                              </td>
                          </tr>
                      </table>

                      <div className="buttonbox pt1">
                          <div className="button gr">
                              <a href="/register">修正する</a>
                          </div>
                          <div className="button or">
                              <div onClick={create}>登録する</div>
                          </div>
                      </div>
                  </form>
          </div>

        </section>




           
        </article>
        <Footer />
      </div>


  )
}

export default RegisterConfirmApp


