import React, {  useEffect,useState } from 'react'
import Header from '../../header/HeaderForStep'
import useAuthState from '../../auth/useAuthState'
import OrderForm from './OrdeForm'
import useCartState from '../cart/useCartState'
import step_img from "../../images/step/step-step8.png"

function ConfirmOrderDetailApp(props:PropsValue) {
  var _is_loading = false
  const {token, auth} = useAuthState()
  const {cart, current_cart_id, getCart, getCurrentCartId} = useCartState()
  const [item, setItem] = useState(null)
  useEffect(() => {
    auth()
    getCurrentCartId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    getCurrentCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[current_cart_id]);

  useEffect(() => {
    getCurrentCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);

  useEffect(() => {
    setItem(cart)
  },[cart ]);


  const getCurrentCart = () =>{
    if(token && current_cart_id && !cart && !_is_loading) {
      _is_loading = true
      getCart(token, current_cart_id)
    }
  }



  const moveToNextStep = (step) => {
    /** switch (item.plan) {
      case RequestValue.PLAN_NO_ATENA:
        window.location.href = "/order/step3"
        break;
      default:
        window.location.href = "/order/step2"
        break;
    } **/
    window.location.href = "/order/" + step
   
  }

  return(

        <div id="step" className="step step8"> 
          <Header token={token} />
       
          <form enctype="multipart/form-data" id="stepOne">
            <article id="contents">
            <section className="contentsin now-step">
                <img src={step_img} alt="最終印刷データの確認" />
            </section>

            <section className="s-contentsin topcomment">
              <h1>最終確認</h1>
              <p>印刷内容、配送先、決済方法、合計金額をご確認ください。<br/>
              問題なければ、「決済へ(代金引換の方は完了へ)」へお進みください。</p>
            </section>
             
                 {item ? <OrderForm token={token} item={item} moveToNextStep={moveToNextStep}/> : null }
            
              </article>
          </form>

         
        
        </div>
  

  )
}

export default ConfirmOrderDetailApp
