
import { Record } from 'immutable'
import CsvRowItem from './CsvRowItem';

const CsvRow= Record({
  name:new CsvRowItem(),
  zip:new CsvRowItem(),
  address:new CsvRowItem(),
  company_name:new CsvRowItem(),
  additional_1:new CsvRowItem(),
  additional_2:new CsvRowItem(),
  additional_3:new CsvRowItem(),
});
export default CsvRow;
