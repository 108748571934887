import React, {  useEffect, useState } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import useAuthState from '../auth/useAuthState'
import useProfileState from './useProfileState'
import UserMenu from '../menu/UserMenu'
import Message from '../message/Message'


function MailResetApp(props:PropsValue) {
  const [error, setError] = useState(null)
  const [mail, setMail] = useState(null)
  const {token, auth} = useAuthState()

  const  {createResult, profileError, updateEmail} = useProfileState()

  useEffect(() => {
    auth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  useEffect(() => {
    if(createResult){
      window.location.href = "/profile/email/success"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[createResult]);


  useEffect(() => {
    if(profileError){
      setError("更新に失敗しました。メールアドレスが不正か、すでに利用されているアドレスです。")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profileError]);



  const update = () => {
    setError(null)
    if(mail === null || mail === ""){
      setError(Message.getMessage("email.required"))    
    }else{
      updateEmail(token, mail)
    }

  }

  return(

        <div id="page"  className="member register email-reset"> 
          <Header token={token} />
          <Menu />
          <article id="contents">
            <UserMenu selectMenuItem="register" />
            <section className="s-contentsin2 topcomment">
              <h1 className="stitle">メールアドレス再設定</h1>
              <p>メールアドレスを再設定いたします</p>
            </section>
            {error ?
            <section className="contentsin">
              <ul className="errorbox"><li>{error}</li></ul>
           </section>
           : null}


           <section className="s-contentsin2 delivery-list">

              <div className="box">
                          <table className="form-table address-table">  
                              <tr>
                                  <th>
                                      <span className="thname">ID（メールアドレス）</span>
                                  </th>
                                  <td>
                                      <div className="textbox">
                                          <div>
                                              <input id="password" type="text"  placeholder="ID（メールアドレス）" className="inputbox02" name="email" required value={mail} onChange={(e) => setMail(e.target.value)} />
                                          </div>
                                      </div>
                                  </td>
                              </tr>
                             
                          </table>

                          <div className="buttonbox">
                              <div className="button or">
                                  <div onClick={update}>再設定</div>
                              </div>
                          </div>
           
              </div>

	          </section>
            </article>
          <Footer />
        </div>
  

  )
}

export default MailResetApp
