import React, {  useEffect,useState } from 'react'
import Header from '../../header/HeaderForStep'
import useAuthState from '../../auth/useAuthState'
import OrderForm from './OrdeForm'
import step_img from "../../images/step/step-step7.png"
import useCartState from '../cart/useCartState'
import useProfileState from '../../profile/useProfileState'
import ProfileWindow from '../../profile/ProfileWindow'


function OrderDetailApp(props:PropsValue) {
  var _is_loading = false
  const [item, setItem] = useState(null)
  const [profile_window, setProfileWindow] = useState(null)
  const {token, auth} = useAuthState()
  const {cart, current_cart_id, getCart, getCurrentCartId} = useCartState()

  const {profile,profileUpdatedAt, getProfile, updateProfile} = useProfileState()
  useEffect(() => {
    auth()
    getCurrentCartId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
   
    if(profile && !profile.main_member_at){
      setProfileWindow(<ProfileWindow item={profile} submit={submitProfile} />)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profile]);

  useEffect(() => {
   
      if(profileUpdatedAt){
           window.location.href = "/order/step7"
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profileUpdatedAt]);

  useEffect(() => {
    getCurrentCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[current_cart_id]);

  useEffect(() => {
    if(token){
      getProfile(token)
      getCurrentCart()
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);

  useEffect(() => {
    setItem(cart)
  },[cart ]);


  const getCurrentCart = () =>{
    if(token && current_cart_id && !cart && !_is_loading) {
      _is_loading = true
      getCart(token, current_cart_id)
    }
  }

  const submitProfile = (item) => {
    setProfileWindow(null)
    updateProfile(token, item)
  }


  const moveToNextStep = (item) => {
    /** switch (item.plan) {
      case RequestValue.PLAN_NO_ATENA:
        window.location.href = "/order/step3"
        break;
      default:
        window.location.href = "/order/step2"
        break;
    } **/
    window.location.href = "/order/step8"
   
  }

  return(

        <div id="step" className="step step7"> 
          {profile_window}
          <Header token={token} />
          { profile !== null ? 
          <form enctype="multipart/form-data" id="stepOne">
            <article id="contents">

            <section className="contentsin now-step">
                <img src={step_img} alt="最終印刷データの確認" />
            </section>

              <section className="s-contentsin topcomment">
                  <h1>注文内容確認</h1>
                  <p>印刷内容をご確認いただき、配送先、決済方法を選択し、<span class="red">「最終確認へ」ボタン</span>を押して確認ページへお進みください。<br/>
                  ※印刷内容を変更されたい場合は、各項目を選択しなおしてください。<br />
                  　宛名あり、無しの再選択、CSVデータの再登録、枚数変更はできません。</p>
              </section>
              <section className="s-contentsin2 section">
                  {item ? <OrderForm token={token} profile={profile} item={item} moveToNextStep={moveToNextStep}/> : null}
              </section>
              </article>
          </form>
          : null }
         
        
        </div>
  

  )
}

export default OrderDetailApp
