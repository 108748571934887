
import 'whatwg-fetch'
import AuthAction from '../auth/AuthAction'


const Error = {

  authError(){
    AuthAction.logout()
  }
 
}

export default Error
