import React, {  useEffect } from 'react'

import Header from '../../header/Header'
import Menu from '../../menu/Menu'
import useAuthState from '../../auth/useAuthState'
//import useCartState from '../cart/useCartState'


function SuccessOrderApp(props:PropsValue) {
  //var _is_loading = false
  const {token, auth} = useAuthState()
  //const {cart, current_cart_id, getCart, getCurrentCartId} = useCartState()

  useEffect(() => {
    auth()
    //getCurrentCartId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
/**
  useEffect(() => {
    //getCurrentCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[current_cart_id]);

  useEffect(() => {
    //getCurrentCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);

  useEffect(() => {

  },[cart ]);

  const getCurrentCart = () =>{
    if(token && current_cart_id && !cart && !_is_loading) {
      _is_loading = true
      getCart(token, current_cart_id)
    }
  } */



    return(

      <div id="page" className="index step9"> 
        <Header token={token} />
        <Menu />
        <div className="step">
        <form enctype="multipart/form-data" id="stepOne">
          <article id="contents">
   
            <section className="topcomment">
                <h1>入稿完了</h1>
                <p>この度は、「あさひ高速印刷オンラインショップ DM圧着はがきDM印刷」をご利用いただき、ありがとうございます。<br />
                入稿を完了いたしました。<br />代金引換にて商品をお送りいたしますので、今しばらくお待ちください。<br />
                <br/>
                ご不明な点がございましたら、弊社までお問合せ下さい。
                </p>

                  <div className="box">
                    <h2>お問合せ</h2>
        
                </div>
                <div className="buttonbox">
                

                  <div className="button or">
                    <a href="/public/inquiry">お問合せフォームへ</a>
                  </div>
                </div>

  
            </section>
           
            </article>
        </form>
        </div>
       
      
      </div>


)
}

export default SuccessOrderApp
