import { useState } from 'react';
import Schema from './pdf_me/schema';


function UsePdfState() {

  const [schema, setSchema] = useState(null)


  const getPossibleSnapPostionList = (template) => {

    const target_schema = template.schemas[0]      
      const items = Schema.getItems(target_schema).filter(item => item !== undefined && item !== null)

      const list_x = []
      const list_y = []
  
      items.forEach(item => {
        
        list_x.push(mm2px(item.position.x))
        list_x.push(mm2px(item.position.x + item.width))
        list_y.push(mm2px(item.position.y))
        list_y.push(mm2px(item.position.y + item.height))
      });

      return {horizontal:list_y, vertical:list_x}
  }

  const getSnapPosition = (template) => {
    const target_schema = template.schemas[0]
    const moved_element_obj = Schema.findeMoviedItem(schema,target_schema )
    if(moved_element_obj){
      const moved_element  = Object.values(moved_element_obj)[0];
      
      const items = Schema.getItems(schema)
      const list_x = []
      const list_y = []
      items.forEach(item => {
        list_x.push(mm2px(item.position.x))
        list_x.push(mm2px(item.position.x + item.width))
        list_y.push(mm2px(item.position.y))
        list_y.push(mm2px(item.position.y + item.height))
      });

      //x左が一番近い値
      const min_left = list_x.reduce((a,b)=>Math.abs(a - moved_element.position.x) < Math.abs(b - moved_element.position.x) ?a:b) 
      //x右が一番近い値
      const min_right = list_x.reduce((a,b)=>Math.abs(a - (moved_element.position.x + moved_element.width)) < Math.abs(b - (moved_element.position.x + moved_element.width)) ?a:b) 

      //y上が一番近い値
      const min_top = list_y.reduce((a,b)=>Math.abs(a - moved_element.position.y) < Math.abs(b - moved_element.position.y) ?a:b) 
      //y下が一番近い値
      const min_bottom = list_y.reduce((a,b)=>Math.abs(a - (moved_element.position.y + moved_element.height)) < Math.abs(b - (moved_element.position.y + moved_element.height)) ?a:b) 

      const min_left_dif = Math.abs(moved_element.position.x - min_left)
      const min_right_dif = Math.abs(moved_element.position.x + moved_element.width - min_right)
      const min_top_dif = Math.abs(moved_element.position.y - min_top)
      const min_bottom_dif = Math.abs(moved_element.position.y + moved_element.height - min_bottom)

      const min_dif_x = min_left_dif > min_right_dif ? min_right : min_left_dif
      const min_dif_y = min_top_dif > min_bottom_dif ? min_bottom_dif : min_top_dif
      
      
      const min_dif = 5


      return {target:moved_element_obj, left:min_dif_x < min_dif ? min_left_dif > min_right_dif ? min_right - moved_element.width : min_left : null, 
        top:min_dif_y < min_dif ? min_top_dif > min_bottom_dif ? min_bottom - moved_element.height : min_top : null}
    }else{
      console.log("no moved element")
    }
    
  }
  const getSc = (sc) => {
 
    console.log(schema)
    return schema
  }

  const updateSchema = (sc) => {

    console.log(sc)
    setSchema(sc)
  }


   const mm2px = (mm) => {
    // http://www.unitconversion.org/typography/millimeters-to-pixels-y-conversion.html
    const mmRatio = 0.264583333;
    return parseFloat(String(mm)) / mmRatio;
  };

  return {  schema:schema, getSchema:getSc, setSchema:updateSchema, getPossibleSnapPostionList:getPossibleSnapPostionList, getSnapPosition:getSnapPosition};
}

export default UsePdfState