
import config from 'react-global-configuration';

const Message = {

  getMessage: function(key){
    const result = config.get("MESSAGES").filter(item => item.key === key) 
    if(result.length > 0) return result[0].value
    return null
  },

  hasMessge: function(messages, type){
    if(!messages) return false
    const result = messages.filter(item => item.split(".")[0]  === type) 
    if(result.length > 0) return true
    return false
  },

  getMessgeFromList: function(messages, type){
    if(!messages) return false
    const result = messages.filter(item => item.split(".")[0]  === type) 
    const items =  result.map(item => {
      return <li>{Message.getMessage(item)}</li>
    })
    return items
  }
  
}

export default Message
