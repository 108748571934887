import Layout from "./layoutinfo/Layout";

const baseUrl = window.location.origin


// Check multiple Collisions
export function detectCollisions(rectObject) {
    let obj1;
    let obj2;

    // Reset collision state of all objects
    for (let i = 0; i < rectObject.length; i++) {
        rectObject[i].isColliding = false;
    }

    // Start checking for collisions
    for (let i = 0; i < rectObject.length; i++)
    {
        obj1 = rectObject[i];
        
        for (let j = i + 1; j < rectObject.length; j++)
        {
            obj2 = rectObject[j];

            // Compare object1 with object2
            if (rectIntersect(obj1.x, obj1.y, obj1.width, obj1.height, obj2.x, obj2.y, obj2.width, obj2.height)){
                obj1.isColliding = true;
                obj2.isColliding = true;
            }
        }
    }

    return rectObject;
}

// Check overlap
export function rectIntersect(x1, y1, w1, h1, x2, y2, w2, h2) {
    // Check x and y for overlap
    if (x2 > w1 + x1 || x1 > w2 + x2 || y2 > h1 + y1 || y1 > h2 + y2) {
        return false;
    }

    return true;
}

// Check edge collisions
export function edgeCollisions(edgeObject) {
    // Reset edge collision state of all objects
    for (let i = 0; i < edgeObject.length; i++) {
        edgeObject[i].isInEdge = false;
    }

    // Start checking for edge
    for (let i = 0; i < edgeObject.length; i++)
    {
        if (rectOnEdge(edgeObject[i].x, edgeObject[i].y)) {
            edgeObject[i].isInEdge = true;
        }
    }

    return edgeObject;
}

// Check overlap
export function rectOnEdge(x, y) {
    if (x < 24 || y < 24) {
        return true;
    }

    return false;
}

export function getTemplateAsBlob(json) {
    const blob = new Blob([JSON.stringify(json)], {
        type: 'application/json',
    });

    return blob;
}

export function sendTemplate(csrf, data) {
    var url = `${baseUrl}/upload-template`
    
    try {
        fetch(url, {
            method: 'POST',
            headers: {
                Accept: '*/*',
                'X-CSRF-TOKEN': csrf
            },
            body: data
        })
        .then(response => response.json())
        .then(response => {
            if (response.message === "goGenerate") {
                window.location.href = `${baseUrl}/print-generate`;
            } else {
                window.location.href = `${baseUrl}/step-four-back`;
            }
        });
    } catch (error) {
        console.log(error);
        return alert('Something Wrong !');
    }
}

export function checkAllCollisions(template, template_type, page_type) {
  
  let obj = template.schemas[0];
  let result = [];
  let msg = '';
  let error = [];
       //文字がはみ出ていないか
 
  Object.entries(obj).forEach(([key, val]) => {
    
      const dom = document.getElementById("pefme-text-" + val.name)
      if(dom){
        if(dom.clientHeight < dom.scrollHeight){
          error.push('ボックス内の文字が収まっていません。ボックスのサイズを確認してください。');
        }
        const a = {
              x : val.position.x,
              y : val.position.y,
              height : val.height,
              width : val.width,
         };
          
         result.push(a);
      }
      
    });


    var check = detectCollisions(result)
    var flag = check.find(x => x.isColliding === true);
    if (flag !== undefined) {
        error.push('ボックスが重なっています。ボックスの位置をか確認してください。');
    }

    //領域に入っているかチェック
    const layout = new Layout(template_type, page_type)
   // var checkEdge = edgeCollisions(result)
    //var edge = checkEdge.find(x => x.isInEdge === true);
    const edge = layout.checkEdge(result)
    if (!edge) {
        error.push('ボックスが印刷面の枠をはみ出しています。「編集モード」に切り替え、ボックスの位置をか確認してください。');
    }

    if (error.length !== 0) {
        error.forEach(el => {
            msg += el + '\n';
        });
    }

    return msg;
}



export function getSampleDataTemplate(data) {
    let obj = data.sampledata[0];
    let result = {};

    Object.entries(obj).forEach(([key, value]) => {
        if (key !== undefined) {
            result[key] = value;
        }
    });

    return [result];
}

export function addWaterMarkOnTemplate(template) {
    //template['columns'].push('watermark');
    /**template['schemas'][0]['watermark'] = {
        type: 'text',
        //fontName : 'ipag',
        //fontColor : '#F3F3F3',
        fontSize : 30,
        position : { x: 70, y: 130 },
       // rotate : 35,
        width : 150,
        height: 20,
    };**/
    
    return template;
}

export function addWaterMarkOnSchema(data) {

  data['watermark'] = 'あさひ高速印刷サンプルPDF';


  return data;
}

export function removeWaterMark(template, data = {}) {
    template['columns'].pop();
    template['sampledata'][0]['郵便番号'] = data['郵便番号'];
    template['sampledata'][0]['氏名'] = data['氏名'];
    template['sampledata'][0]['住所'] = data['住所'];
    template['sampledata'][0]['会社名'] = data['会社名'];
    delete template['sampledata'][0]['watermark'];
    delete template['schemas'][0]['watermark'];
}

export function logFormData(data) {
    
}