import React, {  useEffect } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import useAuthState from '../auth/useAuthState'

import UserMenu from '../menu/UserMenu'


function MailResetSuccessApp(props:PropsValue) {

  const {token, auth} = useAuthState()



  useEffect(() => {
    auth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);



  return(

        <div id="page" className="member register email-success"> 
          <Header token={token} />
          <Menu />
          <article id="contents">
            <UserMenu selectMenuItem="register" />
            <section className="s-contentsin2 topcomment">
              <h1 className="stitle">メールアドレス再設定</h1>
              <p>メールアドレスの再設定が完了しました</p>
            </section>
  


            </article>
          <Footer />
        </div>
  

  )
}

export default MailResetSuccessApp
