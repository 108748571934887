import { useState } from 'react';
import ProfileAction from './ProfileAction';
import Profile from './Profile';


function useProfileState() {

  const [item, setItem] = useState(null)
  const [error, setError] = useState(null)
  const [completed, setCompleted] = useState(null)
  const [updatedAt, setUPdatedAt] = useState(null)

  const loadedHandler = (data) => {
    setItem(new Profile(data.data))
  }
  const loadedFromCookieHandler = (data) => {
    setItem(new Profile(data))
  }

  const updatedHandler = (data) => {
    if(data.data.id ){
      setCompleted(true)
      setItem(data.data)
      setUPdatedAt(new Date())
    }
  }

  const mailUpdatedHandler = (data) => {
    if(data){
      setCompleted(true)
    }
  }

  const errorHandler = (data) => {
    //TODO 
    console.log("erro")
    setError(data)
  }

  const deletedHandler = (data) => {
  
    if(data.data){
      console.log(data.data)
      setCompleted(new Date())
    }
  }


  const get = (token) => {
    ProfileAction.get(token, loadedHandler)
  }
  

  const updateItem = (token, item) => {
    ProfileAction.update(token, item, updatedHandler, errorHandler)
  }
  const updateEmail = (token, email) => {
    ProfileAction.updateEmail(token, email, mailUpdatedHandler, errorHandler)
  }



  const save = (data) => {
    ProfileAction.saveOnCookie(data)
  }


  const deleteItem= (token,password) => {
      ProfileAction.delete(token, password, deletedHandler, errorHandler)
  }

  const loadFromCookie = () => {
    ProfileAction.loadFromCookie( loadedFromCookieHandler)
  }

  const removeFromCookie = () => {
    ProfileAction.removeFromCookie()
  }


  return { profile:item, profileError:error,  profileUpdatedAt:updatedAt, getProfile:get, deleteProfile:deleteItem, updateProfile:updateItem,createResult:completed, deleted:completed, updateEmail, saveProfileInfo:save, loadProfiletInfo:loadFromCookie, removeProfileInfo:removeFromCookie};
}

export default useProfileState