import { useState } from 'react';
import PostCodeAction from './PostcodeAction';


function usePostcodeState() {

  const [items, setItems] = useState(null)
  const [error, setError] = useState(null)



  const loadedHandler = (data) => {
    setItems(data.data.results)
  }

  const errorHander = (data) => {
    setError(data)
  }

  const get = (code) => {
    PostCodeAction.getAddressInfo( code,loadedHandler, errorHander)
  }
  


  return { addresses:items,  address_error: error, getAddressInfo:get};
}

export default usePostcodeState