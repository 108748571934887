
import { Record } from 'immutable'
import  RequestValue  from './RequestValue';

const Request = Record({
    id: null,
    specification: RequestValue.SPECIFICATION_V,
    paper: RequestValue.PAPER_MAT,
    thickness: RequestValue.THICKNESS_110,
    process_type: RequestValue.PROCESS_TYPE_NORMAL,
    processing: RequestValue.PROCESSING_ZURASHI,
    plan: null,
    sheet:null,
    csv_path: null,
    pdf_front_path: null,
    pdf_back_path: null,
    temp_front_path: null,
    temp_back_path: null,
    memo: null,
    csv:null,
    aggrement:null,
    shipping:RequestValue.DELIVERY_ME,
    payment_method:RequestValue.PAYMENT_METHOD_CASHON
});
export default Request;


