import React, { } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'


import "../css/member.css"
function ProfileDeleteCompleteApp(props:PropsValue) {


  return(

        <div id="page" className="member withdrawal completed"> 
          <Header token={null} />
          <Menu />
          <article id="contents">
        
            <section className="s-contentsin2 topcomment">
              <h1 className="stitle">会員登録/会員情報変更</h1>
              <h2 className="stitle3">会員退会完了</h2>
              <p>この度は、「あさひ高速印刷オンラインショップ　DM圧着ハガキDM圧着印刷」をご利用いただき、<br class="brpc" />誠にありがとうございました。<br/>
		          またのご利用を心よりお待ちしております。</p>
            </section>
            </article>
          <Footer />
        </div>
  

  )
}

export default ProfileDeleteCompleteApp
