
import 'whatwg-fetch'
import Error from './Error';

const Action = {


  fetchAuthorizedJson( path, token, method, header, body=null,  callbackHandler = null, errorHandler = null, response_data = {}){

    if(!token) {
      Error.authError()
      return false
    }

    header.Authorization =  'Bearer ' + token

    fetch(path  , {
      method: method,
      headers: header,
      body: body ? body : null
    }).then(function(response) {
     
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
  
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return {"error":1, "data":response.json()}
         } else {
           return {"error":response.status}
        }
        
      }else{
        return {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error && callbackHandler){
        response_data.data = data
        callbackHandler(response_data)
      }else{
        if(data && data.error === 1){
          //message有の場合
          data.data.then(function(error){
            errorHandler(error)
          })
          //
          
        }else if(data && data.error === 403){
          console.log("auth error")
          Error.authError()
          
        }else if(errorHandler){
          errorHandler(data)
        }
      }
    })
  },

  fetchJson( path, method, header, body=null,  callbackHandler = null, errorHandler = null, response_data = {}){


    fetch(path  , {
      method: method,
      headers: header,
      body: body ? body : null
    }).then(function(response) {
     
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");

        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return {"error":1, "data":response.json()}
         } else {
           return {"error":response.status}
        }
        
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {

      if(data && !data.error && callbackHandler){
        response_data.data = data
        callbackHandler(response_data)
      }else{

        if(data && data.error === 1){
          //message有の場合
          data.data.then(function(error){
            errorHandler(error)
          })
          
        }else if(errorHandler){
          errorHandler(data)
        }
      }
    })
  },

  fetchText( path, method, header, body=null,  callbackHandler = null, errorHandler = null, response_data = {}){


    fetch(path  , {
      method: method,
      headers: header,
      body: body ? body : null
    }).then(function(response) {
     
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("text/plain;charset=utf-8") !== -1) {

           return response.body.getReader()
         } else {
           return {"error":response.status}
        }
      }else{
        return {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error && callbackHandler){
        let response = ''; // 細切れの値をここに結合していく。
        const decoder = new TextDecoder();
        function readChunk({done, value}) {
          if(done) {
            response_data.data = JSON.parse(response)
            callbackHandler(response_data)
            return;
          }
    
          response += decoder.decode(value);
    
          // 次の値を読みにいく。
          data.read().then(readChunk);
        }
        
        // 最初の値を読み込む。
        data.read().then(readChunk);
      }else{
        if(data && data.error === 1){
          //message有の場合
          data.data.then(function(error){
            errorHandler(error)
          })
          
        }else if(errorHandler){
          errorHandler(data)
        }
      }
    })
  },

  fetchAuthorizedDelete( path, token,  header, callbackHandler = null, errorHandler = null, response_data = {}){

    if(!token) {
      Error.authError()
      return false
    }

    header.Authorization =  'Bearer ' + token

    fetch(path  , {
      method: "DELETE",
      headers: header
    }).then(function(response) {
      
      if(response.status === 204  ){
        return {"status":1}
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error && callbackHandler){
        response_data.data = data
        callbackHandler(response_data)
      }else{
        if(data && data.error === 403){
          Error.authError()
        }
        if(errorHandler){
          errorHandler(data)
        }
      }
    })
  }
 
}

export default Action
