
import { Record } from 'immutable'


const AdditionalPlacement = Record({
  
  column_name: null,
  placement: null,
  qr_code: 0
});
export default AdditionalPlacement;