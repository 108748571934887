
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'

const LoginAction = {


  login( item, callback, errorHandler){
  
    const data = {
      grant_type:"password",
      client_id:config.get("CLIENT_ID"),
      client_secret:config.get("CLIENT_SECRET"),
      username:item.email,
      password:item.password,
      scope:"*"
    }



    fetch(config.get("API_PATH") + "/oauth/token"  , {
      method: "POST",
      headers: {
       //'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(function(response) {
      
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        return {"error":401}
      }
    }).then(function(data) {
  
      if(data.access_token){
        cookie.save('token', data.access_token, { path: '/' })
        cookie.save('refresh_token', data.refresh_token, { path: '/' })
        callback(data)
      }else{
        cookie.remove('token',{ path: '/' })
        cookie.remove('refresh_token',{ path: '/' })
        errorHandler({error:1})
      }
    })
  },



 
}

export default LoginAction
