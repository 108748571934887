
import { Record } from 'immutable'
const Profile = Record({
    id: null,
    email: null,
    member_no: null,
    company_name: null,
    name: null,
    name_kana: null,
    tel: null,
    post1: null,
    post2: null,
    prefecture_id: null,
    address: null,
    address_add: null,
    email_verified_at: null,
    main_member_at: null,
    created_at: null,
    updated_at:null,
    deleted_at: null
});
export default Profile;
