
import 'whatwg-fetch'

import CsvRow from './CsvRow';
import CsvRowItem from './CsvRowItem';
//import RequestValue from '../RequestValue';

const GetCsvRowItem = {


  getLongestItem( item){
    if(!item) return null
 
    const items = item

    const _name_length = items.map(_item => {
      
      return (GetCsvRowItem.getNameString(_item)).length
    })
    const _name_item = GetCsvRowItem.getItem(items, _name_length)
    const _name = GetCsvRowItem.getNameString(_name_item)

    const _zip_length = items.map(item => (item[1]).length)
    const _zip_item = GetCsvRowItem.getItem(items, _zip_length)
    const _zip = (_zip_item[1])


    const _address_length = items.map(item => (GetCsvRowItem.getAddress(item)).length)
    const _address_item = GetCsvRowItem.getItem(items, _address_length)
    const _address = (GetCsvRowItem.getAddress(_address_item))

    const _company_name_length = items.map(item => (item[6]).length)
    const _company_name_item = GetCsvRowItem.getItem(items, _company_name_length)
    const _company_name = (_company_name_item[6])

    var row = new CsvRow({
      name:new CsvRowItem({value:_name}),
      zip:new CsvRowItem({value:_zip}),
      address:new CsvRowItem({value:_address}),
      company_name:new CsvRowItem({value:_company_name}),
    })

   // if(item.plan === RequestValue.PLAN_ATENA_NAKA){
      //if(item.additional_placements.length > 0 ){
        const _add_1_data = (GetCsvRowItem.getAdditionalItem(item,1))
        const _add_1_length = items.map(item =>  _add_1_data ?  _add_1_data.length : 0)
        const _add_1_item = GetCsvRowItem.getItem(items, _add_1_length)
        const _add_1 = (GetCsvRowItem.getAdditionalItem(_add_1_item,1))
       if(_add_1){
        row = row.set("additional_1",new CsvRowItem({value:_add_1})) 
       }
       
     // } 

//if(item.additional_placements.length > 1 ){
        const _add_2_data =  (GetCsvRowItem.getAdditionalItem(item,2)) 
        const _add_2_length = items.map(item => _add_2_data ? _add_2_data.length : 0)
        const _add_2_item = GetCsvRowItem.getItem(items, _add_2_length)
        const _add_2 = (GetCsvRowItem.getAdditionalItem(_add_2_item,2))
        if(_add_2){
          row = row.set("additional_2", new CsvRowItem({value:_add_2})) 
        }
        
     // } 

    //  if(item.additional_placements.length > 2 ){
      const _add_3_data =  (GetCsvRowItem.getAdditionalItem(item,3))
      const _add_3_length = items.map(item => _add_3_data ? _add_3_data.length : 0)
      const _add_3_item = GetCsvRowItem.getItem(items, _add_3_length)
      const _add_3 = (GetCsvRowItem.getAdditionalItem(_add_3_item,3))
      if(_add_3){
        row = row.set("additional_3", new CsvRowItem({value:_add_3})) 
      }
      
     // } 
  // }



    return row
  },


  getPageItem( item, row){
  
    const _name = GetCsvRowItem.getNameString(row)
    const _zip = (row[1])
    const _address = (GetCsvRowItem.getAddress(row))
    const _company_name = (row[6])


    var _row = new CsvRow({
      name:new CsvRowItem({value:_name}),
      zip:new CsvRowItem({value:_zip}),
      address:new CsvRowItem({value:_address}),
      company_name:new CsvRowItem({value:_company_name}),
    })

    if(row.length > 9 ){
     const _add_1 = (GetCsvRowItem.getAdditionalItem(row,1))
     if(_add_1){
      _row = _row.set("additional_1",new CsvRowItem({value:_add_1})) 
     }
    
    } 

    if(row.length > 10 ){
      const _add_2 = (GetCsvRowItem.getAdditionalItem(row,2))
      if(_add_2 ){
        _row = _row.set("additional_2", new CsvRowItem({value:_add_2})) 
      }
      
    } 

    if(row.length > 11){
      const _add_3 = (GetCsvRowItem.getAdditionalItem(row,3))
      if(_add_3){
        _row = _row.set("additional_3", new CsvRowItem({value:_add_3})) 
      }
      
    }

    return _row
  },


  getSampleData(item, sche) {
   
      var _sample = {}  
 
     if(sche.郵便番号1){
      var _zip =  item.zip.value
      /**if(longest_data && longest_data.zip){
        while(_zip.length < longest_data.zip.value.length){
          _zip += "＿"
        }
      }**/
       _sample.郵便番号1 = _zip.substr(0,3)
       _sample.郵便番号2 = _zip.substr(4,7)
     }


     if(sche.氏名){
      var _name =  item.name.value 
      /**if(longest_data && longest_data.name){
        while(_name.length < longest_data.name.value.length){
          _name += "＿"
        }
      }**/

       _sample.氏名 = _name
     }
     
     
     if(sche.住所){
      var _address =   item.address.value 
      /**if(longest_data && longest_data.address){
        while(_address.length < longest_data.address.value.length){
          _address  += "＿"
        }
      }**/
       _sample.住所 = _address
     }


     if(sche.会社名){
      var _company_name =  item.company_name.value
      /**if(longest_data && longest_data.company_name){
        while(_company_name.length < longest_data.company_name.value.length){
          _company_name += "＿"
        }
      }**/
       _sample.会社名 = _company_name
     }
     
   
     //if(item.plan === RequestValue.PLAN_ATENA_NAKA){
      if(sche.可変項目1){
        var _additional_1 =  item.additional_1.value
       /**if(longest_data && longest_data.additional_1){
          while(_additional_1.length < longest_data.additional_1.value.length){
            _additional_1 += "＿"
          }
        }**/
        _sample.可変項目1 = _additional_1
      }
      if(sche.可変項目2){
        var _additional_2 =  item.additional_2.value
        /**if(longest_data && longest_data.additional_2){
          while(_additional_2.length < longest_data.additional_2.value.length){
            _additional_2 += "＿"
          }
        }**/
        _sample.可変項目2 = _additional_2 
     }
      if(sche.可変項目3){
        var _additional_3 =  item.additional_3.value
        /**if(longest_data && longest_data.additional_3){
          while(_additional_3.length < longest_data.additional_3.value.length){
            _additional_3 += "＿"
          }
        }**/
        _sample.可変項目3 = _additional_3
      }
    //}
 
     
     return _sample
  },


  getSampleDataWithLongest(item, sche, longest_data) {
   
    var _sample = {}  


   if(sche.郵便番号1){
    var _zip =  item.zip.value
    /**if(longest_data && longest_data.zip){
      while(_zip.length < longest_data.zip.value.length){
        _zip += "＿"
      }
    }**/
     _sample.郵便番号1 = _zip.substr(0,3)
     _sample.郵便番号2 = _zip.substr(4,7)
   }


   if(sche.氏名){
    var _name =  item.name.value + "|" + longest_data.name.value
    /**if(longest_data && longest_data.name){
      while(_name.length < longest_data.name.value.length){
        _name += "＿"
      }
    }**/

     _sample.氏名 = _name
   }
   
   
   if(sche.住所){
    var _address =   item.address.value + "|" + longest_data.address.value 
    /**if(longest_data && longest_data.address){
      while(_address.length < longest_data.address.value.length){
        _address  += "＿"
      }
    }**/
     _sample.住所 = _address
   }


   if(sche.会社名){
    var _company_name =  item.company_name.value + "|" + longest_data.company_name.value 
    /**if(longest_data && longest_data.company_name){
      while(_company_name.length < longest_data.company_name.value.length){
        _company_name += "＿"
      }
    }**/
     _sample.会社名 = _company_name
   }
   
 
   //if(item.plan === RequestValue.PLAN_ATENA_NAKA){
    if(sche.可変項目1){
      var _additional_1 =  item.additional_1.value + "|" + longest_data.additional_1.value 
     /**if(longest_data && longest_data.additional_1){
        while(_additional_1.length < longest_data.additional_1.value.length){
          _additional_1 += "＿"
        }
      }**/
      _sample.可変項目1 = _additional_1
    }
    if(sche.可変項目2){
      var _additional_2 =  item.additional_2.value + "|" + longest_data.additional_2.value 
      /**if(longest_data && longest_data.additional_2){
        while(_additional_2.length < longest_data.additional_2.value.length){
          _additional_2 += "＿"
        }
      }**/
      _sample.可変項目2 = _additional_2 
   }
    if(sche.可変項目3){
      var _additional_3 =  item.additional_3.value + "|" + longest_data.additional_3.value 
      /**if(longest_data && longest_data.additional_3){ 
        while(_additional_3.length < longest_data.additional_3.value.length){
          _additional_3 += "＿"
        }
      }**/
      _sample.可変項目3 = _additional_3
    }
  //}

   
   return _sample
},

  getFullDate(item) {
    var _sample = {}
   
   if(item.zip){
     _sample.郵便番号1 =  item.zip.value.substr(0,3)
     _sample.郵便番号2 =  item.zip.value.substr(4,7)
   }
   if(item.name){
     _sample.氏名 = item.name.value
   }
   if(item.address){
     _sample.住所 = item.address.value
   }
   if(item.company_name){
     _sample.会社名 = item.company_name.value
   }

   console.log(item.additional_3)
   // if(item.plan === RequestValue.PLAN_ATENA_NAKA){
      if(item.additional_1 && item.additional_1.value !== ""){
        _sample.可変項目1 = item.additional_1.value
      }
      if(item.additional_2 && item.additional_2.value !== ""){
        _sample.可変項目2 = item.additional_2.value
      }
      if(item.additional_3 && item.additional_3.value !== ""){
        _sample.可変項目3 = item.additional_3.value
      }
    //}
   return _sample
},

  getItem(items, length_list){
    const _index = length_list.indexOf(Math.max(...length_list));
    return  items[_index]
  },

  getNameString(row){

    return row[7] + row[8] + row[9]
  },

  getAddress(row){
    return row[2] + row[3] + row[4] + row[5]
  },

  getAdditionalItem(row, num){
    
    const before_num = 9
    if(row.length <= before_num + num ) return null
    var value =  row[before_num + num]  
    if(value.length === 0) return null
    return value
  },

 
 
}

export default GetCsvRowItem
