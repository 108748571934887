import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import config from 'react-global-configuration';
import configuration from './config.js';
import HomeApp from './static/HomeApp.js';
import RegisterApp from './auth/register/RegisterApp.js';
import RegisterConfirmApp from './auth/register/RegisterConfirmApp.js';
import RegisterCompleteApp from './auth/register/RegisterCompleteApp.js';
import LoginApp from './auth/login/LoginApp.js';
import PasswordResetCodeApp from './auth/password/PasswordResetCodeApp.js';
import PasswordResetMailSuccessApp from './auth/password/PasswordResetMailSuccessApp.js';
import PasswordResetApp from './auth/password/PasswordResetApp.js';
import PasswordResetSuccessApp from './auth/password/PasswordResetSuccessApp.js';

import ProfileApp from './profile/ProfileApp.js';
import ProfileConfirmApp from './profile/ProfileConfirmApp.js';
import ProfileDeleteApp from './profile/ProfileDeleteApp.js';
import ProfileDeleteCompleteApp from './profile/ProfileDeleteCompleteApp.js';
import PrintDetailApp from './order/print_detail/PrintDetailApp.js';
import ConfirmDetailApp from './order/confirm_detail/ConfirmDetailApp.js';
import EditLayoutApp from './order/edit_layout/EditLayoutApp.js';
import EditElementApp from './order/edit_layout/EditElementApp.js';
import GeneratePdfApp from './order/generateSamplePdf/GeneratePdfApp.js';
import OrderDetailApp from './order/order_detail/OrderDetailApp.js';
import ConfirmOrderDetailApp from './order/confirm_order/ConfirmOrderDetailApp.js';
import SuccessOrderApp from './order/success/SuccessOrderApp.js';
import SendingApp from './sending/SendingApp.js'
import HistoryApp from "./sending/HistoryApp"
import DeliveryApp from './delivery/DeliveryApp.js';
import InqueryApp from "./inquiry/InqueryApp"
import InqueryConfirmApp from './inquiry/InqueryConfirmApp.js';
import InquerySuccessApp from './inquiry/InquerySuccessApp.js';
import MailResetApp from './profile/MailResetApp.js';
import MailResetSuccessApp from './profile/MailResetSuccessApp.js';
import PasswordResetUserApp from './profile/PasswordResetApp.js';
import PasswordResetUserSuccessApp from './profile/PasswordResetSuccessApp.js';
import HowtoApp from './static/HowtoApp.js';
import CompanyApp from './static/CompanyApp.js';
import DeliveryStaticApp from './static/DeliveryApp.js';
import HowtoOrderApp from './static/HowtoOrderApp.js';
import FAQApp from './static/FAQApp.js';
import SitemapApp from './static/SitemapApp.js';
import LawApp from './static/PrivacyApp.js';
import PrivacyApp from './static/LawApp.js';
config.set(configuration);

class App extends React.Component{

render(){
return(
    <div>
      <Routes>
        <Route  path='/' element={<HomeApp />} />
        <Route  path='/howto' element={<HowtoApp />} />
        <Route  path='/delivery' element={<DeliveryStaticApp />} />
        <Route  path='/order' element={<HowtoOrderApp />} />
        <Route  path='/faq' element={<FAQApp />} />
        <Route  path='/company' element={<CompanyApp />} />
        <Route  path='/inquiry' element={<InqueryApp />} />
        <Route  path='/sitemap' element={<SitemapApp />} />
        <Route  path='/law' element={<LawApp />} />
        <Route  path='/law/' element={<LawApp />} />
        <Route  path='/privacy' element={<PrivacyApp />} />

        <Route  path='/register' element={<RegisterApp />} />
        <Route  path='/register/confirm' element={<RegisterConfirmApp />} />
        <Route  path='/register/complete' element={<RegisterCompleteApp />} />
        <Route  path='/password/reset' element={<PasswordResetCodeApp />} />
        <Route  path='/password/reset/sent' element={<PasswordResetMailSuccessApp />} />
        <Route  path='/password/reset/mail' element={<PasswordResetApp />} />
        <Route  path='/password/reset/updated' element={<PasswordResetSuccessApp />} />
        <Route  path='/login' element={<LoginApp />} />
       
        
        <Route  path='/mypage' element={<ProfileApp />} />
        <Route  path='/mypage/confirm' element={<ProfileConfirmApp />} />
        <Route  path='/mypage/profile/delete' element={<ProfileDeleteApp />} />
        <Route  path='/mypage/profile/delete/complete' element={<ProfileDeleteCompleteApp />} />
        <Route  path='/mypage/password/reset' element={<PasswordResetCodeApp />} />
        <Route  path='/mypage/password/updated' element={<PasswordResetSuccessApp />} />

        <Route  path='/inquiry' element={<InqueryApp />} />
        <Route  path='/inquiry/confirm' element={<InqueryConfirmApp />} />
        <Route  path='/inquiry/success' element={<InquerySuccessApp />} />
        
        <Route  path='/order/step1' element={<PrintDetailApp />} />
        <Route  path='/order/step2' element={<ConfirmDetailApp />} />
        <Route  path='/order/step3' element={<EditLayoutApp />} />
        <Route  path='/order/step4/:type' element={<EditElementApp />} />
        <Route  path='/order/step6' element={<GeneratePdfApp />} />
        <Route  path='/order/step6/:id' element={<GeneratePdfApp />} />
        <Route  path='/order/step7' element={<OrderDetailApp />} />
        <Route  path='/order/step8' element={<ConfirmOrderDetailApp />} />
        <Route  path='/order/success' element={<SuccessOrderApp />} />

        <Route  path='/sending' element={<SendingApp />} />
        <Route  path='/order-history' element={<HistoryApp />} />
        <Route  path='/profile/email/reset' element={<MailResetApp />} />
        <Route  path='/profile/email/success' element={<MailResetSuccessApp />} />

        <Route  path='/profile/password/reset' element={<PasswordResetUserApp />} />
        <Route  path='/profile/password/success' element={<PasswordResetUserSuccessApp />} />

        <Route  path='/profile/delivery' element={<DeliveryApp />} />
      </Routes> 
      
    </div>
)}}

const container = document.getElementById('root')
const root = createRoot(container)



root.render((<BrowserRouter ><App /></BrowserRouter>))

