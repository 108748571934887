import React, { useState, useEffect } from 'react'
import Request from '../Request';
import useRequestState from '../print_detail/useRequestState';
import useCartState from '../cart/useCartState';
import DeliveryInfoTable from '../../delivery/DeliveryInfoTable';
import usePrefectreState from '../../prefectre/usePrefectreState';
import useOrderState from '../useOrderState';

function OrderForm(props:PropsValue) {
  
  const [item, setItem] = useState(new Request())
  const [price, setTotalPrice] = useState(null)
  const [done, setDone] = useState(false)
  const {prefectures,getPrefectures} = usePrefectreState()
  const {total_price,  delivery_price,  getTotalPrice} = useRequestState()
  const {cart, cart_updated_time} = useCartState()
  const {order,  order_created_at, pdf_created_at, createOrder,createPdf} = useOrderState()

  useEffect(() => {
    if(props.item && props.item.id)
      setItem(props.item)
      getTotalPrice(props.token, props.item, props.item.delivery.prefecture_id, props.item.thickness)
      getPrefectures()
       // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.item]);  
  
  useEffect(() => {

    if(pdf_created_at){
      props.moveToNextStep("success")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pdf_created_at]);



  useEffect(() => {

    if(cart_updated_time){
 
      props.moveToNextStep(cart)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cart_updated_time]);

  useEffect(() => {   

    if(total_price && delivery_price){
      setTotalPrice(total_price + delivery_price)  
    }else{
      setTotalPrice(null)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[total_price,delivery_price]); 




  useEffect(() => {   
    
    if(done){
      createOrder(props.token,item.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[done]); 


  useEffect(() => {
    if((order && order.id ) || order_created_at){
      
      createPdf(props.token,order.id)
      window.setTimeout(function(){
        props.moveToNextStep("success")
      }, 3000)
      
    }
       // eslint-disable-next-line react-hooks/exhaustive-deps
  },[order, order_created_at]);

  const moveTo = () => {
    setDone(true)
    
  }

  const moveToStep= (step) => {
    if(step)
      window.location.href = "/order/" + step
  }


  return(
      <div>
        <section className="s-contentsin2 section form">
            <h2 className="titlebox mb-p0">
                <div className="titleboxin">ご注文フォーム</div>
            </h2>
    
            <table className="form-table">
                <tr className="">
                    <th>
                        <span className="ls10">仕様</span>
                    </th>
                    <td>
                        <div className="list specification">
                            {
                              item.specification 

                            }
                            
                            
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>
                        <span className="ls10">用紙</span>
                    </th>
                    <td>
                        <div className="list">
                            <div>
                                <span>
                                    {item.paper }
                                </span>
                            </div>
                            
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>
                        用紙の厚み
                    </th>
                    <td>
                    < div className="list">
                            <div>
                                <span>
                                    {item.thickness }
                                </span>
                            </div>
                            
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>
                        加工方法
                    </th>
                    <td>
                        <div className="list">
                            <div>
                                <span>
                                   {item.processing}
                                </span>
                            </div>
                        </div>
                      </td>
                </tr>

                <tr>
                    <th>
                        圧着方法
                    </th>
                    <td>
                        <div className="list">
                            <div>
                                <span>
                                    {item.process_type}
                                </span>
                            </div>
                        </div>
                      </td>
                </tr>

                <tr className="">
                    <th>
                        印刷プラン
                    </th>
                    <td data-trigger="plan">
                        <div className="input-button-plan">
                            {
                                item.plan 

                            }
                        </div>
                    </td>
                </tr>

              
                <tr className="">
                    <th>
                        <span className="ls10">枚数</span>
                    </th>
                    <td>
                        <div className="textbox">
                            <div>
                                
                               {item.sheet ? item.sheet.toLocaleString() : null}　枚
                            </div>
                            
                        </div>
                    </td>
                </tr>

                <tr className="">
                    <th>
                        備考欄
                    </th>
                    <td>
                        <div className="textbox">
                            <div>
                                
                            {item.memo}
                            </div>
                            
                        </div>
                    </td>
                </tr>

               </table>
        </section>
    
      

        <section id="memo" className="s-contentsin2 section">
            <h2 className="titlebox">
                <div className="titleboxin">配送方法</div>
            </h2>
            <div className="box">
              
        {
          item && item.delivery ?                      
            <DeliveryInfoTable item={item.delivery} prefectures={prefectures} />
           : null }
            </div>
        </section>
        

        <section className="s-contentsin2 section">
        <h2 className="titlebox">
          <div className="titleboxin">商品のお届け・返品など</div>
        </h2>

        <table className="form-table address-table">
          <tr>
            <th>
              <span className="thname">商品のお届け時期</span>
            </th>
            <td>
              ご注文完了より7日～14日前後<br/>
              ※一部地域・離島を除く。<br/>
              ※繁忙期は受注状況によって通常よりお時間をいただく場合がございます。
            </td>
          </tr>
          <tr>
            <th>
              <span className="thname">返品・交換・不良品<br/>について</span>
            </th>
            <td>
              完全受注生産のため、一度注文されました商品は、返品・交換・キャンセルはできません。<br/>
              ただし、商品にあきらかな不備があった場合・ご注文いただきました商品と違う商品が届いた場合に限り、商品の交換を承ります。<br/>
              交換の場合は、商品到着後7日以内に下記内容を必ずご記入の上、「お問い合わせ」よりご連絡くださいますようお願い申し上げます。<br/>
              <br/>
              　1．お名前<br/>
              　2．メールアドレス<br/>
              　3．注文番号<br/>
              　4．交換理由
            </td>
          </tr>
        </table>
      </section>


        <section className="s-contentsin2 section">
            <h2 className="titlebox">
                <div className="titleboxin">決済方法</div>
            </h2>
    
            <div className="box payment">
                <div className="list">
                    {item.payment_method}
                </div>
            </div>
        </section>


        

        <section id="last-step" className="contents">
    
        <section className="s-contentsin2 tbox">
            <p>料金をご確認いただき問題なければ、ご同意の上、決済へお進みください。</p>
            <div className="totalprice">
                <div>
                    <span className="total">合計金額</span>
                    <span id="priceLabel" className="price">{price ? price.toLocaleString(): null}</span>
                </div>
            </div>

            <div className="box">
                <div className="l">
                    <div className="button forward">
                      
                        <div onClick={() => moveToStep("step7")} >
                            <span>注文内容へ</span>
                        </div>
                    </div>
                </div>
                <div className="r">
                    <div className="button next">{done ? 
                        <div>保存中...</div> : 
                        <div onClick={() => moveTo()}>
                            <span>{item.payment_method === "代金引換" ? "完了" : "決済へ"}</span>
                        </div>
                       }
                    </div>
                </div>
            </div>
        </section>
        </section>


        </div>

  )
}

export default OrderForm
