import React, {   } from 'react'
function SendingDeleteOrderModalContent(props) {

  return (
    <div id="openwindowfloat">
      <div id="contentsbox" className="contentsin">
        <div id="closebox" onClick={() => props.onClose()}>
          <p>閉じる</p>
          <div id="window-toggle">
            <div>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>

        <div className="topcomment">
				  <h1>入稿データの削除</h1>
          <p>以下の入稿データの削除を実行してもよろしいですか？</p>
          <p>{props.item.filename ? props.item.filename : props.item.data_name}</p>
        </div>

        <div className="button-area">
          <div className="buttonbox">
            <button type="button" className="btn-red" onClick={(e) => { e.preventDefault(); props.onDelete(props.item)}}>削除</button>
          </div>
        </div>
      </div>
      <div id="window-bg" onClick={() => props.onClose()}></div>
    </div>
  )
}

export default SendingDeleteOrderModalContent
