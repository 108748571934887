import { useState } from 'react';
import AdditionalPlacementAction from './AdditionalPlacementAction';



function useAdditionalPlacementState() {

  const [additional_placemnets, setAdditionalPlacements] = useState(null)


  
  const additionalPlacementChangeHandler = (data) => {
    setAdditionalPlacements(data)
  }

  const errorHandler = (data) => {
    console.log(data)
  }
  /**
  const getCurrentId =  () => {
    CartAction.loadFromCookie(currentCartIdLoadHandler)
  }
  
  const get = (token, id) => {
    CartAction.get(token, id, cartChangeHandler)
  } 
     */
  const create = (token, cart_id, item) => {
    AdditionalPlacementAction.create(token, cart_id, item, additionalPlacementChangeHandler, errorHandler)
  } 



  return { additional_placemnets:additional_placemnets,  createAdditionalItems:create};
}

export default useAdditionalPlacementState