import React from 'react';
import  Designer  from './Designer';
import {  DesignerProps,Size } from '@spursjp/pdfme-common';


class DesignerV extends Designer {
  

  override bgSize?:Size;
  override tonboSize?:Size
  override specification:string

  constructor(props: DesignerProps) {
    super(props);
    this.bgSize = {width:949, height:752}
    this.tonboSize = {width:104,height:95}
    this.specification = "v"
    
  }

  public setSpecification(s:string){
    this.specification = s
  } 

 
}

export default DesignerV;
