import React, { useEffect, useState } from 'react'

import DeliveryInfoTable from './DeliveryInfoTable'
import DeliveryDetail from './DeliveryDetail'
/**
 * 

 * @returns 
 */

function DeliveryListItem(props:PropsValue) {

  const [item, setItem] = useState(props.item)

  useEffect(() => {

    setItem(props.item)
  },[props.item]);


  const deleteItem = (item) => {
    props.delete(item)
  }

  
  return(
    props.edit ? 
      <DeliveryDetail item={item} deleteItem={deleteItem} submit={props.submit} delete={true} list={true} prefectures={props.prefectures} />
    :
      <DeliveryInfoTable item={item} deleteItem={deleteItem} delete={true} list={true} prefectures={props.prefectures} edit={true} />
  )
}
export default DeliveryListItem
