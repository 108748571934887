import { useState } from 'react';


function useSampleState() {

  const [current_page, setCurrentPage] = useState(null)



  const set = (page_num) => {
    setCurrentPage(page_num)
  }

  return { current_page:current_page, setPage:set};
}

export default useSampleState