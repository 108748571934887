import React, {  useEffect } from 'react'
import Status from './Status';
import useProfileState from './useProfileState'

import ProfileDetail from './ProfileDetail';
function ProfileContent(props:PropsValue) {





  const {profile, getProfile, saveProfileInfo} = useProfileState()

  useEffect(() => {
    if(props.token){
      getProfile(props.token)
    }
       // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.token]);


  useEffect(() => {

  },[profile]);




  const confirm = (item) => {
    saveProfileInfo(item)
    window.location.href = "/mypage/confirm"
  } 

  return(
    
    <div>
      <Status profile={profile} />
      <ProfileDetail profile={profile} confirm={confirm}/>
      <section className="s-contentsin2 withdrawal">
        <div className="link">
          <a href="/mypage/profile/delete"><span>退会をご希望の方はこちら</span></a>
        </div>
      </section>
    </div>

  )
}

export default ProfileContent
