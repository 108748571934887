import { useState,useEffect } from 'react';
import PrefectreAction from './PrefectreAction';


function usePrefectreState() {

  const [items, setItems] = useState([])

  useEffect(() => {
    get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const loadedHandler = (data) => {
    setItems(data)
  }


  const get = () => {
    PrefectreAction.get( loadedHandler)
  }
  
  const getName = (id) => {

    const pref = items.find(item => Number(item.id) === Number(id))
    if(!pref) return null
   return pref.prefecture_name
  }

  const getId = (name) => {
    const pref = items.find(item => item.prefecture_name === name)
   return pref.id
  }



  return { prefectures:items,  getPrefectures:get, getPrefectureId:getId, getPrefectureName:getName};
}

export default usePrefectreState