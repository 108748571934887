import React, {  useEffect, useState } from 'react'
import Header from '../../header/HeaderForStep'
import useCartState from '../cart/useCartState'
import useAuthState from '../../auth/useAuthState'

import step_img from "../../images/step/step-step3.png"
import question_img from "../../images/common/question.png"
import PdfUploader from './PdfUploader'
import RequestValue from '../RequestValue'
import LayoutConfirmWindow from './LayoutConfirmWindow'


function EditLayoutApp(props:PropsValue) {
  const url = window.URL || window.webkitURL;
  var _is_loading = false
  const [confirm, setConfirmWindow] = useState(null)

  const {token, auth} = useAuthState()
  const {cart, current_cart_id, getCart, getCurrentCartId} = useCartState()
  const [is_checked, setChecked] = useState(false)
  useEffect(() => {
    auth()
    getCurrentCartId()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    getCurrentCart()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[current_cart_id]);

  useEffect(() => {
    getCurrentCart()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);

  useEffect(() => {

  },[cart ]);

  const getCurrentCart = () =>{
    if(token && current_cart_id  && !_is_loading) {
      _is_loading = true
      getCart(token, current_cart_id)
    }
  }


  const updatedPdfHandler = () => {
    getCurrentCart()
  }


  const moveTo = (step) => {
    if(step)
      window.location.href = "/order/" + step
  }

  const moveToWithCheck = (step) => {
    if(!step || getNextStepBtnDisalbed() ) return false
    window.location.href = "/order/" + step
  }


  const closeConfirm = () => {
    setConfirmWindow(null)
  }
  const displayConfirm = () => {
    const disable = getNextBtnDisable()
    if(disable) return null
    setChecked(true)
    window.scrollTo(0, 0);
    setConfirmWindow(<LayoutConfirmWindow item={cart} url={url} token={token} close={closeConfirm} moveTo={moveTo} layoutUrl={getLayoutBtnLink()} nextPage={getNextStepBtnLink()}/>)
  }

  const getLayoutBtnClass = () => {
    return cart && cart.pdf_front_path && cart.pdf_back_path ? "btn btn-light button next  rightarrow-small l " : "btn btn-light button next  rightarrow-small l  off"
  }


  const getLayoutBtnLink = () => {
    return cart && cart.pdf_front_path && cart.pdf_back_path ? "step4/1 " : null
  }


  const getNextBtnClass = () => {
    return cart &&  ((cart.plan === RequestValue.PLAN_NO_ATENA && cart.pdf_front_path && cart.pdf_back_path ) || (cart.temp_front_path && cart.temp_back_path ))  ? "button rightarrow r " : "button rightarrow r off"
  }

  const getNextBtnDisable = (disabled) => {
   
    return cart &&   ((cart.plan === RequestValue.PLAN_NO_ATENA && cart.pdf_front_path && cart.pdf_back_path ) || (cart.temp_front_path && cart.temp_back_path) )  ? false : true
  }

  const getNextStepBtnClass = () => {
    return cart  && (is_checked ) ? "button next" : "button next off"
  }
  const getNextStepBtnDisalbed = () => {
    return cart  && (is_checked ) ? false : true
  }

  const getNextStepBtnLink = () => {
    return cart && cart.pdf_front_path && cart.pdf_back_path && cart.plan !== RequestValue.PLAN_NO_ATENA? "step6" : "step7"
  }

  const getNextStepBtnLabel = () => {
    return cart && cart.pdf_front_path && cart.pdf_back_path && cart.plan !== RequestValue.PLAN_NO_ATENA? "最終印刷へ" : "注文内容確認"
  }

  const getBackStepBtnLink = () => {
    return cart && cart.plan === RequestValue.PLAN_NO_ATENA ? "step1" : "step2"
  }
  

  return(

        <div id="step" className="step step3"> 
          <Header token={token} />
          <article id="contents">

              {confirm}
              
              <section className="contentsin now-step">
                <img src={step_img} alt="レイアウトを編集してください" />
              </section>
             
              <section className="s-contentsin topcomment">
              <h1>レイアウトを編集してください</h1>
              {
                cart && cart.plan !== RequestValue.PLAN_NO_ATENA ? 
                <ol>
                  <li>表面、裏面の背景画像（PDF）<a href="/#"><img src={question_img} alt="" /></a> をアップしてください</li>
                  <li>背景画像（PDF）をアップしましたら、「レイアウト調整」ボタンを押して、宛名の位置を調整してください。</li>
                  <li>位置調整が完了しましたら、「レイアウトイメージ確認」ボタンを押して、配置に問題がないかを確認してください。</li>
                  <li>&#10102;、&#10103;、&#10104;が完了しましたら、「最終印刷」へお進みください。</li>
                </ol>
                : cart ? 
                <ol>
                  <li>宛名面、中面の背景画像（PDF）<a href="/#" onclick="window.open('../help/help-background.html', '', 'width=580,height=600,scrollbars=yes,sizable=yes, location=no'); return false;" class="icon"><img src="/images/common/question.png" alt="" /></a>をアップしてください。</li>
                  <li>背景画像（PDF）をアップしましたら、「レイアウトイメージ確認」ボタンを押して、配置に問題がないかを確認してください。</li>
                  <li>&#10102;、&#10103;が完了しましたら、「最終印刷」へお進みください。</li>
                </ol>
               : null
              } 

              <div class="buttonbox move">
                    <div class="button-move">
                      <a href="#table">▼ 宛名面へ</a>
                    </div>
                    <div class="button-move">
                      <a href="#medium">▼ 中面へ</a>
                    </div>
              </div>


             
              </section>
            
              <section className="contents" id="surface">
                <PdfUploader type={1} item={cart} url={url} token={token} updatePdf={updatedPdfHandler}/>
                <PdfUploader type={2} item={cart} url={url} token={token} updatePdf={updatedPdfHandler}/>
              </section>
              </article>    

              <footer id="footer-step">
                <div className="s-contentsin">
                  <div className="l">
                    <div className="button forward" style={{width:"295px"}} onClick={() => moveTo(getBackStepBtnLink())}>
                      <div >
                          <span>アップロードした可変データへ</span>
                      </div>
                    </div>
                  </div>

                  <div className="m">
                  { cart && cart.plan !== RequestValue.PLAN_NO_ATENA ? 
                    <div className={getLayoutBtnClass()} onClick={() => moveTo(getLayoutBtnLink())}>
                      <span>レイアウト調整</span>
                    </div>
                       
                  : null }
                 
                      <div className={getNextBtnClass()} type="submit"  disabled={getNextBtnDisable()} onClick={() => displayConfirm()}>
                        <div><span>レイアウトイメージ確認</span></div>
                      </div>
               
                  </div>

                  <div className="r">
                    <div className={getNextStepBtnClass()} type="submit"  disabled={getNextStepBtnDisalbed()} onClick={() => moveToWithCheck(getNextStepBtnLink())}> 
                     <div><span> {getNextStepBtnLabel()}</span></div>
                  </div>
                  
                  </div>
                </div>
              </footer>
             

         
        </div>
  

  )
}

export default EditLayoutApp
