import { Record } from 'immutable'

const InqueriProps = Record({
  member_id:null,
  company_name:null,
  name:null,
  name_kana:null,
  email:null,
  tel:null,
  post1:null,
  post2:null,
  post_code:null,
  prefecture_id:null,
  address1:null,
  address2:null,
  memo:null
});
export default InqueriProps;
