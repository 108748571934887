import React, { } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import "../css/base.css"
import "../css/page.css"

function SitemapApp(props:PropsValue) {



  return(

        <div id="page" className="page sitemap">
          <Header noAuth={true} />
          <Menu />
          <article id="contents">
          <section className="s-contentsin2 topcomment">
           <h1 className="stitle-line">サイトマップ</h1>
          </section>

          <section className="s-contentsin">
            <section className="link-box">
              <div className="top">
                <a href="/">トップページ</a>
              </div>
            </section>
            <section className="link-box">
              <div className="box">
                <ul className="und-ul">
                  <li><a href="/order/#printing01">宛名あり印刷</a></li>
                  <li><a href="/order/#printing02">宛名あり・可変項目印刷</a></li>
                  <li><a href="/order/#printing03">宛名なし印刷</a></li>
                </ul>
              </div>
              <div className="box">
                <ul>
                  <li><a href="/mypage">マイページ（会員登録）</a></li>
                  <li><a href="/order">入稿の流れ</a></li>
                  <li><a href="/howto">入稿方法（データ作成方法）</a></li>
                  <li><a href="/delivery">料金・発送・お支払いについて</a></li>
                  <li><a href="/faq">Q&A</a></li>
                  <li><a href="/company">会社概要</a></li>
                  <li><a href="/inquiry">お問合せ</a></li>



                </ul>
              </div>
              <div className="box">
                <ul>
                <li><a href="/law">特定商取引法に基づく表記</a></li>
                <li><a href="/privacy">個人情報保護方針</a></li>
                <li><a href="/sitemap">サイトマップ</a></li>
                </ul>
              </div>
    </section>
	</section>


          </article>
          <Footer />
        </div>


  )
}

export default SitemapApp
