import { useState } from 'react';
import MediaAction from './MediaAction';



function useMediaState() {

  const [imageLength, setImageLength] = useState(0)
  const [error, setError] = useState(null)
  const [images, setImages] = useState([])
  const [pdf, setPdf] = useState(null)
  const [updatedDate, setUpdatedDate] = useState(null)

  const errorHandler = (data) => {
    setError(data)
  }

  const createdHandker = (data, cart_id) => {
   
    setUpdatedDate(new Date())
  }
  const loadedPdfHandler= (data) => {
    setPdf(data)
  }

  const loadedHandler  = (data) => {

    const  _images = images
    _images.push(data)
    //images.push(data)
    setImages(_images)
    setImageLength((new Date()).getMilliseconds())
  }

  const getThumnail = (token, cart_id, type) => {
    MediaAction.getThumnail( token, cart_id, type, loadedHandler)
  }

  const getOrderThumnail = (token, order_id, type) => {
    MediaAction.getOrderThumnail( token, order_id, type, loadedHandler)
  }

  const getPdf = (token, cart_id, type) => {
    MediaAction.getPdf( token, cart_id, type, loadedPdfHandler)
  }

  const create = (token, cart_id, file, type) => {
    const img = images.find(image => image.type === type);
    const index = images.indexOf(img);
    const  _images = images
    _images.splice(index, 1);  
    setImages(_images)
    
    MediaAction.create( token, cart_id, type,file, createdHandker ,errorHandler)
  }

  return { pdf_uploader_error:error,loaded_image:images, pdf:pdf, pdfUpdatedDate:updatedDate, loaded_images_length:imageLength, createPdf:create, getPdf:getPdf, getImage:getThumnail, getOrderImage:getOrderThumnail};
}

export default useMediaState