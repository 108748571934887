import React, { } from 'react'
import Header from '../../header/Header'
import Menu from '../../menu/Menu'
import Footer from '../../footer/Footer'
import RegisterForm from './RegisterForm'
import "../../css/member.css"


function RegisterApp(props:PropsValue) {

  return(
      <div id="page" className="member temporary-registration"> 
        <Header />
        <Menu />
        <article id="contents">
        <section className="topcomment">
          <h1>仮会員登録（無料）</h1>
          <p>データ入稿の前に、仮登録（無料）を行ってください。</p>
        </section>
            <RegisterForm />
        </article>
        <Footer />
      </div>


  )
}

export default RegisterApp

