import React, {  useEffect, useState } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import useAuthState from '../auth/useAuthState'
import UserMenu from '../menu/UserMenu'
import useProfileState from './useProfileState'
import useSendingState from '../sending/useSendingState';
import cookie from 'react-cookies'
import "../css/member.css"
function ProfileDeleteApp(props:PropsValue) {
  const {token, auth} = useAuthState()
  const [password, setPassword ] = useState(null)
  const [available, setAvailable] = useState(false)
  const [error, setError] = useState(null)
  const {deleted,profileError, deleteProfile} = useProfileState()

  const {orders, sending_updated_date, getSending} = useSendingState()
  const [delete_box, setDeleteBox] = useState(null)

  useEffect(() => {
    auth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(token)
      getSending(token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);
    

  useEffect(() => {
 
    if(profileError){
      setError(<li>パスワードが一致しません</li>)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profileError]);


  useEffect(() => {
    
    if(deleted){
      cookie.remove("token",{ path: '/' })
      window.location.href = "/mypage/profile/delete/complete"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[deleted]);


  const deleteItem =() => {
    if(password === null){
      setError(<li>パスワードを入力してください</li>)
    }else{
     if(window.confirm("退会してよろしいですか")) {
      deleteProfile(token, password)
     }
    }
    //deleteProfile()
  }


  useEffect(() => {
    if(orders){
      
      if(orders.length === 0){
        setAvailable(true)
      }else{
        const has_trading_item = orders.findIndex(item => item.status !== "納品完了" &&  item.deleted_by === null)
        if(has_trading_item === -1) {
          setAvailable(true)
        }else{
          setAvailable(false)
          setDeleteBox(	<p className="non-withdrawal"><strong>納品前の注文があるため、退会はできません。</strong></p>)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sending_updated_date]);



  return(

        <div id="page" className="member withdrawal"> 
          <Header token={token} />
          <Menu />
          <article id="contents">
            <UserMenu selectMenuItem="withdrawal" />
            <section className="s-contentsin2 topcomment">
              <h1 className="stitle">会員登録/会員情報変更</h1>
              {available ? <h2 className="stitle3">会員退会</h2>: null}
              {available ? <p>退会されますと、<strong>注文情報も削除されます</strong>のでご注意ください。<br />
		パスワードを入力し「退会する」ボタンを押しますと、退会処理を行います。</p> : null}
    <section className="s-contentsin2 delivery-list">
              {available ?
            <table className="form-table address-table">
                  <tr className="company_name" >
                              <th>
                                  <span className="thname">パスワード</span>
                              </th>

                              <td>
                                  <div className="textbox">
                                      <div>
                                      <input id="password" type="password" className="inputbox02" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                      
                                      </div>
                                      {error ? 
                                      <div class="error-box">
                                        <ul>
                                          {error}
                                        </ul>
                                      </div>
                                       : null }
                                  </div>
                              </td>
                  </tr>
            </table>

                                      :null}
            {available ?
            <div className="buttonbox">

                <div className="button or" onClick={deleteItem}>
                 <div>退会する</div>
                </div>
            </div>
            :null}
            {delete_box}
            </section>
          </section>
            </article>
          <Footer />
        </div>
  

  )
}

export default ProfileDeleteApp
